import { useTranslation } from 'react-i18next';

import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType, FormSubtitle } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import type { AnchorLoanDetailVOModel } from 'models/vo/AnchorLoanDetailVO';

interface FinancierAnchorFinancingDetailDesignatedBankAccountInformationProps {
  data: AnchorLoanDetailVOModel;
}
function FinancierAnchorFinancingDetailDesignatedBankAccountInformation({
  data,
}: FinancierAnchorFinancingDetailDesignatedBankAccountInformationProps) {
  const { t } = useTranslation(['format']);

  return (
    <div className="content-area">
      <SectionTitle title={t('text:Designated_Bank_Account_Information')} />
      <FormBorder>
        <FormSubtitle title={t('text:DISBURSEMENT_ACCOUNT')} />
        <FormContents backGroundType={BackGroundType.WHITE}>
          <div className="row">
            <FormValue label={t('text:Bank_Name')} value={data.disbursementAccountFinancierName} />
            <FormValue label={t('text:Bank_Branch_Name')} value={data.disbursementAccountBranchName} />
          </div>
          <div className="row">
            <FormValue label={t('text:Account_Number')} value={data.disbursementAccount} />
            <FormValue label={t('text:Account_Holder_Name')} value={data.disbursementAccountOwner} />
          </div>
        </FormContents>
      </FormBorder>
    </div>
  );
}
export default FinancierAnchorFinancingDetailDesignatedBankAccountInformation;
