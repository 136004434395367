const API_AC = {
  ANCHOR_AGREEMENTS: {
    AGREEMENT_LIST: 'v1/ac/anchor-agreements',
    AGREEMENT_DETAIL: (anchorAgreementId: number) => `v1/ac/anchor-agreements/${anchorAgreementId}`,
    AGREEMENT_LIST_FOR_ARS: 'v1/ac/anchor-agreements/list-for-ars',
    AGREEMENT_LIST_FOR_PARTNERS: 'v1/ac/anchor-agreements/list-for-partners',
  },
  ANCHOR_FINANCING_OPTIONS: {
    ANCHOR_FINANCING_OPTION_LIST: 'v1/ac/anchor-financing-options',
    ANCHOR_FINANCING_OPTION_DETAIL: (anchorFinancingOptionId: number) =>
      `v1/ac/anchor-financing-options/${anchorFinancingOptionId}`,
    AR_LIST_FOR_MULTIPLE_REQUEST_ANCHOR_LOANS:
      'v1/ac/anchor-financing-options/ar-list-for-multiple-request-anchor-loans',
    INVOICE_LIST_FOR_MULTIPLE_REQUEST_ANCHOR_LOANS:
      'v1/ac/anchor-financing-options/invoice-list-for-multiple-request-anchor-loans',
  },
  ANCHOR_DEALERS: {
    ANCHOR_DEALERS_LIST: 'v1/ac/anchor-dealers',
    ANCHOR_DEALERS_DETAIL: (anchorDealerId: number) => `v1/ac/anchor-dealers/${anchorDealerId}`,
  },
  ANCHOR_PARTNER_ACCOUNTS: {
    PARTNER_BANK_ACCOUNT_LIST: 'v1/ac/anchor-partner-accounts',
    PARTNER_BANK_ACCOUNT_DETAIL: (anchorPartnerAccountId: number) =>
      `v1/ac/anchor-partner-accounts/${anchorPartnerAccountId}`,
  },
  ANCHOR_PARTNERS: {
    PARTNERS: 'v1/ac/anchor-partners',
    PARTNER_DATA: (anchorPartnerId: number) => `v1/ac/anchor-partners/${anchorPartnerId}`,
    PARTNER_MODIFICATION: (anchorPartnerId: number) => `v1/ac/anchor-partners/${anchorPartnerId}/modification`,
    PARTNER_NAMES: 'v1/ac/anchor-partners/partner-names',
  },
  ANCHOR_LOANS: {
    LOANS: 'v1/ac/anchor-loans',
    LOAN: (anchorLoanId: number) => `v1/ac/anchor-loans/${anchorLoanId}`,
    CALCULATE_EXPECTED_AMOUNT: (anchorLoanId: number) => `v1/ac/anchor-loans/${anchorLoanId}/calculate:expected-amount`,
  },
  AR_APPROVAL_PHASES: {
    AR_PHASE_LIST: 'v1/ac/ar-approval-phases',
  },
  AR_SETTLEMENTS: {
    AR_SETTLEMENT_LIST: 'v1/ac/ar-settlements',
    AR_SETTLEMENT_DETAIL: (anchorAgreementId: number, settlementDate: string) =>
      `v1/ac/ar-settlements/${anchorAgreementId}/${settlementDate}`,
  },
  AR_SUMMARIES: {
    AR_SUMMARIES: 'v1/ac/ar-summaries',
    AR_SUMMARY_VALIDATE: 'v1/ac/ar-summaries/validate',
    AR_SUMMARY_DETAIL: (arSummaryId: number) => `v1/ac/ar-summaries/${arSummaryId}`,
    AR_SUMMARY_LAST_PHASE: (arSummaryId: number) => `v1/ac/ar-summaries/${arSummaryId}/find:last-phase-with-ars`,
    AR_SUMMARY_APPROVE: 'v1/ac/ar-summaries/approve:ar-phase',
    AR_SUMMARY_REJECT: 'v1/ac/ar-summaries/reject:ar-registration',
  },
  CLIENT_AUTH_SETTING: {
    CLIENT_AUTH_BY_DEALER_AGREEMENT_ID: 'v1/ac/client-auth-setting',
    CLIENT_AUTH_BY_FINANCIER_ID: 'v1/ac/client-auth-setting/by-financier-id',
  },
  DASHBOARD: {
    CLIENT_REQUEST: 'v1/ac/dashboard/client-request',
    INTERNAL_APPROVAL: 'v1/ac/dashboard/internal-approval',
  },
  DEALER_AGREEMENT_REPAID_AMOUNTS: {
    DEALER_AGREEMENT_REPAID_AMOUNT_LIST: 'v1/ac/dealer-agreement-repaid-amounts',
  },
  DEALER_AGREEMENTS: {
    DEALER_AGREEMENT_DETAIL: (dealerAgreementId: number) => `v1/ac/dealer-agreements/${dealerAgreementId}`,
    TEMP_DEALER_AGREEMENT_LIST: 'v1/ac/dealer-agreements/list-for-temp-invoice',
    ANCHOR_AGREEMENT_RELATED_DEALER_AGREEMENT: (dealerAgreementId: number) =>
      `v1/ac/dealer-agreements/${dealerAgreementId}/related-anchor-agreement`,
  },
  DELETED_ARS: {
    DELETED_ARS: 'v1/ac/deleted-ars',
  },
  DELETED_INVOICES: {
    DELETED_INVOICES: 'v1/ac/deleted-invoices',
  },
  ENTERPRISES: {
    RELATED_FINANCIERS: `v1/ac/enterprises/related-financiers`,
  },
  FACTORING_NOTICES: {
    FACTORING_NOTICE: `v1/ac/factoring-notices`,
    FACTORING_NOTICE_DETAIL: (factoringNoticeId: number) => `v1/ac/factoring-notices/${factoringNoticeId}`,
    FACTORING_NOTICE_DOWNLOAD_ATTACHMENT: (factoringNoticeId: number) =>
      `v1/ac/factoring-notices/${factoringNoticeId}/download:attachment`,
    FACTORING_NOTICE_RECEIVERS: (factoringNoticeId: number) => `v1/ac/factoring-notices/${factoringNoticeId}/receivers`,
    FACTORING_NOTICE_RELATED_ARS: (factoringNoticeId: number) =>
      `v1/ac/factoring-notices/${factoringNoticeId}/related-ars`,
    FACTORING_NOTICE_CONFIRM: `v1/ac/factoring-notices/confirm`,
  },
  FINANCIER_CALENDAR: {
    FINANCIER_CALENDAR: (financierId: number) => `v1/ac/financier-calendar/${financierId}`,
  },
  MULTIPLE_REQUEST_ANCHOR_LOANS: {
    MULTIPLE_REQUEST_ANCHOR_LOAN: 'v1/ac/multiple-request-anchor-loans',
    MULTIPLE_REQUEST_ANCHOR_LOANS_DETAIL: (multipleRequestAnchorLoanId: number) =>
      `v1/ac/multiple-request-anchor-loans/${multipleRequestAnchorLoanId}`,
    ARS: (multipleRequestAnchorLoanId: number) =>
      `v1/ac/multiple-request-anchor-loans/${multipleRequestAnchorLoanId}/ars`,
    INVOICES: (multipleRequestAnchorLoanId: number) =>
      `v1/ac/multiple-request-anchor-loans/${multipleRequestAnchorLoanId}/invoices`,
    LOANS: (multipleRequestAnchorLoanId: number) =>
      `v1/ac/multiple-request-anchor-loans/${multipleRequestAnchorLoanId}/loans`,
    REQUEST_SUCCESS_ARS: 'v1/ac/multiple-request-anchor-loans/request:success-ars',
    CALCULATE_SUCCESS_ARS: 'v1/ac/multiple-request-anchor-loans/calculate:success-ars',
    REQUEST_SUCCESS_INVOICES: 'v1/ac/multiple-request-anchor-loans/request:success-invoices',
    CALCULATE_SUCCESS_INVOICES: 'v1/ac/multiple-request-anchor-loans/calculate:success-invoices',
    APPROVE: (multipleRequestAnchorLoanId: number) =>
      `v1/ac/multiple-request-anchor-loans/${multipleRequestAnchorLoanId}/approve`,
    CANCEL: (multipleRequestAnchorLoanId: number) =>
      `v1/ac/multiple-request-anchor-loans/${multipleRequestAnchorLoanId}/cancel`,
    DOWNLOAD_ATTACHMENT: (multipleRequestAnchorLoanId: number) =>
      `v1/ac/multiple-request-anchor-loans/${multipleRequestAnchorLoanId}/download:attachment`,
  },
  INVOICE_APPROVAL_PHASES: {
    INVOICE_PHASE_LIST: 'v1/ac/invoice-approval-phases',
  },
  INVOICE_PAYMENTS: {
    INVOICE_PAYMENT_LIST: 'v1/ac/invoice-payments',
  },
  INVOICE_SUMMARIES: {
    INVOICE_SUMMARY: 'v1/ac/invoice-summaries',
    INVOICE_SUMMARY_DETAIL: (invoiceSummaryId: number) => `v1/ac/invoice-summaries/${invoiceSummaryId}`,
    INVOICE_SUMMARY_LAST_PHASE: (invoiceSummaryId: number) =>
      `v1/ac/invoice-summaries/${invoiceSummaryId}/find:last-phase-with-invoices`,
    APPROVE_INVOICE_PHASE: 'v1/ac/invoice-summaries/approve:invoice-phase',
    REJECT_INVOICE_REGISTRATION: 'v1/ac/invoice-summaries/reject:invoice-registration',
    INVOICE_BULK_UPLOAD: 'v1/ac/invoice-summaries/upload:bulk',
    APPROVE_BULK_INVOICE: 'v1/ac/invoice-summaries/approve:invoice-phase-bulk',
  },
  LOANS: {
    LOAN_LIST: 'v1/ac/loans',
    DOWNLOAD_SCANNED_AR: (loanId: number) => `v1/ac/loans/${loanId}/download:scanned-ar`,
  },
  OTP: {
    OTP_BY_DEALER_AGREEMENT_ID: 'v1/ac/otp/send:code',
    OTP_BY_FINANCIER_ID: 'v1/ac/otp/send:code-by-financier-id',
  },
  STATISTICS_OF_DEALER_AGREEMENTS: {
    STATISTICS_OF_DEALER_AGREEMENT_LIST: 'v1/ac/statistics-of-dealer-agreements',
  },
  SUCCESS_ARS: {
    SUCCESS_AR: 'v1/ac/success-ars',
    SUCCESS_AR_DETAIL: (successArId: number) => `v1/ac/success-ars/${successArId}`,
    RENEW_AR_BY_FINANCIER_INTERFACE: (successArId: number) =>
      `v1/ac/success-ars/${successArId}/renew:ar-by-financier-interface`,
    PARTIAL_COLLATERALS: (successArId: number) => `v1/ac/success-ars/${successArId}/partial-collaterals`,
    LIST_FOR_MULTIPLE_REQUEST_ANCHOR_LOANS: 'v1/ac/success-ars/list-for-multiple-request-anchor-loans',
  },
  SUCCESS_INVOICES: {
    SUCCESS_INVOICE: 'v1/ac/success-invoices',
    SUCCESS_INVOICE_DETAIL: (successInvoiceId: number) => `v1/ac/success-invoices/${successInvoiceId}`,
    DOWNLOAD_SUCCESS_INVOICE: (successInvoiceId: number) =>
      `v1/ac/success-invoices/${successInvoiceId}/download:scanned-invoice`,
    LIST_FOR_MULTIPLE_REQUEST_ANCHOR_LOANS: 'v1/ac/success-invoices/list-for-multiple-request-anchor-loans',
  },
  USERS: {
    USER: 'v1/ac/users',
    USER_DETAIL: (userId: number) => `v1/ac/users/${userId}`,
    SUSPEND_ADMIN_OPERATOR: (userId: number) => `v1/ac/users/${userId}/suspend:admin-operator`,
    INVITE_ADMIN_OPERATOR: (userId: number) => `v1/ac/users/${userId}/invite:admin-operator`,
    DEALER_AGREEMENT_LIST_RELATED_USER: (userId: number) => `v1/ac/users/${userId}/related-dealer-agreements`,
    ANCHOR_AGREEMENT_LIST_RELATED_USER: (userId: number) => `v1/ac/users/${userId}/related-anchor-agreements`,
    ANCHOR_AGREEMENT_LIST_NOT_RELATED_USER: (userId: number) => `v1/ac/users/${userId}/not-related-anchor-agreements`,
    ASSIGN_ANCHOR_AGREEMENT: (userId: number) => `v1/ac/users/${userId}/assign:anchor-agreement`,
    RELEASE_ANCHOR_AGREEMENT: (userId: number) => `v1/ac/users/${userId}/release:anchor-agreement`,
    USER_INFO_REGISTERED_BY_FINANCIER: 'v1/ac/users/related-anchor-users',
    ADMIN_OPERATOR_INVITE_URL: (userId: number) => `v1/ac/users/${userId}/invite-url`,
  },
  USER_EMAIL_SETTING: {
    USER_EMAIL_SETTING: 'v1/ac/user-email-setting',
  },
  WAITING_ANCHOR_AGREEMENTS: {
    WAITING_ANCHOR_AGREEMENT_LIST: 'v1/ac/waiting-anchor-agreements',
  },
  WAITING_ANCHOR_PARTNER_ACCOUNTS: {
    WAITING_PARTNER_ACCOUNT: 'v1/ac/waiting-anchor-partner-accounts',
    WAITING_PARTNER_ACCOUNT_DETAIL: (waitingAnchorPartnerAccountId: number) =>
      `v1/ac/waiting-anchor-partner-accounts/${waitingAnchorPartnerAccountId}`,
    APPROVE_WAITING_PARTNER_ACCOUNT: (waitingAnchorPartnerAccountId: number) =>
      `v1/ac/waiting-anchor-partner-accounts/${waitingAnchorPartnerAccountId}/approve:modification`,
    RETURN_WAITING_PARTNER_ACCOUNT: (waitingAnchorPartnerAccountId: number) =>
      `v1/ac/waiting-anchor-partner-accounts/${waitingAnchorPartnerAccountId}/return:modification`,
    CANCEL_WAITING_PARTNER_ACCOUNT: (waitingAnchorPartnerAccountId: number) =>
      `v1/ac/waiting-anchor-partner-accounts/${waitingAnchorPartnerAccountId}/cancel:modification`,
  },
  WAITING_ANCHOR_PARTNERS: {
    WAITING_ANCHOR_PARTNER: 'v1/ac/waiting-anchor-partners',
    WAITING_ANCHOR_PARTNER_LIST_FOR_MODIFICATION: 'v1/ac/waiting-anchor-partners/list-for-modification',
    REGISTER_WAITING_ANCHOR_PARTNER: (waitingAnchorPartnerId: number) =>
      `v1/ac/waiting-anchor-partners/${waitingAnchorPartnerId}/update:registration`,
    MODIFY_WAITING_ANCHOR_PARTNER: (waitingAnchorPartnerId: number) =>
      `v1/ac/waiting-anchor-partners/${waitingAnchorPartnerId}/update:modification`,
    APPROVE_WAITING_ANCHOR_PARTNER: 'v1/ac/waiting-anchor-partners/approve:registration',
    APPROVE_WAITING_ANCHOR_PARTNER_MODIFICATION: (waitingAnchorPartnerId: number) =>
      `v1/ac/waiting-anchor-partners/${waitingAnchorPartnerId}/approve:modification`,
    RETURN_WAITING_ANCHOR_PARTNER: 'v1/ac/waiting-anchor-partners/return:modification',
    CANCEL_WAITING_ANCHOR_PARTNER: 'v1/ac/waiting-anchor-partners/cancel:modification',
    WAITING_ANCHOR_PARTNER_DETAIL: (waitingAnchorPartnerId: number) =>
      `v1/ac/waiting-anchor-partners/${waitingAnchorPartnerId}`,
  },
  WAITING_ARS: {
    WAITING_AR_LIST: 'v1/ac/waiting-ars',
  },
  WAITING_INVOICES: {
    WAITING_INVOICE_LIST: 'v1/ac/waiting-invoices',
  },
  WAITING_ANCHOR_DEALERS: {
    WAITING_ANCHOR_DEALERS: 'v1/ac/waiting-anchor-dealers',
    WAITING_ANCHOR_DEALERS_DETAIL: (waitingAnchorDealerId: number) =>
      `v1/ac/waiting-anchor-dealers/${waitingAnchorDealerId}`,
    WAITING_ANCHOR_DEALERS_CANCEL: 'v1/ac/waiting-anchor-dealers/cancel',
    WAITING_ANCHOR_DEALERS_REJECT: 'v1/ac/waiting-anchor-dealers/reject',
    WAITING_ANCHOR_DEALERS_APPROVE_REGISTER: 'v1/ac/waiting-anchor-dealers/approve:register',
    WAITING_ANCHOR_DEALERS_APPROVE_MODIFY: (waitingAnchorDealerId: number) =>
      `v1/ac/waiting-anchor-dealers/${waitingAnchorDealerId}/approve:modify`,
  },
  WAITING_ANCHOR_FINANCING_OPTIONS: {
    WAITING_ANCHOR_FINANCING_OPTIONS: 'v1/ac/waiting-anchor-financing-options',
  },
};

export default API_AC;
