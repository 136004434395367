import type { RouteComponentProps } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';

import Navigation from 'components/stateless/Navigation';
import { ROUTES_FI } from 'constants/routes/financier';
import NotFoundPage from 'pages/NotFoundPage';
import FinancierBilling from 'pages/financier/billing/FinancierBilling';
import FinancierDashboard from 'pages/financier/dashboard/FinancierDashboard';
import FinancierAnchorAgreementList from 'pages/financier/manage-anchor/agreement/list/FinancierAnchorAgreementList';
import AnchorAgreementRegistration from 'pages/financier/manage-anchor/agreement/registration';
import AnchorAgreementRegistrationDetail from 'pages/financier/manage-anchor/agreement/registration-detail';
import AnchorAgreementWaitingDetail from 'pages/financier/manage-anchor/agreement/waiting-detail';
import FinancierManageAnchorDetail from 'pages/financier/manage-anchor/company/detail/FinancierManageAnchorDetail';
import FinancierManageAnchorList from 'pages/financier/manage-anchor/company/list/FinancierManageAnchorList';
import FinancierManageAnchorRegister from 'pages/financier/manage-anchor/company/register/FinancierManageAnchorRegister';
import FinancierAnchorFinancingOptionList from 'pages/financier/manage-anchor/financing-option/list/FinancierAnchorFinancingOptionList';
import AnchorFinancingOptionRegistration from 'pages/financier/manage-anchor/financing-option/registration';
import AnchorFinancingOptionRegistrationDetail from 'pages/financier/manage-anchor/financing-option/registration-detail';
import AnchorFinancingOptionWaitingDetail from 'pages/financier/manage-anchor/financing-option/waiting-detail';
import FinancierAnchorReportDetail from 'pages/financier/manage-anchor/report/detail/FinancierAnchorReportDetail';
import FinancierAnchorReportList from 'pages/financier/manage-anchor/report/list/FinancierAnchorReportList';
import FinancierAnchorReportRegister from 'pages/financier/manage-anchor/report/register/FinancierAnchorReportRegister';
import FinancierManageAnchorUserRegisteredDetail from 'pages/financier/manage-anchor/user/detail/FinancierManageAnchorUserRegisteredDetail';
import FinancierManageAnchorUserWaitingDetail from 'pages/financier/manage-anchor/user/detail/FinancierManageAnchorUserWaitingDetail';
import FinancierManageAnchorUserDetailEmailSetting from 'pages/financier/manage-anchor/user/detail/sections/email-notification-setting';
import FinancierManageAnchorUserList from 'pages/financier/manage-anchor/user/list/FinancierManageAnchorUserList';
import FinancierManageAnchorUserRegistration from 'pages/financier/manage-anchor/user/register/FinancierManageAnchorUserRegistration';
import FinancierBulkAnchorFinancingConfirmationDetail from 'pages/financier/manage-anchor-financing/bulk-financing/detail/FinancierBulkAnchorFinancingConfirmationDetail';
import FinancierBulkAnchorFinancingConfirmationList from 'pages/financier/manage-anchor-financing/bulk-financing/list/FinancierBulkAnchorFinancingConfirmationList';
import FinancierAnchorLoanDetail from 'pages/financier/manage-anchor-financing/financing/detail/FinancierAnchorLoanDetail';
import FinancierAnchorFinancingList from 'pages/financier/manage-anchor-financing/financing/list/FinancierAnchorFinancingList';
import FinancierAnchorDealerAssignmentDetail from 'pages/financier/manage-dealer/assignment/detail/FinancierAnchorDealerAssignmentDetail';
import FinancierAnchorDealerAssignmentList from 'pages/financier/manage-dealer/assignment/list/FinancierAnchorDealerAssignmentList';
import FinancierAnchorDealerAssignmentListAdmin from 'pages/financier/manage-dealer/assignment/list-admin/FinancierAnchorDealerAssignmentListAdmin';
import FinancierAnchorDealerRegistrationConfirmedDetailPage from 'pages/financier/manage-dealer/registration/detail/FinancierAnchorDealerRegistrationConfirmedDetailPage';
import FinancierAnchorDealerRegistrationWaitingDetailPage from 'pages/financier/manage-dealer/registration/detail/FinancierAnchorDealerRegistrationWaitingDetailPage';
import FinancierAnchorDealerRegistrationListPage from 'pages/financier/manage-dealer/registration/list/FinancierAnchorDealerRegistrationListPage';
import FinancierAnchorDealerRegistrationStep1Page from 'pages/financier/manage-dealer/registration/step1/FinancierAnchorDealerRegistrationStep1Page';
import FinancierAnchorDealerRegistrationStep2Page from 'pages/financier/manage-dealer/registration/step2/FinancierAnchorDealerRegistrationStep2Page';
import FinancierFinancingApprovalList from 'pages/financier/manage-financing/approval/list/FinancierFinancingApprovalList';
import FinancierBulkFinancingApprovalDetail from 'pages/financier/manage-financing/bulk-approval/detail/FinancierBulkFinancingApprovalDetail';
import FinancierBulkFinancingApprovalList from 'pages/financier/manage-financing/bulk-approval/list/FinancierBulkFinancingApprovalList';
import FinancierProgramChargeDetailPage from 'pages/financier/manage-financing/charge/detail';
import FinancierProgramChargeListPage from 'pages/financier/manage-financing/charge/list';
import FinancierProgramChargeRegisterPage from 'pages/financier/manage-financing/charge/register';
import FinancierEarlyRepaymentDetail from 'pages/financier/manage-financing/earlyRepayment/detail/FinancierEarlyRepaymentDetail';
import FinancierEarlyRepaymentList from 'pages/financier/manage-financing/earlyRepayment/list/FinancierEarlyRepaymentList';
import FinancierExtensionDetail from 'pages/financier/manage-financing/extension/detail/FinancierExtensionDetail';
import FinancierExtensionList from 'pages/financier/manage-financing/extension/list/FinancierExtensionList';
import FinancierSecuredInventoryDetail from 'pages/financier/manage-financing/securedInventoryUpdate/detail/FinancierSecuredInventoryDetail';
import FinancierSecuredInventoryList from 'pages/financier/manage-financing/securedInventoryUpdate/list/FinancierSecuredInventoryList';
import FinancierDealerAgreementList from 'pages/financier/manage-partner/agreement/list/FinancierDealerAgreementList';
import PartnerAgreementRegistration from 'pages/financier/manage-partner/agreement/registration';
import PartnerAgreementRegistrationDetail from 'pages/financier/manage-partner/agreement/registration-detail';
import PartnerAgreementWaitingDetail from 'pages/financier/manage-partner/agreement/waiting-detail';
import FinancierManageDealerDetail from 'pages/financier/manage-partner/company/detail/FinancierManageDealerDetail';
import FinancierManageDealerList from 'pages/financier/manage-partner/company/list/FinancierManageDealerList';
import FinancierManageDealerRegistration from 'pages/financier/manage-partner/company/register/FinancierManageDealerRegistration';
import FinancierDealerReportDetail from 'pages/financier/manage-partner/report/detail/FinancierDealerReportDetail';
import FinancierDealerReportList from 'pages/financier/manage-partner/report/list/FinancierDealerReportList';
import FinancierDealerReportRegister from 'pages/financier/manage-partner/report/register/FinancierDealerReportRegister';
import FinancierManageDealerUserDetail from 'pages/financier/manage-partner/user/detail/FinancierManageDealerUserDetail';
import FinancierManageDealerUserDetailEmailSetting from 'pages/financier/manage-partner/user/detail/email-notification-setting';
import FinancierManageDealerUserList from 'pages/financier/manage-partner/user/list/FinancierManageDealerUserList';
import FinancierScPartnerAcquisitionDetailPage from 'pages/financier/manage-uploaded-partner/acquisition/detail/FinancierSCPartnerAcquisitionDetailPage';
import FinancierScPartnerAcquisitionListPage from 'pages/financier/manage-uploaded-partner/acquisition/list/FinancierSCPartnerAcquisitionListPage';
import FinancierSCPartnerAcquisitionSettingPage from 'pages/financier/manage-uploaded-partner/acquisition/settings/FinancierSCPartnerAcquisitionSettingPage';
import FinancierPotentialSupplyChainPartnerAssignmentDetail from 'pages/financier/manage-uploaded-partner/assignment/detail/FinancierPotentialSupplyChainPartnerAssignmentDetail';
import FinancierPotentialSupplyChainPartnerAssignmentList from 'pages/financier/manage-uploaded-partner/assignment/list/FinancierPotentialSupplyChainPartnerAssignmentList';
import FinancierPotentialSupplyChainPartnerBankAccountConfirmedRevisionDetail from 'pages/financier/manage-uploaded-partner/bank-account-revision/detail/FinancierPotentialSupplyChainPartnerBankAccountConfirmedRevisionDetail';
import FinancierPotentialSupplyChainPartnerBankAccountWaitingRevisionDetail from 'pages/financier/manage-uploaded-partner/bank-account-revision/detail/FinancierPotentialSupplyChainPartnerBankAccountWaitingRevisionDetail';
import FinancierPotentialSupplyChainPartnerBankAccountRevisionList from 'pages/financier/manage-uploaded-partner/bank-account-revision/list/FinancierPotentialSupplyChainPartnerBankAccountRevisionList';
import FinancierPotentialSupplyChainPartnerRegistrationConfirmedDetail from 'pages/financier/manage-uploaded-partner/registration/detail/FinancierPotentialSupplyChainPartnerRegistrationConfirmedDetail';
import FinancierPotentialSupplyChainPartnerRegistrationWaitingDetail from 'pages/financier/manage-uploaded-partner/registration/detail/FinancierPotentialSupplyChainPartnerRegistrationWaitingDetail';
import FinancierPotentialSupplyChainPartnerRegistrationList from 'pages/financier/manage-uploaded-partner/registration/list/FinancierPotentialSupplyChainPartnerRegistrationList';
import FinancierPotentialSupplyChainPartnerRegisterStep1 from 'pages/financier/manage-uploaded-partner/registration/step1/FinancierPotentialSupplyChainPartnerRegisterStep1';
import FinancierPotentialSupplyChainPartnerRegisterStep2 from 'pages/financier/manage-uploaded-partner/registration/step2/FinancierPotentialSupplyChainPartnerRegisterStep2';
import FinancierMyAccount from 'pages/financier/my-account/manage/FinancierMyAccount';
import FinancierBankCodeDetailConfirmed from 'pages/financier/program-settings/bank-code/confirmed-detail/FinancierBankCodeConfirmedDetail';
import FinancierBankCodeList from 'pages/financier/program-settings/bank-code/list/FinancierBankCodeList';
import FinancierBankCodeRegister from 'pages/financier/program-settings/bank-code/register/FinancierBankCodeRegister';
import FinancierBankCodeWaitingDetail from 'pages/financier/program-settings/bank-code/waiting-detail/FinancierBankCodeWaitingDetail';
import FinancierDelinquentInterestRateSettingDetail from 'pages/financier/program-settings/delinquent-interest-rate-setting/detail/FinancierDelinquentInterestRateSettingDetail';
import FinancierDelinquentInterestRateSettingList from 'pages/financier/program-settings/delinquent-interest-rate-setting/list/FinancierDelinquentInterestRateSettingList';
import FinancierDelinquentInterestRateSettingRegister from 'pages/financier/program-settings/delinquent-interest-rate-setting/register/FinancierDelinquentInterestRateSettingRegister';
import FinancierAdditionalDocumentDetail from 'pages/financier/program-settings/document/additional-document-detail/FinancierAdditionalDocumentDetail';
import FinancierAdditionalDocumentRegister from 'pages/financier/program-settings/document/additional-document-register/FinancierAdditionalDocumentRegister';
import FinancierDocumentDetail from 'pages/financier/program-settings/document/detail/FinancierDocumentDetail';
import FinancierDocumentList from 'pages/financier/program-settings/document/list/FinancierDocumentList';
import FinancierDocumentRegister from 'pages/financier/program-settings/document/register/FinancierDocumentRegister';
import FinancierEmailNotificationSetting from 'pages/financier/program-settings/email-notification/FinancierEmailNotificationSetting';
import FinancierFinancingSetting from 'pages/financier/program-settings/financing/FinancierFinancingSetting';
import FinancierHolidaysDetail from 'pages/financier/program-settings/holidays/detail/FinancierHolidaysDetail';
import FinancierHolidaysList from 'pages/financier/program-settings/holidays/list/FinancierHolidaysList';
import FinancierHolidaysRegister from 'pages/financier/program-settings/holidays/register/FinancierHolidaysRegister';
import FinancierInterestRateFeeList from 'pages/financier/program-settings/interest-rate-fee/list/FinancierInterestRateFeeList';
import FinancierInterestRateFeeRegister from 'pages/financier/program-settings/interest-rate-fee/register/FinancierInterestRateFeeRegister';
import FinancierManageOperationHour from 'pages/financier/program-settings/operation-hour/FinancierManageOprerationHour';
import FinancierArConfirmationDetail from 'pages/financier/register-ar/confirmation/detail/FinancierArConfirmationDetail';
import FinancierArConfirmationList from 'pages/financier/register-ar/confirmation/list/FinancierArConfirmationList';
import FinancierInvoiceConfirmationDetail from 'pages/financier/register-invoices/confirmation/detail/FinancierInvoiceConfirmationDetail';
import FinancierInvoiceConfirmationList from 'pages/financier/register-invoices/confirmation/list/FinancierInvoiceConfirmationList';
import FinancierInvoiceRegisterStep1 from 'pages/financier/register-invoices/registeration/step1/FinancierInvoiceRegisterStep1';
import FinancierInvoiceRegisterStep2 from 'pages/financier/register-invoices/registeration/step2/FinancierInvoiceRegisterStep2';
import FinancierBranchDetail from 'pages/financier/settings/branch/detail/FinancierBranchDetail';
import FinancierBranchList from 'pages/financier/settings/branch/list/FinancierBranchList';
import FinancierBranchRegister from 'pages/financier/settings/branch/register/FinancierBranchRegister';
import FinancierUserDetail from 'pages/financier/settings/user/detail/FinancierUserDetail';
import FinancierUserList from 'pages/financier/settings/user/list/FinancierUserList';
import FinancierUserRegister from 'pages/financier/settings/user/register/FinancierUserRegister';
import FinancierArDetail from 'pages/financier/view-transactions/ar/detail/FinancierArDetail';
import FinancierArList from 'pages/financier/view-transactions/ar/list/FinancierArList';
import FinancierArSettlementDetail from 'pages/financier/view-transactions/ar-settlement/detail/FinancierArSettlementDetail';
import FinancierArSettlementList from 'pages/financier/view-transactions/ar-settlement/list/FinancierArSettlementList';
import FinancierDeptSellingNoticeDetail from 'pages/financier/view-transactions/dept-selling-notice/detail/FinancierDeptSellingNoticeDetail';
import FinancierDeptSellingNoticeList from 'pages/financier/view-transactions/dept-selling-notice/list/FinancierDeptSellingNoticeList';
import FinancierFinancingDetail from 'pages/financier/view-transactions/financing/detail/FinancierFinancingDetail';
import FinancierFinancingList from 'pages/financier/view-transactions/financing/list/FinancierFinancingList';
import FinancierInterestPaymentList from 'pages/financier/view-transactions/interest-payment/FinancierInterestPaymentList';
import FinancierInvoiceDetail from 'pages/financier/view-transactions/invoice/detail/FinancierInvoiceDetail';
import FinancierInvoiceList from 'pages/financier/view-transactions/invoice/list/FinancierInvoiceList';
import FinancierInvoicePaymentList from 'pages/financier/view-transactions/invoice-payment/list/FinancierInvoicePaymentList';
import FinancierOverviewByAnchorList from 'pages/financier/view-transactions/overview-by-anchor/list/FinancierOverviewByAnchorList';
import FinancierOverviewByPartnerList from 'pages/financier/view-transactions/overview-by-dealer/FinancierOverviewByPartnerList';
import FinancierWithdrawalFromPartnerDetail from 'pages/financier/view-transactions/withdrawalFromPartner/detail/FinancierWithdrawalFromPartnerDetail';
import FinancierWithdrawalFromPartnerList from 'pages/financier/view-transactions/withdrawalFromPartner/list/FinancierWithdrawalFromPartnerList';

function FinancierRouter({ location }: RouteComponentProps<{}>) {
  return (
    <div className="content-wrapper">
      <Navigation />
      <div className="page-content">
        <Switch>
          {/* DashBoard - 대시보드 페이지 */}
          <Route exact path={ROUTES_FI.DASHBOARD} component={FinancierDashboard} />

          {/* Manage Anchor - Anchor 기업 목록 */}
          <Route exact path={ROUTES_FI.MANAGE_ANCHOR.COMPANY_LIST} component={FinancierManageAnchorList} />

          {/* Manage Anchor - Anchor 기업 등록 */}
          <Route exact path={ROUTES_FI.MANAGE_ANCHOR.COMPANY_REGISTER} component={FinancierManageAnchorRegister} />

          {/* Manage Anchor - Anchor 기업 상세 - 등록 완료 */}
          <Route path={ROUTES_FI.MANAGE_ANCHOR.COMPANY_DETAIL} component={FinancierManageAnchorDetail} />

          {/* Manage Anchor - Anchor 약정 목록 */}
          <Route exact path={ROUTES_FI.MANAGE_ANCHOR.AGREEMENT_LIST} component={FinancierAnchorAgreementList} />

          {/* Manage Anchor - New Agreement Registration */}
          <Route exact path={ROUTES_FI.MANAGE_ANCHOR.AGREEMENT_REGISTER} component={AnchorAgreementRegistration} />

          {/* Manage Anchor - New Agreement Registration Detail */}
          <Route
            path={ROUTES_FI.MANAGE_ANCHOR.AGREEMENT_REGISTERED_DETAIL}
            component={AnchorAgreementRegistrationDetail}
          />

          <Route
            key={location.key}
            path={ROUTES_FI.MANAGE_ANCHOR.AGREEMENT_WAITING_DETAIL}
            component={AnchorAgreementWaitingDetail}
          />

          <Route
            exact
            path={ROUTES_FI.MANAGE_ANCHOR.FINANCING_OPTION_LIST}
            component={FinancierAnchorFinancingOptionList}
          />

          <Route
            exact
            path={ROUTES_FI.MANAGE_ANCHOR.FINANCING_OPTION_REGISTER}
            component={AnchorFinancingOptionRegistration}
          />

          <Route
            key={location.key}
            path={ROUTES_FI.MANAGE_ANCHOR.FINANCING_OPTION_WAITING_DETAIL}
            component={AnchorFinancingOptionWaitingDetail}
          />
          <Route
            key={location.key}
            path={ROUTES_FI.MANAGE_ANCHOR.FINANCING_OPTION_REGISTERED_DETAIL}
            component={AnchorFinancingOptionRegistrationDetail}
          />

          {/* Manage Anchor - User List */}
          <Route exact path={ROUTES_FI.MANAGE_ANCHOR.USER_LIST} component={FinancierManageAnchorUserList} />

          {/* Financier - Manage Anchor - 사용자 등록 */}
          <Route exact path={ROUTES_FI.MANAGE_ANCHOR.USER_REGISTER} component={FinancierManageAnchorUserRegistration} />

          <Route
            path={ROUTES_FI.MANAGE_ANCHOR.USER_REGISTERED_DETAIL_EMAIL_SETTING}
            component={FinancierManageAnchorUserDetailEmailSetting}
          />

          {/* Manage Anchor - User Detail */}
          <Route
            path={ROUTES_FI.MANAGE_ANCHOR.USER_REGISTERED_DETAIL}
            component={FinancierManageAnchorUserRegisteredDetail}
          />

          {/* Financier - Manage Anchor - User Detail - Waiting */}
          <Route
            path={ROUTES_FI.MANAGE_ANCHOR.USER_WAITING_DETAIL}
            component={FinancierManageAnchorUserWaitingDetail}
          />

          {/* Financier > Manage Anchor > Report / List */}
          <Route exact path={ROUTES_FI.MANAGE_ANCHOR.REPORT_LIST} component={FinancierAnchorReportList} />

          {/* Financier > Manage Anchor > Report / Register */}
          <Route exact path={ROUTES_FI.MANAGE_ANCHOR.REPORT_REGISTER} component={FinancierAnchorReportRegister} />

          {/* Financier > Manage Anchor > Report / Detail */}
          <Route path={ROUTES_FI.MANAGE_ANCHOR.REPORT_DETAIL} component={FinancierAnchorReportDetail} />

          {/* Manage Dealer - Dealer 기업 목록 */}
          <Route exact path={ROUTES_FI.MANAGE_PARTNER.COMPANY_LIST} component={FinancierManageDealerList} />

          {/*Manage Dealer - Dealer 기업 등록 */}
          <Route exact path={ROUTES_FI.MANAGE_PARTNER.COMPANY_REGISTER} component={FinancierManageDealerRegistration} />

          {/* Manage Dealer - Dealer 기업 상세 - 등록 완료 */}
          <Route path={ROUTES_FI.MANAGE_PARTNER.COMPANY_DETAIL} component={FinancierManageDealerDetail} />

          {/* Manage Dealer - Dealer 약정 목록 */}
          <Route exact path={ROUTES_FI.MANAGE_PARTNER.AGREEMENT_LIST} component={FinancierDealerAgreementList} />

          <Route exact path={ROUTES_FI.MANAGE_PARTNER.AGREEMENT_REGISTER} component={PartnerAgreementRegistration} />
          <Route
            exact
            path={ROUTES_FI.MANAGE_PARTNER.AGREEMENT_REGISTERED_DETAIL}
            component={PartnerAgreementRegistrationDetail}
          />
          <Route
            exact
            path={ROUTES_FI.MANAGE_PARTNER.AGREEMENT_WAITING_DETAIL}
            component={PartnerAgreementWaitingDetail}
          />

          {/* Manage Dealer - Dealer 유저 목록 */}
          <Route exact path={ROUTES_FI.MANAGE_PARTNER.USER_LIST} component={FinancierManageDealerUserList} />

          <Route
            path={ROUTES_FI.MANAGE_PARTNER.USER_DETAIL_EMAIL_SETTING}
            component={FinancierManageDealerUserDetailEmailSetting}
          />

          <Route path={ROUTES_FI.MANAGE_PARTNER.USER_DETAIL} component={FinancierManageDealerUserDetail} />

          {/* Manage Dealer > Report / List */}
          <Route exact path={ROUTES_FI.MANAGE_PARTNER.REPORT_LIST} component={FinancierDealerReportList} />

          {/* Manage Dealer > Report / Register */}
          <Route exact path={ROUTES_FI.MANAGE_PARTNER.REPORT_REGISTER} component={FinancierDealerReportRegister} />

          {/* Manage Dealer > Report / Detail */}
          <Route path={ROUTES_FI.MANAGE_PARTNER.REPORT_DETAIL} component={FinancierDealerReportDetail} />

          {/* Register Invoices - 송장 등록 step1 */}
          <Route exact path={ROUTES_FI.REGISTER_INVOICE.REGISTRATION_STEP1} component={FinancierInvoiceRegisterStep1} />

          {/* Register Invoices - 송장 등록 step2 */}
          <Route path={ROUTES_FI.REGISTER_INVOICE.REGISTRATION_STEP2} component={FinancierInvoiceRegisterStep2} />

          {/* Register Invoice - Confirmation - 송장 결재 목록 */}
          <Route
            exact
            path={ROUTES_FI.REGISTER_INVOICE.CONFIRMATION_LIST}
            component={FinancierInvoiceConfirmationList}
          />

          {/* Register Invoice - Confirmation - 송장 결재 상세 */}
          <Route
            key={location.key}
            path={ROUTES_FI.REGISTER_INVOICE.CONFIRMATION_DETAIL}
            component={FinancierInvoiceConfirmationDetail}
          />

          <Route
            path={ROUTES_FI.MANAGE_ANCHOR_FINANCING.APPROVAL_DETAIL}
            component={FinancierBulkAnchorFinancingConfirmationDetail}
          />

          <Route
            exact
            path={ROUTES_FI.MANAGE_ANCHOR_FINANCING.APPROVAL_LIST}
            component={FinancierBulkAnchorFinancingConfirmationList}
          />

          <Route path={ROUTES_FI.MANAGE_ANCHOR_FINANCING.DETAIL} component={FinancierAnchorLoanDetail} />

          <Route exact path={ROUTES_FI.MANAGE_ANCHOR_FINANCING.LIST} component={FinancierAnchorFinancingList} />

          {/* Register AR - Confirmation List : 미승인된 (등록 중인) 매출채권 목록 조회  */}
          <Route exact path={ROUTES_FI.REGISTER_AR.CONFIRMATION_LIST} component={FinancierArConfirmationList} />

          {/* Register AR - Confirmation List : 미승인된 (등록 중인) 매출채권 상세 조회  */}
          <Route
            key={location.key}
            path={ROUTES_FI.REGISTER_AR.CONFIRMATION_DETAIL}
            component={FinancierArConfirmationDetail}
          />

          {/* Manage Financing - Approval - 대출 목록 */}
          <Route exact path={ROUTES_FI.MANAGE_FINANCING.APPROVAL_LIST} component={FinancierFinancingApprovalList} />

          {/* Manage Financing - Approval - 대출 상세 */}
          <Route
            key={location.key}
            path={ROUTES_FI.MANAGE_FINANCING.APPROVAL_DETAIL}
            component={FinancierFinancingDetail}
          />

          {/* Manage Financing - Bulk Financing Approval - 일괄 승인 목록 */}
          <Route
            exact
            path={ROUTES_FI.MANAGE_FINANCING.BULK_APPROVAL_LIST}
            component={FinancierBulkFinancingApprovalList}
          />

          {/* Manage Financing - Bulk Financing Approval - 일괄 승인 상세 */}
          <Route
            key={location.key}
            path={ROUTES_FI.MANAGE_FINANCING.BULK_APPROVAL_DETAIL}
            component={FinancierBulkFinancingApprovalDetail}
          />

          {/* Manage Financing - Extension - 만기 연장 신청 목록 */}
          <Route exact path={ROUTES_FI.MANAGE_FINANCING.EXTENSION_LIST} component={FinancierExtensionList} />

          {/* Manage Financing - Extension - 만기 연장 신청 상세 */}
          <Route
            key={location.key}
            path={ROUTES_FI.MANAGE_FINANCING.EXTENSION_DETAIL}
            component={FinancierExtensionDetail}
          />

          {/* Manage Financing - Early repayment - 조기 상환 신청 목록 */}
          <Route exact path={ROUTES_FI.MANAGE_FINANCING.EARLY_REPAYMENT_LIST} component={FinancierEarlyRepaymentList} />

          {/* Manage Financing - Early repayment - 조기 상환 신청 상세 */}
          <Route
            key={location.key}
            path={ROUTES_FI.MANAGE_FINANCING.EARLY_REPAYMENT_DETAIL}
            component={FinancierEarlyRepaymentDetail}
          />

          {/* Manage Financing - Secured Inventory update - 재고 담보 목록 */}
          <Route
            exact
            path={ROUTES_FI.MANAGE_FINANCING.SECURED_INVENTORY_UPDATE_LIST}
            component={FinancierSecuredInventoryList}
          />

          {/* Manage Financing - Secured Inventory update - 재고 담보 상세 */}
          <Route
            path={ROUTES_FI.MANAGE_FINANCING.SECURED_INVENTORY_UPDATE_DETAIL}
            component={FinancierSecuredInventoryDetail}
          />

          {/* View Transactions - Invoice - 송장 목록 */}
          <Route exact path={ROUTES_FI.VIEW_TRANSACTION.INVOICE_LIST} component={FinancierInvoiceList} />

          {/* View Transactions - Invoice - 송장 상세 */}
          <Route
            key={location.key}
            path={ROUTES_FI.VIEW_TRANSACTION.INVOICE_DETAIL}
            component={FinancierInvoiceDetail}
          />

          {/* View Transactions - Invoice Payment - BOE 관련 송장 목록 */}
          <Route exact path={ROUTES_FI.VIEW_TRANSACTION.INVOICE_PAYMENT_LIST} component={FinancierInvoicePaymentList} />

          {/* View Transactions - AP - AP 목록 */}
          <Route exact path={ROUTES_FI.VIEW_TRANSACTION.AR_LIST} component={FinancierArList} />

          {/* View Transactions - AP - AP 상세 */}
          <Route path={ROUTES_FI.VIEW_TRANSACTION.AR_DETAIL} component={FinancierArDetail} />

          {/* View Transactions - AP - AP Settlement 목록 */}
          <Route exact path={ROUTES_FI.VIEW_TRANSACTION.AR_SETTLEMENT_LIST} component={FinancierArSettlementList} />

          {/* View Transactions - 채권 양도 통지 확인  */}
          <Route
            exact
            path={ROUTES_FI.VIEW_TRANSACTION.DEPT_SELLING_NOTICE_LIST}
            component={FinancierDeptSellingNoticeList}
          />

          <Route
            path={ROUTES_FI.VIEW_TRANSACTION.DEPT_SELLING_NOTICE_DETAIL}
            component={FinancierDeptSellingNoticeDetail}
          />

          {/* Manage Financing - Settlement Payment - 판매기업으로부터 금융사 인출 가능 금액 목록  */}
          <Route
            exact
            path={ROUTES_FI.VIEW_TRANSACTION.SETTLEMENT_PAYMENT_LIST}
            component={FinancierWithdrawalFromPartnerList}
          />

          {/* Manage Financing - Settlement Payment - 판매기업으로부터 금융사 인출 가능 금액 상세  */}
          <Route
            path={ROUTES_FI.VIEW_TRANSACTION.SETTLEMENT_PAYMENT_DETAIL}
            component={FinancierWithdrawalFromPartnerDetail}
          />

          {/* View Transactions - AP - AP Settlement 상세 */}
          <Route path={ROUTES_FI.VIEW_TRANSACTION.AR_SETTLEMENT_DETAIL} component={FinancierArSettlementDetail} />

          {/* View Transactions - Financing - 대출 목록 */}
          <Route exact path={ROUTES_FI.VIEW_TRANSACTION.FINANCING_LIST} component={FinancierFinancingList} />

          {/* View Transactions - Financing - 대출 상세 */}
          <Route
            key={location.key}
            path={ROUTES_FI.VIEW_TRANSACTION.FINANCING_DETAIL}
            component={FinancierFinancingDetail}
          />

          {/* View Transactions - Transaction by anchor - 앵커 모니터링 목록 */}
          <Route
            exact
            path={ROUTES_FI.VIEW_TRANSACTION.OVERVIEW_BY_ANCHOR_LIST}
            component={FinancierOverviewByAnchorList}
          />

          {/* View Transactions - Transaction by dealer - 딜러 모니터링 목록 */}
          <Route
            exact
            path={ROUTES_FI.VIEW_TRANSACTION.OVERVIEW_BY_PARTNER_LIST}
            component={FinancierOverviewByPartnerList}
          />

          {/* View Transactions - Interest Payment - 파트너 이자 납부 조회 */}
          <Route
            exact
            path={ROUTES_FI.VIEW_TRANSACTION.INTEREST_PAYMENT_LIST}
            component={FinancierInterestPaymentList}
          />

          {/* Financier - Branch - 지점 목록 */}
          <Route exact path={ROUTES_FI.SETTINGS.BRANCH_LIST} component={FinancierBranchList} />

          {/* Financier - Branch - 지점 등록 */}
          <Route exact path={ROUTES_FI.SETTINGS.BRANCH_REGISTER} component={FinancierBranchRegister} />

          {/* Financier - Branch - 지점 상세 */}
          <Route path={ROUTES_FI.SETTINGS.BRANCH_DETAIL} component={FinancierBranchDetail} />

          {/* Financier - User - 사용자 목록 */}
          <Route exact path={ROUTES_FI.SETTINGS.USER_LIST} component={FinancierUserList} />

          {/* Financier - User - 사용자 등록 */}
          <Route exact path={ROUTES_FI.SETTINGS.USER_REGISTER} component={FinancierUserRegister} />

          {/* Financier - User - 사용자 상세  */}
          <Route path={ROUTES_FI.SETTINGS.USER_DETAIL} component={FinancierUserDetail} />

          {/* Settings - Interest rate/fee - 건벌 대출 수수료 목록 페이지*/}
          <Route
            exact
            path={ROUTES_FI.PROGRAM_SETTINGS.INTEREST_RATE_FEE_LIST}
            component={FinancierInterestRateFeeList}
          />

          {/* Settings - Interest rate/fee - 건벌 대출 수수료 등록 페이지*/}
          <Route
            exact
            path={ROUTES_FI.PROGRAM_SETTINGS.INTEREST_RATE_FEE_REGISTER}
            component={FinancierInterestRateFeeRegister}
          />

          {/* Settings - Delinquent Interest Rate */}
          <Route
            exact
            path={ROUTES_FI.PROGRAM_SETTINGS.DELINQUENT_INTEREST_RATE_LIST}
            component={FinancierDelinquentInterestRateSettingList}
          />
          <Route
            path={ROUTES_FI.PROGRAM_SETTINGS.DELINQUENT_INTEREST_RATE_REGISTER}
            component={FinancierDelinquentInterestRateSettingRegister}
          />
          <Route
            path={ROUTES_FI.PROGRAM_SETTINGS.DELINQUENT_INTEREST_RATE_DETAIL}
            component={FinancierDelinquentInterestRateSettingDetail}
          />

          {/* Settings - Holidays - 비영업일 목록 */}
          <Route exact path={ROUTES_FI.PROGRAM_SETTINGS.HOLIDAYS_LIST} component={FinancierHolidaysList} />

          {/* Settings - Holidays - 비영업일 등록 */}
          <Route exact path={ROUTES_FI.PROGRAM_SETTINGS.HOLIDAYS_REGISTER} component={FinancierHolidaysRegister} />

          {/* Settings - Holidays - 비영업일 수정 */}
          <Route path={ROUTES_FI.PROGRAM_SETTINGS.HOLIDAYS_DETAIL} component={FinancierHolidaysDetail} />

          {/* Settings - Operation hour - 업무시간 관리 페이지 */}
          <Route exact path={ROUTES_FI.PROGRAM_SETTINGS.OPERATION_HOURS} component={FinancierManageOperationHour} />

          {/* Settings - bank-code - 리스트 */}
          <Route exact path={ROUTES_FI.PROGRAM_SETTINGS.BANK_CODE_LIST} component={FinancierBankCodeList} />

          {/* Settings - bank-code - 등록 */}
          <Route exact path={ROUTES_FI.PROGRAM_SETTINGS.BANK_CODE_REGISTER} component={FinancierBankCodeRegister} />

          {/* Settings -  bank-code - confirmed detail */}
          <Route
            path={ROUTES_FI.PROGRAM_SETTINGS.BANK_CODE_CONFIRMED_DETAIL}
            component={FinancierBankCodeDetailConfirmed}
          />

          {/* Settings -  bank-code - waiting  detail */}
          <Route
            path={ROUTES_FI.PROGRAM_SETTINGS.BANK_CODE_WAITING_DETAIL}
            component={FinancierBankCodeWaitingDetail}
          />

          {/* Settings - document - 리스트 */}
          <Route exact path={ROUTES_FI.PROGRAM_SETTINGS.DOCUMENT_LIST} component={FinancierDocumentList} />

          {/* Settings - document - 신규 등록 페이지 */}
          <Route exact path={ROUTES_FI.PROGRAM_SETTINGS.DOCUMENT_REGISTER} component={FinancierDocumentRegister} />

          {/* Settings - additional-document - 등록페이지  */}
          <Route
            exact
            path={ROUTES_FI.PROGRAM_SETTINGS.ADDITIONAL_DOCUMENT_REGISTER}
            component={FinancierAdditionalDocumentRegister}
          />

          {/* Settings - additional-document - 상세페이지  */}
          <Route
            exact
            path={ROUTES_FI.PROGRAM_SETTINGS.ADDITIONAL_DOCUMENT_DETAIL}
            component={FinancierAdditionalDocumentDetail}
          />

          {/* Settings - document - 상세 페이지 */}
          <Route exact path={ROUTES_FI.PROGRAM_SETTINGS.DOCUMENT_DETAIL} component={FinancierDocumentDetail} />

          {/* Loan Setting */}
          <Route path={ROUTES_FI.PROGRAM_SETTINGS.FINANCING} component={FinancierFinancingSetting} />

          {/* Manage Financing - Program Charge Setting */}
          <Route
            exact
            path={ROUTES_FI.PROGRAM_SETTINGS.CHARGE_REGISTER}
            component={FinancierProgramChargeRegisterPage}
          />

          <Route exact path={ROUTES_FI.PROGRAM_SETTINGS.CHARGE} component={FinancierProgramChargeListPage} />

          <Route path={ROUTES_FI.PROGRAM_SETTINGS.CHARGE_DETAIL} component={FinancierProgramChargeDetailPage} />

          {/* Email / SMS Settings */}
          <Route path={ROUTES_FI.PROGRAM_SETTINGS.EMAIL_NOTIFICATION} component={FinancierEmailNotificationSetting} />

          {/* Manage Potential Partner */}
          <Route
            exact
            path={ROUTES_FI.MANAGE_UPLOADED_PARTNER.ASSIGNMENT_LIST}
            component={FinancierPotentialSupplyChainPartnerAssignmentList}
          />

          {/* Manage Potential Partner Detail */}
          <Route
            path={ROUTES_FI.MANAGE_UPLOADED_PARTNER.ASSIGNMENT_DETAIL}
            component={FinancierPotentialSupplyChainPartnerAssignmentDetail}
          />

          {/* Manage Potential Partner Bank Account Revision */}
          <Route
            exact
            path={ROUTES_FI.MANAGE_UPLOADED_PARTNER.BANK_ACCOUNT_REVISION_LIST}
            component={FinancierPotentialSupplyChainPartnerBankAccountRevisionList}
          />

          {/* Manage Potential Partner Bank Account waiting Revision Detail */}
          <Route
            path={ROUTES_FI.MANAGE_UPLOADED_PARTNER.BANK_ACCOUNT_REVISION_WAITING_DETAIL}
            component={FinancierPotentialSupplyChainPartnerBankAccountWaitingRevisionDetail}
          />

          {/* Manage Potential Partner Bank Account confirmed Revision Detail */}
          <Route
            path={ROUTES_FI.MANAGE_UPLOADED_PARTNER.BANK_ACCOUNT_REVISION_CONFIRMED_DETAIL}
            component={FinancierPotentialSupplyChainPartnerBankAccountConfirmedRevisionDetail}
          />

          {/* Manage Potential Partner Registration step1 */}
          <Route
            exact
            path={ROUTES_FI.MANAGE_UPLOADED_PARTNER.REGISTRATION_STEP1}
            component={FinancierPotentialSupplyChainPartnerRegisterStep1}
          />

          {/* Manage Potential Partner Registration */}
          <Route
            path={ROUTES_FI.MANAGE_UPLOADED_PARTNER.REGISTRATION_LIST}
            component={FinancierPotentialSupplyChainPartnerRegistrationList}
          />

          {/* Manage Potential Partner Registration waiting detail */}
          <Route
            path={ROUTES_FI.MANAGE_UPLOADED_PARTNER.REGISTRATION_WAITING_DETAIL}
            component={FinancierPotentialSupplyChainPartnerRegistrationWaitingDetail}
          />

          {/* Manage Potential Partner Registration confirmed detail */}
          <Route
            path={ROUTES_FI.MANAGE_UPLOADED_PARTNER.REGISTRATION_CONFIRMED_DETAIL}
            component={FinancierPotentialSupplyChainPartnerRegistrationConfirmedDetail}
          />

          {/* Manage Potential Partner Registration step2 */}
          <Route
            path={ROUTES_FI.MANAGE_UPLOADED_PARTNER.REGISTRATION_STEP2}
            component={FinancierPotentialSupplyChainPartnerRegisterStep2}
          />

          {/* Manage Potential Partner Acquisition Setting */}
          <Route
            path={ROUTES_FI.MANAGE_UPLOADED_PARTNER.ACQUISITION_SETTING}
            component={FinancierSCPartnerAcquisitionSettingPage}
          />

          {/* Manage Potential Partner Acquisition detail */}
          <Route
            path={ROUTES_FI.MANAGE_UPLOADED_PARTNER.ACQUISITION_DETAIL}
            component={FinancierScPartnerAcquisitionDetailPage}
          />

          {/* Manage Potential Partner Acquisition List */}
          <Route
            path={ROUTES_FI.MANAGE_UPLOADED_PARTNER.ACQUISITION_LIST}
            component={FinancierScPartnerAcquisitionListPage}
          />

          {/* Manage Anchor Dealer Registration */}
          <Route
            exact
            path={ROUTES_FI.MANAGE_DEALER.REGISTRATION_LIST}
            component={FinancierAnchorDealerRegistrationListPage}
          />

          <Route
            exact
            path={ROUTES_FI.MANAGE_DEALER.REGISTRATION_STEP1}
            component={FinancierAnchorDealerRegistrationStep1Page}
          />

          <Route
            exact
            path={ROUTES_FI.MANAGE_DEALER.REGISTRATION_STEP2}
            component={FinancierAnchorDealerRegistrationStep2Page}
          />

          <Route
            path={ROUTES_FI.MANAGE_DEALER.REGISTRATION_WAITING_DETAIL}
            component={FinancierAnchorDealerRegistrationWaitingDetailPage}
          />

          <Route
            path={ROUTES_FI.MANAGE_DEALER.REGISTRATION_DETAIL}
            component={FinancierAnchorDealerRegistrationConfirmedDetailPage}
          />

          {/* Manage Anchor Dealer Assignment */}
          <Route exact path={ROUTES_FI.MANAGE_DEALER.ASSIGNMENT_LIST} component={FinancierAnchorDealerAssignmentList} />

          <Route
            exact
            path={ROUTES_FI.MANAGE_DEALER.ASSIGNMENT_LIST_ADMIN}
            component={FinancierAnchorDealerAssignmentListAdmin}
          />
          <Route path={ROUTES_FI.MANAGE_DEALER.ASSIGNMENT_DETAIL} component={FinancierAnchorDealerAssignmentDetail} />

          {/* Financier - Billing */}
          <Route exact path={ROUTES_FI.BILLING} component={FinancierBilling} />

          {/* Financier - MyAccount - 내 정보 페이지 */}
          <Route exact path={ROUTES_FI.MY_ACCOUNT} component={FinancierMyAccount} />

          <Route path="*" component={NotFoundPage} />
        </Switch>
      </div>
    </div>
  );
}

export default FinancierRouter;
