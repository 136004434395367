import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { isNil } from 'lodash-es';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import { FormRadioWrap, FormSelect } from 'components/stateless/CommonForm';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import FormInput from 'components/stateless/CommonForm/FormInput';
import FormRadio from 'components/stateless/CommonForm/form-radio/FormRadio';
import Pagination from 'components/stateless/Pagination/Pagination';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import getSelectOptions from 'constants/selectOptions';
import {
  ANCHOR_AGREEMENT_STATUS,
  AUTHORITY_TYPE,
  COLLATERAL_TYPE,
  FINANCIER_INTERFACE_TYPE,
  OTP_TYPE,
  SUPPORTED_COLLATERAL_TYPE,
} from 'enums';
import { ColorMap } from 'enums/colorMap';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { SignInModel } from 'models/SignInModel';
import type { AnchorAgreementVOModel } from 'models/vo/AnchorAgreementVO';
import type { DivisionVOModel } from 'models/vo/DivisionVO';
import type { FinancierClientHistoryVOModel } from 'models/vo/FinancierClientHistoryVO';
import type { FinancierClientVOModel } from 'models/vo/FinancierClientVO';
import UpdateAnchorModal from 'pages/financier/manage-anchor/company/modals/UpdateAnchorModal';
import { formErrorHandler } from 'utils/error/manager';
import { requestFinancierSettingData } from 'utils/http/api/common/financier-common-setting';
import { requestFinancierAnchorAgreementList } from 'utils/http/api/financier/anchor-agreements';
import type { SearchFiAnchorAgreementListDTO } from 'utils/http/api/financier/anchor-agreements/requests';
import { requestFinancierAuthSetting } from 'utils/http/api/financier/client-auth-setting';
import { requestFiDivisions } from 'utils/http/api/financier/divisions';
import { requestFinancierClientHistories } from 'utils/http/api/financier/financier-client-histories';
import { requestFinancierClientDetail, requestFinancierClientUpdate } from 'utils/http/api/financier/financier-clients';
import type { FinancierClientUpdateRequest } from 'utils/http/api/financier/financier-clients/requests';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { checkInterfaceType, getCollateralType, getSignIn } from 'utils/storage/LocalStorage';
import { requestDTOParser } from 'utils/valueManager/ValueManager';

import EditDivisionModal from '../modals/EditDivisionModal';
import RegisterDivisionModal from '../modals/RegisterDivisionModal';

import type { RegisterDivisionRefHandle } from '../modals/RegisterDivisionModal';

function FinancierManageAnchorDetail() {
  const mounted = useMounted();
  const modal = useModal();
  const [financierManageAnchorData, setFinancierManageAnchorData] = useState<FinancierClientVOModel>();
  const [anchorAgreementsPage, setAnchorAgreementsPage] = useState<Pageable<AnchorAgreementVOModel[]>>();
  const [anchorInfoEditHistory, setAnchorInfoEditHistory] = useState<Pageable<FinancierClientHistoryVOModel[]>>();
  const [divisionsPage, setDivisionsPage] = useState<Pageable<DivisionVOModel[]>>();
  const [isShowEditDivisionModal, setIsShowEditDivisionModal] = useState(false);
  const [divisionRegistrable, setDivisionRegistrable] = useState(false);

  const anchorAgreementsPageHandler = usePageable();
  const financierManageAnchorHistoriesPageHandler = usePageable();
  const divisionsPageHandler = usePageable();
  const [willUpdateFinancierManageAnchorData, setWillUpdateFinancierManageAnchorData] =
    useState<FinancierClientUpdateRequest>();
  const [isEdit, setIsEdit] = useState(false);
  const { t } = useTranslation(['format']);
  const { financierClientId } = useParams() as any;
  const [isEnable] = useState<boolean>(checkInterfaceType(FINANCIER_INTERFACE_TYPE.ENTERPRISE)); // 전문 여부
  const [isSearched, setIsSearched] = useState<boolean>(false);
  const [isOTPUsed, setIsOTPUsed] = useState(false);

  const signInModel: SignInModel | null = getSignIn();
  const isOperator = signInModel?.authorityType === AUTHORITY_TYPE.OPERATOR;
  const isAuthorizer = signInModel?.authorityType === AUTHORITY_TYPE.AUTHORIZER;
  const isVendorFinance = getCollateralType() === SUPPORTED_COLLATERAL_TYPE.AR;

  const { register, reset, getValues, errors, setError, clearErrors } = useForm<FinancierClientUpdateRequest>({
    mode: 'onSubmit',
    shouldFocusError: true,
  });

  useEffect(() => {
    if (mounted) {
      initialize();
    }
  }, [mounted]);

  const initialize = async () => {
    await fetchAll(
      anchorAgreementsPageHandler.pageable.currentPage,
      anchorAgreementsPageHandler.pageable.sizePerPage,
      financierManageAnchorHistoriesPageHandler.pageable.currentPage,
      financierManageAnchorHistoriesPageHandler.pageable.sizePerPage,
      divisionsPageHandler.pageable.currentPage,
      divisionsPageHandler.pageable.sizePerPage,
    );
    setIsEdit(false);
    setIsSearched(false);
  };

  const fetchAll = async (
    anchorAgreementsPageNumber: number = 1,
    anchorAgreementsSizePerPage: number = 10,
    financierManageAnchorHistoriespageNumber: number = 1,
    financierManageAnchorHistoriesSizePerPage: number = 10,
    divisionsPageNumber: number = 1,
    divisionsSizePerPage: number = 10,
  ) => {
    try {
      const financierManageAnchorData = await requestFinancierClientDetail(financierClientId);
      const { divisionRegistrable } = await requestFinancierSettingData(financierManageAnchorData.financierId);

      const [anchorAgreementsPage, financierManageAnchorHistoriesPage, divisionsPageResponse, { otpType }] =
        await Promise.all([
          requestFinancierAnchorAgreementList(anchorAgreementsPageNumber, anchorAgreementsSizePerPage, {
            anchorClientId: financierClientId,
          } as SearchFiAnchorAgreementListDTO),
          requestFinancierClientHistories(
            financierManageAnchorHistoriespageNumber,
            financierManageAnchorHistoriesSizePerPage,
            financierClientId,
          ),
          requestFiDivisions(divisionsPageNumber, divisionsSizePerPage, {
            anchorClientId: financierManageAnchorData.financierClientId,
          }),
          requestFinancierAuthSetting(),
        ]);

      setAnchorInformationForm(financierManageAnchorData);
      setDivisionRegistrable(divisionRegistrable);

      ReactDOM.unstable_batchedUpdates(() => {
        setIsEdit(false);
        setFinancierManageAnchorData(financierManageAnchorData);
        setAnchorAgreementsPage(anchorAgreementsPage);
        anchorAgreementsPageHandler.setPageable(anchorAgreementsPage);
        setAnchorInfoEditHistory(financierManageAnchorHistoriesPage);
        financierManageAnchorHistoriesPageHandler.setPageable(financierManageAnchorHistoriesPage);
        setDivisionsPage(divisionsPageResponse);
        divisionsPageHandler.setPageable(divisionsPageResponse);
        setIsOTPUsed(otpType !== OTP_TYPE.NONE);
      });

      return financierManageAnchorData;
    } catch (error) {
      modal.show(error);
    }
  };

  const isReadonly = (parameterName: string): boolean => {
    if (isEdit) {
      if (isEnable) {
        if (parameterName === 'otpBypassed') return false;
        if (!willUpdateFinancierManageAnchorData) return true;

        return !(!(willUpdateFinancierManageAnchorData as any)[parameterName] && isSearched);
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const setAnchorInformationForm = (
    data?: FinancierClientVOModel | FinancierClientUpdateRequest,
    isInterface?: boolean,
  ) => {
    reset({
      ...data,
      financierClientTaxCode: isInterface
        ? financierManageAnchorData?.enterpriseId && !data?.financierClientTaxCode
          ? financierManageAnchorData?.financierClientTaxCode
          : data?.financierClientTaxCode
        : data?.financierClientTaxCode,
      autoSignUpForAnchorAdminOfVendorFinance: String(data?.autoSignUpForAnchorAdminOfVendorFinance),
    });
  };

  const showRequestConfirmModal = (): void => {
    modal.show(<h6>{t('text:The_information_has_been_saved_successfully')}</h6>, {
      modalType: ModalType.ALERT,
      confirmBtnText: t(`text:OK`),
      closeBtnCb: () => fetchAll(),
    });
  };

  const requestUpdate = async (e: any) => {
    e.preventDefault();

    const data = getValues();
    try {
      requestDTOParser(data);
      await requestFinancierClientUpdate(financierClientId, data);

      showRequestConfirmModal();
    } catch (e) {
      modal.show(e);

      formErrorHandler<FinancierClientUpdateRequest>(e, setError, clearErrors);
    }
  };

  const onEditClick = (event: any): void => {
    setIsEdit(true);
    setIsSearched(false);
    event.preventDefault();
  };

  const onCancelClick = (event: any): void => {
    event.preventDefault();
    modal.show(
      <h6>
        {t('text:Would_you_like_to_stop_modifying_the_information?')}
        <br />
        {t('text:If_the_modification_is_aborted_the_entered_content_will_not_be_saved')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Close'),
        confirmBtnCb: () => {
          setIsEdit(false);
          setIsSearched(false);
          setAnchorInformationForm(financierManageAnchorData);
        },
      },
    );
  };

  const getAnchorAgreementStatusClassName = (anchorAgreementStatus: string) => {
    switch (anchorAgreementStatus) {
      case ANCHOR_AGREEMENT_STATUS.ACTIVATED:
        return 'text-bold-dark-green';
      case ANCHOR_AGREEMENT_STATUS.SUSPENDED:
        return 'text-bold-brick-red';
      default:
        return '';
    }
  };

  const anchorAgreementsPaginate = async (pageNumber: number, sizePerPage: number) => {
    try {
      const anchorAgreementsPage = await requestFinancierAnchorAgreementList(pageNumber, sizePerPage, {
        anchorClientId: financierClientId,
      } as SearchFiAnchorAgreementListDTO);

      setAnchorAgreementsPage(anchorAgreementsPage);
      anchorAgreementsPageHandler.setPageable(anchorAgreementsPage);
    } catch (error) {
      modal.show(error);
    }
  };

  const financierManageAnchorHistoriesPaginate = async (pageNumber: number, sizePerPage: number) => {
    try {
      const waitingFinancierManageAnchorsPage = await requestFinancierClientHistories(
        pageNumber,
        sizePerPage,
        financierClientId,
      );

      setAnchorInfoEditHistory(waitingFinancierManageAnchorsPage);
      financierManageAnchorHistoriesPageHandler.setPageable(waitingFinancierManageAnchorsPage);
    } catch (error) {
      modal.show(error);
    }
  };

  const divisionsPaginate = async (pageNumber: number, sizePerPage: number) => {
    try {
      const divisionsPageResponse = await requestFiDivisions(pageNumber, sizePerPage, {
        anchorClientId: financierManageAnchorData?.financierClientId,
      });

      setDivisionsPage(divisionsPageResponse);
      divisionsPageHandler.setPageable(divisionsPageResponse);
    } catch (error) {
      modal.show(error);
    }
  };

  const showUpdateAnchorModal = (event: any): void => {
    const setWillUpdateData = (data: FinancierClientUpdateRequest) => {
      setAnchorInformationForm(data, true);
    };

    modal.show(
      <UpdateAnchorModal
        financierClientData={financierManageAnchorData}
        propFunction={setWillUpdateData}
        isOtpUsed={isOTPUsed}
        modalId={modal.id}
      />,
      {
        modalType: ModalType.CONFIRM,
        modalSize: ModalSize.XL,
        title: t(`text:Update_Anchor`),
        confirmBtnText: t(`text:Apply`),
        confirmBtnCb: () => {
          setWillUpdateFinancierManageAnchorData(getValues());
          setIsSearched(true);
        },
        closeBtnText: t(`text:Close`),
        closeBtnCb: () => {
          setAnchorInformationForm(financierManageAnchorData);
        },
      },
    );

    event.preventDefault();
  };

  const renderAnchorInformation = () => {
    return (
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Anchor_Information')}>
            {signInModel?.authorityType !== AUTHORITY_TYPE.ADMIN &&
              (!isEdit ? (
                <Button size={ButtonSizeEnum.SM} onClick={onEditClick} style={{ width: '60px' }}>
                  {t('text:Edit')}
                </Button>
              ) : (
                <>
                  <Button
                    size={ButtonSizeEnum.SM}
                    variant={ButtonVariantEnum.OUTLINED}
                    color={ButtonColorEnum.SECONDARY}
                    onClick={onCancelClick}
                  >
                    {t('text:Cancel')}
                  </Button>
                  <Button size={ButtonSizeEnum.SM} onClick={requestUpdate} style={{ width: '60px' }}>
                    {t('text:Save')}
                  </Button>
                </>
              ))}
          </SectionTitle>
          <FormBorder editable={isEdit}>
            <FormContents>
              {isEnable && isEdit && (
                <div className="row">
                  <div className="d-flex">
                    <Button size={ButtonSizeEnum.LG} onClick={showUpdateAnchorModal}>
                      {t(`text:Update_Anchor`)}
                    </Button>
                  </div>
                </div>
              )}
              <div className="row">
                <FormInput
                  label={t(`text:Client_Code`)}
                  value={financierManageAnchorData?.financierClientCode ?? ''}
                  disabled={true}
                  requiredOptions={{ required: true }}
                />
                <FormInput
                  label={t(`text:Anchor_Name`)}
                  name="financierClientName"
                  ref={register}
                  disabled={isReadonly('financierClientName')}
                  requiredOptions={{ required: true }}
                  error={errors.financierClientName}
                />
              </div>
              <div className="row">
                <FormInput
                  col={3}
                  label={t(`text:Tax_Code`)}
                  requiredOptions={{
                    fixedRequired:
                      (isEnable &&
                        !isReadonly('financierClientTaxCode') &&
                        isNil(financierManageAnchorData?.enterpriseId)) ||
                      (!isEnable && isEdit && isNil(financierManageAnchorData?.enterpriseId)),
                  }}
                  name="financierClientTaxCode"
                  ref={register}
                  disabled={
                    !(!isReadonly('financierClientTaxCode') && financierManageAnchorData?.enterpriseId === null)
                  }
                  error={errors.financierClientTaxCode}
                />
                <FormInput
                  col={3}
                  label={t(`text:Company_Registration_Number`)}
                  name="financierClientBusinessCode"
                  ref={register}
                  disabled={isReadonly('financierClientBusinessCode')}
                  error={errors.financierClientBusinessCode}
                />
                <FormInput
                  col={3}
                  label={t(`text:Telephone`)}
                  name="financierClientTelephone"
                  ref={register}
                  disabled={isReadonly('financierClientTelephone')}
                  error={errors.financierClientTelephone}
                />
                <FormInput
                  col={3}
                  label={t(`text:Fax`)}
                  name="financierClientFax"
                  ref={register}
                  disabled={isReadonly('financierClientFax')}
                  error={errors.financierClientFax}
                />
              </div>
              <div className="row">
                <FormInput
                  col={isOTPUsed ? 3 : 6}
                  label={t(`text:Legal_Representative_Name`)}
                  name="representativeName"
                  ref={register}
                  disabled={isReadonly('representativeName')}
                  error={errors.representativeName}
                />
                <FormInput
                  col={3}
                  label={t(`text:Legal_Representative_Title`)}
                  name="representativePosition"
                  ref={register}
                  disabled={isReadonly('representativePosition')}
                  error={errors.representativePosition}
                />
                <FormInput
                  col={3}
                  label={t(`text:Legal_Representative_Email`)}
                  name="representativeEmail"
                  ref={register}
                  disabled={isReadonly('representativeEmail')}
                  error={errors.representativeEmail}
                />
                {isOTPUsed && (
                  <FormSelect
                    col={3}
                    label={t('text:OTP_Verification')}
                    selectOptions={getSelectOptions('OTP_VERIFICATION')}
                    name="otpBypassed"
                    ref={register}
                    error={errors.otpBypassed}
                    disabled={isReadonly('otpBypassed')}
                    required={true}
                    placeholderOptions={{ show: true }}
                  />
                )}
              </div>
              <div className="row">
                <FormInput
                  col={12}
                  label={t(`text:Registered_Office_Address`)}
                  name="financierClientAddress"
                  ref={register}
                  disabled={isReadonly('financierClientAddress')}
                  error={errors.financierClientAddress}
                />
              </div>
              {!isEnable && isVendorFinance && (
                <div className="row">
                  <FormRadioWrap label={t('text:VFS_without_Anchor_User_Invitation')}>
                    <div style={{ color: ColorMap.sub500 }}>
                      {t(
                        'text:Even_if_you_select_Yes_you_must_register_an_agreement_with_the_anchor_user_to_apply_for_the_financing',
                      )}
                      <br />
                      {t('text:When_registering_an_agreement_the_anchor_user_is_automatically_signed_up')}
                    </div>
                    <br />
                    <FormRadio
                      label={t('text:Yes')}
                      name="autoSignUpForAnchorAdminOfVendorFinance"
                      ref={register}
                      value="true"
                    />
                    <FormRadio
                      label={t('text:No')}
                      name="autoSignUpForAnchorAdminOfVendorFinance"
                      ref={register}
                      value="false"
                    />
                  </FormRadioWrap>
                </div>
              )}
            </FormContents>
          </FormBorder>
        </form>
      </div>
    );
  };

  const renderAgreementListTable = () => {
    const AGREEMENT_LIST_HEADERS: HeaderType[] = [
      {
        headerText: t('text:Program_Type'),
        colWidths: 100,
      },
      {
        headerText: t('text:Anchor_Master_Agreement_Number'),
        colWidths: 100,
      },
      {
        headerText: t('text:Currency'),
        colWidths: 96,
      },
      {
        headerText: t('text:Responsible_Branch_Name'),
        colWidths: 120,
      },
      {
        headerText: t('text:Responsible_Branch_Code'),
        colWidths: 120,
      },
      {
        headerText: t('text:Effective_Date'),
        colWidths: 120,
      },
      {
        headerText: t('text:Expiration_Date'),
        colWidths: 120,
      },
      {
        headerText: t('text:Agreement_Suspension'),
        colWidths: 90,
      },
      {
        headerText: '',
        colWidths: 50,
      },
    ];

    const returnTableBody = () => {
      return anchorAgreementsPage?.content.map((item, index) => {
        return (
          <Tr key={index}>
            <Td data={renderAgreementType(item.collateralType)} />
            <Td data={item.contractNo} />
            <Td data={item.currencyType} />
            <Td data={item.branchName} />
            <Td data={item.branchCode} />
            <Td data={item.startDate} format="date" />
            <Td data={item.expiryDate} format="date" />
            <Td
              className={getAnchorAgreementStatusClassName(item.anchorAgreementStatus)}
              data={t(`code:anchor-agreement-status.${item.anchorAgreementStatus}`)}
              format="code"
            />
            <TdLink
              path={ROUTES_FI.MANAGE_ANCHOR.AGREEMENT_REGISTERED_DETAIL_BUILD_PATH(item.anchorAgreementId)}
              state={{ supportedCollateralType: item.collateralType }}
            />
          </Tr>
        );
      });
    };

    return (
      <>
        <TableBorder>
          <TableHeader header={AGREEMENT_LIST_HEADERS} />
          <TableBody numOfCol={AGREEMENT_LIST_HEADERS.length}>{returnTableBody()}</TableBody>
        </TableBorder>
        <Pagination pageable={anchorAgreementsPageHandler.pageable} paginate={anchorAgreementsPaginate} />
      </>
    );
  };

  const renderCompanyDivisionsTable = () => {
    const DIVISION_LIST_HEADERS: HeaderType[] = [
      {
        headerText: t('text:Division_Code'),
        colWidths: 120,
      },
      {
        headerText: t('text:Division_Name'),
        colWidths: 120,
      },
      {
        headerText: t('text:Financing_Limit'),
        colWidths: 120,
      },
      {
        headerText: t('text:Financing_Limit_Amount'),
        colWidths: 120,
      },
    ];

    const EDITABLE_DIVISION_LIST_HEADERS = [
      ...DIVISION_LIST_HEADERS,
      {
        headerText: '',
        colWidths: 50,
      },
    ];

    const returnTableBody = () => {
      return divisionsPage?.content.map((division, index) => (
        <Tr key={index}>
          <Td data={division.code} />
          <Td data={division.name} />
          <Td data={division.limitAllowable ? t('text:Applied') : t('text:Not_Applied')} />
          <Td>
            {division.limitAllowable
              ? `${t('format:number', { value: division.limitAmount })} ${division.limitCurrencyType}`
              : '-'}
          </Td>
          {(isOperator || isAuthorizer) && (
            <Td className="information-table-more">
              <Button size={ButtonSizeEnum.SM} onClick={() => handleClickEditDivisionButton(division.id)} bold>
                {t('text:Edit')}
              </Button>
            </Td>
          )}
        </Tr>
      ));
    };

    return (
      <>
        <TableBorder>
          <TableHeader header={isOperator || isAuthorizer ? EDITABLE_DIVISION_LIST_HEADERS : DIVISION_LIST_HEADERS} />
          <TableBody
            numOfCol={isOperator || isAuthorizer ? EDITABLE_DIVISION_LIST_HEADERS.length : DIVISION_LIST_HEADERS.length}
          >
            {returnTableBody()}
          </TableBody>
        </TableBorder>
        <Pagination pageable={divisionsPageHandler.pageable} paginate={divisionsPaginate} />
      </>
    );
  };

  const renderHistoryListTable = () => {
    const HISTORY_LIST_HEADERS: HeaderType[] = [
      {
        headerText: t('text:Edited_Time'),
        colWidths: 120,
      },
      {
        headerText: t('text:Client_Code'),
        colWidths: 100,
      },
      {
        headerText: t('text:Anchor_Name'),
        colWidths: 150,
      },
      {
        headerText: t('text:Telephone'),
        colWidths: 120,
      },
      {
        headerText: t('text:Fax'),
        colWidths: 120,
      },
      {
        headerText: t('text:Company_Registration_Number'),
        colWidths: 120,
      },
      {
        headerText: t('text:Tax_Code'),
        colWidths: 120,
      },
      {
        headerText: t('text:Registered_Office_Address'),
        colWidths: 250,
      },
      {
        headerText: t('text:Legal_Representative_Name'),
        colWidths: 150,
      },
      {
        headerText: t('text:Legal_Representative_Title'),
        colWidths: 150,
      },
      {
        headerText: t('text:Legal_Representative_Email'),
        colWidths: 150,
      },
      {
        headerText: t('text:Updated_User_ID/Name'),
        colWidths: 100,
      },
    ];

    if (isOTPUsed) {
      HISTORY_LIST_HEADERS.splice(HISTORY_LIST_HEADERS.length - 1, 0, {
        headerText: t('text:OTP_Verification'),
        colWidths: 150,
      });
    }

    const returnTableBody = () => {
      return anchorInfoEditHistory?.content.map((item, index) => {
        return (
          <Tr key={index}>
            <Td data={item.updatedDateTime} format="datetime" />
            <Td data={item.financierClientCode} />
            <Td data={item.name} />
            <Td data={item.telephone} />
            <Td data={item.fax} />
            <Td data={item.clientBusinessCode} />
            <Td data={item.clientTaxCode} />
            <Td data={item.address} />
            <Td data={item.representativeName} />
            <Td data={item.representativePosition} />
            <Td data={item.representativeEmail} />
            {isOTPUsed && <Td data={item.otyBypassed ? t('text:Not_Applied') : t('text:Applied')} />}
            <Td data={`${item.updateLoginId} / ${item.updateUserName}`} />
          </Tr>
        );
      });
    };

    return (
      <>
        <TableBorder>
          <TableHeader header={HISTORY_LIST_HEADERS} />
          <TableBody numOfCol={HISTORY_LIST_HEADERS.length}>{returnTableBody()}</TableBody>
        </TableBorder>
        <Pagination
          pageable={financierManageAnchorHistoriesPageHandler.pageable}
          paginate={financierManageAnchorHistoriesPaginate}
        />
      </>
    );
  };

  const renderAgreementType = (collateralType: COLLATERAL_TYPE): string => {
    switch (collateralType) {
      case COLLATERAL_TYPE.AR:
        return t('text:Vendor_Finance');
      case COLLATERAL_TYPE.INVOICE:
        return t('text:Dealer_Finance');
    }
  };

  const registerDivisionRef = useRef<RegisterDivisionRefHandle>(null);
  const showDivisionRegisterModal = () => {
    modal.show(
      <RegisterDivisionModal
        anchorClientId={financierClientId}
        modalId={modal.id}
        fetchDivisionList={() => divisionsPaginate(1, divisionsPageHandler.pageable.sizePerPage)}
        ref={registerDivisionRef}
      />,
      {
        modalType: ModalType.CONFIRM,
        modalSize: ModalSize.L,
        title: t('text:Register_Division'),
        isModalClosesOnConfirm: false,
        confirmBtnCb: async () => {
          await registerDivisionRef.current?.handleRegisterDivision();
        },
        closeBtnText: t('text:Cancel'),
      },
    );
  };

  const divisionId = useRef(NaN);
  const handleClickEditDivisionButton = (id: number) => {
    divisionId.current = id;
    setIsShowEditDivisionModal(true);
  };
  const closeEditDivisionModal = () => setIsShowEditDivisionModal(false);

  return (
    <>
      <BackHeaderTitle title={financierManageAnchorData?.financierClientName} />
      {renderAnchorInformation()}
      {divisionRegistrable && (
        <div className="content-area">
          <SectionTitle title={t(`text:Company_Division`)}>
            {(isOperator || isAuthorizer) && (
              <Button size={ButtonSizeEnum.SM} onClick={showDivisionRegisterModal}>
                {t('text:Add')}
              </Button>
            )}
          </SectionTitle>
          {renderCompanyDivisionsTable()}
        </div>
      )}
      <div className="content-area">
        <SectionTitle title={t(`text:Master_Agreement_List`)}>
          {isOperator && (
            <Button to={ROUTES_FI.MANAGE_ANCHOR.AGREEMENT_LIST} size={ButtonSizeEnum.SM}>
              {t('text:Add')}
            </Button>
          )}
        </SectionTitle>
        {renderAgreementListTable()}
      </div>

      <div className="content-area">
        <SectionTitle title={t(`text:Information_Edit_History`)} />
        {renderHistoryListTable()}
      </div>
      {isShowEditDivisionModal && (
        <EditDivisionModal
          divisionId={divisionId.current}
          reFetchDivisions={() => divisionsPaginate(1, divisionsPageHandler.pageable.sizePerPage)}
          isShow={isShowEditDivisionModal}
          closeModal={closeEditDivisionModal}
        />
      )}
    </>
  );
}

export default FinancierManageAnchorDetail;
