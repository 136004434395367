import { useTranslation } from 'react-i18next';

import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import type { SuccessArDetailVOModel } from 'models/vo/SuccessArDetailVO';

interface FinancierAnchorLoanDetailArInformationProps {
  arData: SuccessArDetailVOModel;
}

function FinancierAnchorLoanDetailArInformation({ arData }: FinancierAnchorLoanDetailArInformationProps) {
  const { t } = useTranslation(['format']);

  return (
    <div className="content-area">
      <SectionTitle title={t('text:AR_Information')} />
      <FormBorder>
        <FormContents backGroundType={BackGroundType.WHITE}>
          <div className="row">
            <FormValue
              col={3}
              className="information-form__input border-none bold-font"
              label={t('text:AR_Number')}
              value={arData.arNumber}
            />
            <FormValue
              col={3}
              className="information-form__input border-none bold-font"
              label={t('text:AR_Amount')}
              value={arData.arAmount}
              format="number"
            />
            <FormValue
              col={3}
              className="information-form__input border-none bold-font"
              label={t('text:AR_Issued_Date')}
              value={arData.arIssuedDate}
              format="date"
            />

            <FormValue
              col={3}
              className="information-form__input border-none bold-font"
              label={t('text:Settlement_Date')}
              value={arData.settlementDate}
              format="date"
            />
          </div>
          <div className="row">
            <FormValue col={3} label={t('text:Anchor_Name')} value={arData.anchorName} />
            <FormValue col={3} label={t('text:Partner_Name')} value={arData.anchorPartnerName} />
            <FormValue col={3} label={t('text:Currency')} value={arData.currencyType} />
          </div>
        </FormContents>
      </FormBorder>
    </div>
  );
}

export default FinancierAnchorLoanDetailArInformation;
