import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button, { ButtonSizeEnum } from 'components/stateless/Button/Button';
import { BackHeaderTitle } from 'components/stateless/Title/BackHeaderTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import { COLLATERAL_TYPE } from 'enums';
import useMounted from 'hooks/useMounted';
import { isLoanDisbursed } from 'utils/status';

import FinancierAnchorLoanDetailArInformation from './sections/ar-information';
import FinancierAnchorFinancingDetailDesignatedBankAccountInformation from './sections/designated-bank-account-information';
import FinancierAnchorFinancingDetailFinancingRequestInformation from './sections/financing-request-information';
import FinancierAnchorFinancingDetailGuideMessage from './sections/guide-message';
import FinancierAnchorFinancingDetailInvoiceInformation from './sections/invoice-information';
import FinancierAnchorFinancingDetailSummary from './sections/summary';
import { useFinancierAnchorLoanDetailState } from './useFinancierAnchorLoanDetailState';
import FinancierFinancingDetailRepaymentTransactionHistory from '../../../view-transactions/financing/detail/sections/repayment-transaction-history';

function FinancierAnchorLoanDetail() {
  const { t } = useTranslation();
  const mounted = useMounted();
  const history = useHistory();

  const {
    state,
    fetchAll,
    fetchAnchorLoanTransactionHistory,
    loanTransactionHistoryPageable,
    onClickViewExpectedRepaymentAmount,
    updateFinancingStatusActions,
  } = useFinancierAnchorLoanDetailState();

  const { loanDetail, loanTransactionHistoryPage } = state;

  const { loanStatus } = loanDetail;

  const { onClickDeleteLoanTransactions, onClickRenewLoanTransactions } = updateFinancingStatusActions;

  useEffect(() => {
    if (mounted) {
      fetchAll();
    }
  }, [mounted]);

  return (
    <>
      <BackHeaderTitle title={t('text:Financing_Details')} />
      <FinancierAnchorFinancingDetailGuideMessage
        loanDetailData={loanDetail}
        updateFinancingStatusActions={updateFinancingStatusActions}
      />

      <FinancierAnchorFinancingDetailSummary
        data={loanDetail}
        onClickViewExpectedRepaymentAmount={onClickViewExpectedRepaymentAmount}
      />

      <FinancierAnchorFinancingDetailFinancingRequestInformation data={loanDetail} />
      {loanDetail.collateralType === COLLATERAL_TYPE.INVOICE && (
        <FinancierAnchorFinancingDetailInvoiceInformation invoiceData={state.invoiceDetail} />
      )}
      {loanDetail.collateralType === COLLATERAL_TYPE.AR && (
        <FinancierAnchorLoanDetailArInformation arData={state.arDetail} />
      )}

      <FinancierAnchorFinancingDetailDesignatedBankAccountInformation data={loanDetail} />

      {isLoanDisbursed(loanStatus) && (
        <FinancierFinancingDetailRepaymentTransactionHistory
          useInterface={false}
          loanStatus={loanStatus}
          loanTransactionHistoryData={loanTransactionHistoryPage}
          onClickDeleteLoanTransactions={onClickDeleteLoanTransactions}
          onClickRenewLoanTransactions={onClickRenewLoanTransactions}
          factoringEnable={false}
          pageable={loanTransactionHistoryPageable}
          paginate={fetchAnchorLoanTransactionHistory}
        />
      )}
      <div className="content-area flex-end">
        <Button
          size={ButtonSizeEnum.LG}
          onClick={() => {
            history.push(
              ROUTES_FI.MANAGE_ANCHOR_FINANCING.APPROVAL_DETAIL_BUILD_PATH(loanDetail.anchorMultipleLoanRequestId),
            );
          }}
        >
          {t('text:Go_to_Bulk_Financing_Page')}
        </Button>
      </div>
    </>
  );
}

export default FinancierAnchorLoanDetail;
