import { useTranslation } from 'react-i18next';

import GuideMessage, { MessageType } from 'components/stateless/GuideMessage/GuideMessage';
import { LOAN_STATUS } from 'enums';
import type { AnchorLoanDetailVOModel } from 'models/vo/AnchorLoanDetailVO';

import AnchorLoanAppliedStatusGuideMessage from './AnchorLoanAppliedStatusGuideMessage';
import AnchorLoanApproveStatusGuideMessage from './AnchorLoanApproveStatusGuideMessage';
import AnchorLoanDisbursedStatusGuideMessage from './AnchorLoanDisbursedStatusGuideMessage';
import AnchorLoanOverdueStatusGuideMessage from './AnchorLoanOverdueStatusGuideMessage';

interface FinancierAnchorFinancingDetailGuideMessageProps {
  loanDetailData: AnchorLoanDetailVOModel;
  updateFinancingStatusActions: any;
}

function FinancierAnchorFinancingDetailGuideMessage({
  loanDetailData,
  updateFinancingStatusActions,
}: FinancierAnchorFinancingDetailGuideMessageProps) {
  const { t } = useTranslation(['format']);

  const { loanStatus, rejectReason, expirationReason } = loanDetailData;

  const {
    onClickRequestFinancierLoanRepayment,
    onClickRequestFinancierLoanOverdue,
    onClickViewRepaymentHistory,
    onClickCancelRepayment,
    onClickConfirmRepaidStatus,
    onClickRevertRepaidStatusRequest,
    onClickCancelOverdue,
    onClickConfirmDelinquentStatus,
    onClickRevertDelinquentStatusRequest,
    onClickRemoveDelinquentStatus,
    onClickCancelOverdueRelease,
    onClickConfirmDelinquentStatusRemovalRequest,
    onClickRevertDelinquentStatusRemovalRequest,
    onClickExpiredStatusRequest,
    onClickCancelExpiredStatusRequest,
    onClickConfirmExpiredStatusRequest,
    onClickRevertExpiredStatusRequest,
    onClickExecuteLoanDisbursement,
    onClickRenewLoanTransactions,
    onClickRequestCancelDisbursement,
    onClickCancelDisbursementCancellationRequest,
    onClickApproveDisbursementCancellation,
    onClickRevertDisbursementCancellation,
  } = updateFinancingStatusActions;

  const { DEALER_REQUESTED, REJECTED, APPROVED, DISBURSED, OVERDUE, EXPIRATION } = LOAN_STATUS;

  return (
    <>
      {loanStatus === DEALER_REQUESTED && <AnchorLoanAppliedStatusGuideMessage loanDetailData={loanDetailData} />}
      {loanStatus === REJECTED && (
        <GuideMessage
          messageType={MessageType.ALERT}
          message={[
            t('text:The_financing_application_has_been_rejected'),
            t('text:Check_the_reason_for_the_rejection_below'),
          ]}
          reasonTitle={t('text:REASON_FOR_REJECTION')}
          reason={rejectReason}
        />
      )}
      {loanStatus === APPROVED && (
        <AnchorLoanApproveStatusGuideMessage
          loanDetailData={loanDetailData}
          onClickExecuteLoanDisbursement={onClickExecuteLoanDisbursement}
        />
      )}
      {loanStatus === DISBURSED && (
        <AnchorLoanDisbursedStatusGuideMessage
          loanDetailData={loanDetailData}
          onClickCancelRepayment={onClickCancelRepayment}
          onClickRenewLoanTransactions={onClickRenewLoanTransactions}
          onClickViewRepaymentHistory={onClickViewRepaymentHistory}
          onClickConfirmRepaidStatus={onClickConfirmRepaidStatus}
          onClickRevertRepaidStatusRequest={onClickRevertRepaidStatusRequest}
          onClickConfirmDelinquentStatus={onClickConfirmDelinquentStatus}
          onClickRevertDelinquentStatusRequest={onClickRevertDelinquentStatusRequest}
          onClickCancelDisbursementCancellationRequest={onClickCancelDisbursementCancellationRequest}
          onClickRequestCancelDisbursement={onClickRequestCancelDisbursement}
          onClickApproveDisbursementCancellation={onClickApproveDisbursementCancellation}
          onClickRequestFinancierLoanOverdue={onClickRequestFinancierLoanOverdue}
          onClickCancelOverdue={onClickCancelOverdue}
          onClickRequestFinancierLoanRepayment={onClickRequestFinancierLoanRepayment}
          onClickRevertDisbursementCancellation={onClickRevertDisbursementCancellation}
        />
      )}
      {loanStatus === OVERDUE && (
        <AnchorLoanOverdueStatusGuideMessage
          loanDetailData={loanDetailData}
          onClickCancelRepayment={onClickCancelRepayment}
          onClickRenewLoanTransactions={onClickRenewLoanTransactions}
          onClickViewRepaymentHistory={onClickViewRepaymentHistory}
          onClickCancelOverdueRelease={onClickCancelOverdueRelease}
          onClickRevertRepaidStatusRequest={onClickRevertRepaidStatusRequest}
          onClickConfirmDelinquentStatusRemovalRequest={onClickConfirmDelinquentStatusRemovalRequest}
          onClickRevertDelinquentStatusRemovalRequest={onClickRevertDelinquentStatusRemovalRequest}
          onClickExpiredStatusRequest={onClickExpiredStatusRequest}
          onClickCancelExpiredStatusRequest={onClickCancelExpiredStatusRequest}
          onClickConfirmExpiredStatusRequest={onClickConfirmExpiredStatusRequest}
          onClickRevertExpiredStatusRequest={onClickRevertExpiredStatusRequest}
          onClickRemoveDelinquentStatus={onClickRemoveDelinquentStatus}
        />
      )}
      {loanStatus === EXPIRATION && (
        <GuideMessage
          messageType={MessageType.ALERT}
          message={[t('text:The_financing_has_expired'), t('text:Check_the_reason_for_the_expiration_below')]}
          reasonTitle={t('text:REASON_FOR_EXPIRATION')}
          reason={expirationReason}
        />
      )}
    </>
  );
}

export default FinancierAnchorFinancingDetailGuideMessage;
