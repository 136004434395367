import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import GuideMessage, { MessageType } from 'components/stateless/GuideMessage/GuideMessage';
import { AUTHORITY_TYPE, LOAN_APPROVAL_TYPE } from 'enums';
import type { SignInModel } from 'models/SignInModel';
import type { AnchorLoanDetailVOModel } from 'models/vo/AnchorLoanDetailVO';
import { getSignIn } from 'utils/storage/LocalStorage';

interface AnchorLoanDisbursedStatusGuideMessageProps {
  loanDetailData: AnchorLoanDetailVOModel;
  onClickCancelRepayment(e: any): void;
  onClickRenewLoanTransactions(e: any): void;
  onClickViewRepaymentHistory(e: any): void;
  onClickConfirmRepaidStatus(e: any): void;
  onClickRevertRepaidStatusRequest(e: any): void;
  onClickConfirmDelinquentStatus(e: any): void;
  onClickRevertDelinquentStatusRequest(e: any): void;
  onClickCancelDisbursementCancellationRequest(e: any): void;
  onClickRequestCancelDisbursement(e: any): void;
  onClickApproveDisbursementCancellation(e: any): void;
  onClickRequestFinancierLoanOverdue(e: any): void;
  onClickCancelOverdue(e: any): void;
  onClickRequestFinancierLoanRepayment(e: any): void;
  onClickRevertDisbursementCancellation(e: any): void;
}

function AnchorLoanDisbursedStatusGuideMessage({
  loanDetailData,
  onClickCancelRepayment,
  onClickRenewLoanTransactions,
  onClickViewRepaymentHistory,
  onClickConfirmRepaidStatus,
  onClickRevertRepaidStatusRequest,
  onClickConfirmDelinquentStatus,
  onClickRevertDelinquentStatusRequest,
  onClickCancelDisbursementCancellationRequest,
  onClickRequestCancelDisbursement,
  onClickApproveDisbursementCancellation,
  onClickRequestFinancierLoanOverdue,
  onClickCancelOverdue,
  onClickRequestFinancierLoanRepayment,
  onClickRevertDisbursementCancellation,
}: AnchorLoanDisbursedStatusGuideMessageProps) {
  const { t } = useTranslation();
  const signInModel: SignInModel | null = getSignIn();
  const { ADMIN, AUTHORIZER, OPERATOR } = AUTHORITY_TYPE;
  const { authorityType } = signInModel ?? {};
  const isAdmin = authorityType === ADMIN;
  const isOperator = authorityType === OPERATOR;
  const isAuthorizer = authorityType === AUTHORIZER;

  const { loanApprovalType, returnReason, cancelDisbursementReason } = loanDetailData;

  const renderApprovedLoanStatusMessage = (loanApprovalType: LOAN_APPROVAL_TYPE) => {
    switch (loanApprovalType) {
      case LOAN_APPROVAL_TYPE.OPERATOR_REPAID:
        if (isOperator) {
          const operatorRepaidStatusOperatorGuideMessage = [
            t('text:The_request_to_change_the_financing_status_to_Repaid_has_been_submitted_to_the_Authorizer'),
            t('text:The_financing_status_will_change_when_the_Authorizer_approves_the_request'),
            t(
              'text:Cancel_the_repaid_status_request_before_the_Authorizers_approval_by_clicking_on_the_Cancel_Repaid_Status_Request_button_on_the_right',
            ),
            t(
              'text:If_there_are_any_repayment_transactions_before_the_financing_is_completely_repaid_click_on_the_Update_Repayment_Transaction_button_to_reflect_the_changes',
            ),
          ];

          return (
            <GuideMessage message={operatorRepaidStatusOperatorGuideMessage}>
              {{
                button: (
                  <>
                    <Button
                      variant={ButtonVariantEnum.OUTLINED}
                      color={ButtonColorEnum.SECONDARY}
                      onClick={onClickCancelRepayment}
                    >
                      {t('text:Cancel_Repaid_Status_Request')}
                    </Button>
                    <Button onClick={onClickRenewLoanTransactions}>{t('text:Update_Repayment_Transaction')}</Button>
                  </>
                ),
                link: (
                  <button className="guide-message-link-btn" onClick={onClickViewRepaymentHistory}>
                    {t('text:View_Repayment_History')}
                  </button>
                ),
              }}
            </GuideMessage>
          );
        } else if (isAuthorizer) {
          const operatorRepaidStatusAuthorizerGuideMessage = [
            t('text:The_repaid_status_has_been_requested'),
            t(
              'text:Check_the_repayment_information_and_approve_the_request_by_clicking_on_the_Confirm_Repaid_Status_Button',
            ),
            t(
              'text:If_the_repayment_has_not_been_completed_click_on_the_Revert_Repaid_Status_Request_button_and_revert_the_request',
            ),
            t(
              'text:If_there_are_any_repayment_transactions_before_the_financing_is_completely_repaid_click_on_the_Update_Repayment_Transaction_button_to_reflect_the_changes',
            ),
          ];

          return (
            <GuideMessage message={operatorRepaidStatusAuthorizerGuideMessage}>
              {{
                button: (
                  <>
                    <Button onClick={onClickConfirmRepaidStatus}>{t('text:Confirm_Repaid_Status')}</Button>
                    <Button
                      variant={ButtonVariantEnum.OUTLINED}
                      color={ButtonColorEnum.SECONDARY}
                      onClick={onClickRevertRepaidStatusRequest}
                    >
                      {t('text:Revert_Repaid_Status_Request')}
                    </Button>

                    <Button onClick={onClickRenewLoanTransactions}>{t('text:Update_Repayment_Transaction')}</Button>
                  </>
                ),
                link: (
                  <button className="guide-message-link-btn" onClick={onClickViewRepaymentHistory}>
                    {t('text:View_Repayment_History')}
                  </button>
                ),
              }}
            </GuideMessage>
          );
        }
        break;
      case LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_REPAID:
        if (isOperator) {
          const AuthorizerReturnedRepaidStatusOperatorGuideMessage = [
            t('text:The_repaid_status_request_has_been_reverted'),
            t('text:Check_the_reason_for_the_rejection_below'),
            t(
              'text:Cancel_the_repaid_status_request_before_the_Authorizers_approval_by_clicking_on_the_Cancel_Repaid_Status_Request_button_on_the_right',
            ),

            t(
              'text:If_there_are_any_repayment_transactions_before_the_financing_is_completely_repaid_click_on_the_Update_Repayment_Transaction_button_to_reflect_the_changes',
            ),
          ];

          return (
            <GuideMessage
              messageType={MessageType.ALERT}
              message={AuthorizerReturnedRepaidStatusOperatorGuideMessage}
              reasonTitle={t('text:REASON_FOR_REVERT')}
              reason={returnReason}
            >
              {{
                button: (
                  <>
                    <Button
                      variant={ButtonVariantEnum.OUTLINED}
                      color={ButtonColorEnum.SECONDARY}
                      onClick={onClickCancelRepayment}
                    >
                      {t('text:Cancel_Repaid_Status_Request')}
                    </Button>

                    <Button onClick={onClickRenewLoanTransactions}>{t('text:Update_Repayment_Transaction')}</Button>
                  </>
                ),
                link: (
                  <button className="guide-message-link-btn" onClick={onClickViewRepaymentHistory}>
                    {t('text:View_Repayment_History')}
                  </button>
                ),
              }}
            </GuideMessage>
          );
        } else if (isAuthorizer) {
          const AuthorizerReturnedRepaidStatusAuthorizerGuideMessage = [
            t('text:The_repaid_status_request_has_been_reverted'),
            t('text:Check_the_reason_for_the_revert_below'),
            t(
              'text:If_there_are_any_repayment_transactions_before_the_financing_is_completely_repaid_click_on_the_Update_Repayment_Transaction_button_to_reflect_the_changes',
            ),
          ];

          return (
            <GuideMessage
              messageType={MessageType.ALERT}
              message={AuthorizerReturnedRepaidStatusAuthorizerGuideMessage}
              reasonTitle={t('text:REASON_FOR_REVERT')}
              reason={returnReason}
            >
              {{
                button: (
                  <Button onClick={onClickRenewLoanTransactions}>{t('text:Update_Repayment_Transaction')}</Button>
                ),
                link: (
                  <button className="guide-message-link-btn" onClick={onClickViewRepaymentHistory}>
                    {t('text:View_Repayment_History')}
                  </button>
                ),
              }}
            </GuideMessage>
          );
        }
        break;
      case LOAN_APPROVAL_TYPE.OPERATOR_OVERDUE:
        if (isOperator) {
          const operatorOverdueStatusOperatorGuideMessage = [
            t('text:The_request_to_change_the_financing_status_to_Delinquent_has_been_submitted_to_the_Authorizer'),
            t('text:The_financing_status_will_change_when_the_Authorizer_approves_the_request'),
            t(
              'text:If_there_are_any_repayment_transactions_before_the_financing_is_completely_repaid_click_on_the_Update_Repayment_Transaction_button_to_reflect_the_changes',
            ),
          ];

          return (
            <GuideMessage message={operatorOverdueStatusOperatorGuideMessage}>
              {{
                button: (
                  <>
                    <Button
                      variant={ButtonVariantEnum.OUTLINED}
                      color={ButtonColorEnum.SECONDARY}
                      onClick={onClickCancelOverdue}
                    >
                      {t('text:Cancel_Delinquent_Status_Request')}
                    </Button>

                    <Button onClick={onClickRenewLoanTransactions}>{t('text:Update_Repayment_Transaction')}</Button>
                  </>
                ),
              }}
            </GuideMessage>
          );
        } else if (isAuthorizer) {
          const operatorOverdueStatusAuthorizerGuideMessage = [
            t('text:The_delinquent_status_has_been_requested'),
            t(
              'text:Check_the_financing_information_and_approve_the_request_by_clicking_on_the_Confirm_Delinquent_Status_button',
            ),
            t(
              'text:If_the_financing_is_not_delinquent_click_on_the_Revert_Delinquent_Status_Request_button_and_revert_the_request',
            ),

            t(
              'text:If_there_are_any_repayment_transactions_before_the_financing_is_completely_repaid_click_on_the_Update_Repayment_Transaction_button_to_reflect_the_changes',
            ),
          ];

          return (
            <GuideMessage messageType={MessageType.ALERT} message={operatorOverdueStatusAuthorizerGuideMessage}>
              {{
                button: (
                  <>
                    <Button onClick={onClickConfirmDelinquentStatus}>{t('text:Confirm_Delinquent_Status')}</Button>
                    <Button
                      variant={ButtonVariantEnum.OUTLINED}
                      color={ButtonColorEnum.SECONDARY}
                      onClick={onClickRevertDelinquentStatusRequest}
                    >
                      {t('text:Revert_Delinquent_Status_Request')}
                    </Button>

                    <Button onClick={onClickRenewLoanTransactions}>{t('text:Update_Repayment_Transaction')}</Button>
                  </>
                ),
              }}
            </GuideMessage>
          );
        }
        break;
      case LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_OVERDUE:
        const authorizerReturnedOverdueStatusOperatorGuideMessage = [
          t('text:The_delinquent_status_request_has_been_reverted'),
          t('text:Check_the_reason_for_the_revert_below'),
          t(
            'text:If_there_are_any_repayment_transactions_before_the_financing_is_completely_repaid_click_on_the_Update_Repayment_Transaction_button_to_reflect_the_changes',
          ),
        ];
        // 은행 OPERATOR 대출 연체 요청 반려
        if (isOperator) {
          return (
            <GuideMessage
              messageType={MessageType.ALERT}
              message={authorizerReturnedOverdueStatusOperatorGuideMessage}
              reasonTitle={t('text:REASON_FOR_REVERT')}
              reason={returnReason}
            >
              {{
                button: (
                  <>
                    <Button
                      variant={ButtonVariantEnum.OUTLINED}
                      color={ButtonColorEnum.SECONDARY}
                      onClick={onClickCancelOverdue}
                    >
                      {t('text:Cancel_Delinquent_Status_Request')}
                    </Button>

                    <Button onClick={onClickRenewLoanTransactions}>{t('text:Update_Repayment_Transaction')}</Button>
                  </>
                ),
              }}
            </GuideMessage>
          );
        } else if (isAuthorizer) {
          const authorizerReturnedOverdueStatusAuthorizerGuideMessage = [
            t('text:The_delinquent_status_request_has_been_reverted'),
            t('text:Check_the_reason_for_the_revert_below'),

            t(
              'text:If_there_are_any_repayment_transactions_before_the_financing_is_completely_repaid_click_on_the_Update_Repayment_Transaction_button_to_reflect_the_changes',
            ),
          ];

          return (
            <GuideMessage
              messageType={MessageType.ALERT}
              message={authorizerReturnedOverdueStatusAuthorizerGuideMessage}
              reasonTitle={t('text:REASON_FOR_REVERT')}
              reason={returnReason}
            >
              {{
                button: (
                  <Button onClick={onClickRenewLoanTransactions}>{t('text:Update_Repayment_Transaction')}</Button>
                ),
              }}
            </GuideMessage>
          );
        }
        break;
      case LOAN_APPROVAL_TYPE.OPERATOR_REQUEST_CANCELLATION_DISBURSEMENT:
        if (isOperator) {
          return (
            <GuideMessage
              messageType={MessageType.ALERT}
              message={[
                t('text:The_financing_disbursement_cancellation_has_been_requested'),
                t('text:Click_on_the_Cancel_Disbursement_Cancellation_Request_button_on_the_right'),
              ]}
              reasonTitle={t('text:REASON_FOR_CANCELLATION')}
              reason={cancelDisbursementReason}
            >
              {{
                button: (
                  <Button
                    onClick={onClickCancelDisbursementCancellationRequest}
                    variant={ButtonVariantEnum.OUTLINED}
                    color={ButtonColorEnum.SECONDARY}
                  >
                    {t('text:Cancel_Disbursement_Cancellation_Request')}
                  </Button>
                ),
              }}
            </GuideMessage>
          );
        } else if (isAuthorizer) {
          return (
            <GuideMessage
              messageType={MessageType.ALERT}
              message={[
                t('text:The_financing_disbursement_cancellation_has_been_requested'),
                t(
                  'text:Click_on_the_buttons_to_the_right_to_confirm_or_revert_the_financing_disbursement_cancellation_request',
                ),
              ]}
              reasonTitle={t('text:REASON_FOR_CANCELLATION')}
              reason={cancelDisbursementReason}
            >
              {{
                button: (
                  <>
                    <Button onClick={onClickApproveDisbursementCancellation}>
                      {t('text:Confirm_Disbursement_Cancellation_Request')}
                    </Button>
                    <Button
                      onClick={onClickRevertDisbursementCancellation}
                      variant={ButtonVariantEnum.OUTLINED}
                      color={ButtonColorEnum.SECONDARY}
                    >
                      {t('text:Revert_Disbursement_Cancellation_Request')}
                    </Button>
                  </>
                ),
              }}
            </GuideMessage>
          );
        } else if (isAdmin) {
          return (
            <GuideMessage
              messageType={MessageType.ALERT}
              message={[t('text:The_financing_disbursement_cancellation_has_been_requested')]}
              reasonTitle={t('text:REASON_FOR_CANCELLATION')}
              reason={cancelDisbursementReason}
            />
          );
        }
        break;
      case LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_CANCELLATION_DISBURSEMENT:
        if (isOperator) {
          return (
            <GuideMessage
              messageType={MessageType.ALERT}
              message={[
                t('text:The_financing_disbursement_cancellation_has_been_reverted'),
                t('text:Check_the_reason_for_the_revert_below'),
                t(
                  'text:Cancel_the_disbursement_cancellation_request_before_the_Authorizers_approval_by_clicking_on_the_Cancel_Disbursement_Cancellation_Request_button_on_the_right',
                ),
              ]}
              reasonTitle={t('text:REASON_FOR_REVERT')}
              reason={returnReason}
            >
              {{
                button: (
                  <>
                    <Button
                      onClick={onClickCancelDisbursementCancellationRequest}
                      variant={ButtonVariantEnum.OUTLINED}
                      color={ButtonColorEnum.SECONDARY}
                    >
                      {t('text:Cancel_Disbursement_Cancellation_Request')}
                    </Button>
                    <Button onClick={onClickRenewLoanTransactions}>{t('text:Update_Repayment_Transaction')}</Button>
                  </>
                ),
              }}
            </GuideMessage>
          );
        } else if (isAuthorizer) {
          return (
            <GuideMessage
              messageType={MessageType.ALERT}
              message={[
                t('text:The_financing_disbursement_cancellation_has_been_reverted'),
                t('text:Check_the_reason_for_the_revert_below'),
              ]}
              reasonTitle={t('text:REASON_FOR_REVERT')}
              reason={returnReason}
            >
              {{
                button: (
                  <Button onClick={onClickRenewLoanTransactions}>{t('text:Update_Repayment_Transaction')}</Button>
                ),
              }}
            </GuideMessage>
          );
        } else if (isAdmin) {
          return (
            <GuideMessage
              messageType={MessageType.ALERT}
              message={[
                t('text:The_financing_disbursement_cancellation_has_been_reverted'),
                t('text:Check_the_reason_for_the_revert_below'),
              ]}
              reasonTitle={t('text:REASON_FOR_REVERT')}
              reason={returnReason}
            />
          );
        }
        break;
      default:
        if (isOperator) {
          const noneLoanApprovalTypeOperatorGuideMessage = [
            t('text:The_financing_has_been_disbursed'),

            t(
              'text:If_there_are_any_repayment_transactions_before_the_financing_is_completely_repaid_click_on_the_Update_Repayment_Transaction_button_to_reflect_the_changes',
            ),
            t(
              'text:If_the_repayment_has_been_completed_click_on_the_Repaid_Status_Request_button_and_enter_the_repayment_information',
            ),
            t(
              'text:If_the_repayment_is_delinquent_click_on_the_Delinquent_Status_Request_button_and_request_the_Authorizers_approval_to_update_the_financing_status_to_Delinquent',
            ),
            t(
              'text:If_you_would_like_to_request_a_financing_disbursement_cancellation_click_the_Cancel_Financing_Disbursement_button',
            ),
          ];

          return (
            <GuideMessage message={noneLoanApprovalTypeOperatorGuideMessage}>
              {{
                button: (
                  <>
                    <Button onClick={onClickRenewLoanTransactions}>{t('text:Update_Repayment_Transaction')}</Button>
                    <Button onClick={onClickRequestFinancierLoanRepayment}>{t('text:Repaid_Status_Request')}</Button>
                    <Button
                      variant={ButtonVariantEnum.OUTLINED}
                      color={ButtonColorEnum.SECONDARY}
                      onClick={onClickRequestFinancierLoanOverdue}
                    >
                      {t('text:Delinquent_Status_Request')}
                    </Button>
                    <Button
                      variant={ButtonVariantEnum.OUTLINED}
                      color={ButtonColorEnum.SECONDARY}
                      onClick={onClickRequestCancelDisbursement}
                    >
                      {t('text:Cancel_Financing_Disbursement')}
                    </Button>
                  </>
                ),
              }}
            </GuideMessage>
          );
        } else if (isAuthorizer) {
          return (
            <GuideMessage
              message={[
                t(
                  'text:If_there_are_any_repayment_transactions_before_the_financing_is_completely_repaid_click_on_the_Update_Repayment_Transaction_button_to_reflect_the_changes',
                ),
              ]}
            >
              {{
                button: (
                  <Button onClick={onClickRenewLoanTransactions}>{t('text:Update_Repayment_Transaction')}</Button>
                ),
              }}
            </GuideMessage>
          );
        }
    }
  };

  return <>{renderApprovedLoanStatusMessage(loanApprovalType)}</>;
}

export default AnchorLoanDisbursedStatusGuideMessage;
