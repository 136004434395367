import { useTranslation } from 'react-i18next';

import Button from 'components/stateless/Button/Button';
import GuideMessage, { MessageType } from 'components/stateless/GuideMessage/GuideMessage';
import { AUTHORITY_TYPE, LOAN_APPROVAL_TYPE } from 'enums';
import type { SignInModel } from 'models/SignInModel';
import type { AnchorLoanDetailVOModel } from 'models/vo/AnchorLoanDetailVO';
import { getSignIn } from 'utils/storage/LocalStorage';

interface AnchorLoanApproveStatusGuideMessageProps {
  loanDetailData: AnchorLoanDetailVOModel;
  onClickExecuteLoanDisbursement(e: any): void;
}

function AnchorLoanApproveStatusGuideMessage({
  loanDetailData,
  onClickExecuteLoanDisbursement,
}: AnchorLoanApproveStatusGuideMessageProps) {
  const { t } = useTranslation();

  const signInModel: SignInModel | null = getSignIn();
  const { ADMIN } = AUTHORITY_TYPE;
  const { authorityType } = signInModel ?? {};
  const isAdmin = authorityType === ADMIN;

  const { loanApprovalType, returnReason, rejectReason } = loanDetailData;

  const renderApprovedLoanStatusMessage = (loanApprovalType: LOAN_APPROVAL_TYPE) => {
    switch (loanApprovalType) {
      case LOAN_APPROVAL_TYPE.OPERATOR_REQUEST_REJECTED:
        return (
          <GuideMessage
            messageType={MessageType.ALERT}
            message={[t('text:The_financing_rejection_has_been_requested')]}
            reasonTitle={t('text:REASON_FOR_REJECTION')}
            reason={rejectReason}
          />
        );

      case LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_REJECTED:
        return (
          <GuideMessage
            messageType={MessageType.ALERT}
            message={[
              t('text:The_financing_rejection_request_has_been_reverted'),
              t('text:Check_the_reason_for_the_revert_below'),
            ]}
            reasonTitle={t('text:REASON_FOR_REVERT')}
            reason={returnReason}
          />
        );

      default:
        if (isAdmin) {
          return <GuideMessage message={[t('text:The_financing_application_has_been_approved')]} />;
        } else
          return (
            <GuideMessage
              message={[
                t('text:The_financing_application_has_been_approved'),
                t(
                  'text:After_the_financing_has_been_disbursed_click_on_the_Disbursement_Confirmed_button_to_the_right_and_enter_the_disbursement_information',
                ),
              ]}
            >
              {{
                button: <Button onClick={onClickExecuteLoanDisbursement}>{t('text:Disbursement_Confirmed')}</Button>,
              }}
            </GuideMessage>
          );
    }
  };

  return <>{renderApprovedLoanStatusMessage(loanApprovalType)}</>;
}

export default AnchorLoanApproveStatusGuideMessage;
