import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import Button, { ButtonColorEnum, ButtonSizeEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import Exporter, { EXCEL_EXPORT_MAX_ROW_COUNT, PDF_EXPORT_MAX_ROW_COUNT } from 'components/stateless/Exporter/Exporter';
import StatusDescriptionModal from 'components/stateless/Modal/common/status/StatusDescriptionModal';
import Pagination from 'components/stateless/Pagination/Pagination';
import SearchBorder from 'components/stateless/SearchForm/SearchBorder';
import SearchDatePicker from 'components/stateless/SearchForm/SearchDatePicker';
import SearchEmpty from 'components/stateless/SearchForm/SearchEmpty';
import SearchInput from 'components/stateless/SearchForm/SearchInput';
import SearchLabel from 'components/stateless/SearchForm/SearchLabel';
import SearchSelect from 'components/stateless/SearchForm/SearchSelect';
import TableBody from 'components/stateless/Table/TableBody';
import TableBorder from 'components/stateless/Table/TableBorder';
import type { HeaderType } from 'components/stateless/Table/TableHeader';
import TableHeader from 'components/stateless/Table/TableHeader';
import Td from 'components/stateless/Table/Td';
import TdLink from 'components/stateless/Table/TdLink';
import Tr from 'components/stateless/Table/Tr';
import { HeaderTitle } from 'components/stateless/Title/HeaderTitle';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import { ROUTES_FI } from 'constants/routes/financier';
import getSelectOptions from 'constants/selectOptions';
import type { COLLATERAL_TYPE, CURRENCY_TYPE } from 'enums';
import { LOAN_APPROVAL_TYPE, LOAN_STATUS } from 'enums';
import useMounted from 'hooks/useMounted';
import usePageable from 'hooks/usePageable';
import type Pageable from 'models/Pageable';
import type { AnchorLoanVOModel } from 'models/vo/AnchorLoanVO';
import getStatusTextClass from 'utils/classNames/getStatusTextClass';
import type { PDFExporterProps } from 'utils/exportFile/exportPDF.d';
import { setFormValues } from 'utils/form/setFormValues';
import { requestFinancierAnchorLoanList } from 'utils/http/api/financier/anchor-loans';
import type { FinancierAnchorLoanListRequest } from 'utils/http/api/financier/anchor-loans/request';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { getParsedSearchParams, updateSearchParams } from 'utils/searchParams';
import type { ColumnOption, ExportSpreadSheetProps } from 'utils/spreadSheet/types';
import { getFilteredDisbursedAmount, tableValueManage } from 'utils/valueManager/ValueManager';

const FI_ANCHOR_FINANCING_LIST_QS_KEY = 'anchor-financing-list';

function FinancierAnchorFinancingList() {
  const { t } = useTranslation(['format']);
  const location = useLocation<FinancierAnchorLoanListRequest>();
  const filteredOptions = location.state;
  const mounted = useMounted();
  const { pageable, setPageable } = usePageable(FI_ANCHOR_FINANCING_LIST_QS_KEY);
  const [financingPage, setFinancingPage] = useState<Pageable<AnchorLoanVOModel[]>>();
  const modal = useModal();

  const LoanStatusCheckBoxOptions: LOAN_STATUS[] = [
    LOAN_STATUS.DEALER_CREATED,
    LOAN_STATUS.DEALER_REQUESTED,
    LOAN_STATUS.DEALER_CANCELLED,
    LOAN_STATUS.REJECTED,
    LOAN_STATUS.APPROVED,
    LOAN_STATUS.DISBURSED,
    LOAN_STATUS.REPAID,
    LOAN_STATUS.OVERDUE,
    LOAN_STATUS.EXPIRATION,
    LOAN_STATUS.RECOURSE_ACTIVATED,
  ];

  const LoanApprovalTypeCheckBoxOptions = [
    LOAN_APPROVAL_TYPE.OPERATOR_APPROVAL,
    LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_APPROVAL,
    LOAN_APPROVAL_TYPE.OPERATOR_REQUEST_REJECTED,
    LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_REJECTED,
    LOAN_APPROVAL_TYPE.OPERATOR_OVERDUE,
    LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_OVERDUE,
    LOAN_APPROVAL_TYPE.OPERATOR_REPAID,
    LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_REPAID,
    LOAN_APPROVAL_TYPE.OPERATOR_CLEAR_OVERDUE,
    LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_CLEAR_OVERDUE,
    LOAN_APPROVAL_TYPE.OPERATOR_EXPIRATION,
    LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_EXPIRATION,
    LOAN_APPROVAL_TYPE.OPERATOR_REQUEST_CANCELLATION_DISBURSEMENT,
    LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_CANCELLATION_DISBURSEMENT,
  ];

  const NUM_OF_CHECKBOX_LOAN_APPROVAL_TYPE = LoanApprovalTypeCheckBoxOptions.length;
  const NUM_OF_CHECKBOX = LoanStatusCheckBoxOptions.length;

  const { register, reset, getValues, watch, setValue, control } = useForm<FinancierAnchorLoanListRequest>();

  const { loanStatusConditions: watchCheckbox, loanApprovalConditions: LoanApprovalTypeWatchCheckbox } = watch();

  useEffect(() => {
    if (mounted) {
      setFormValues<FinancierAnchorLoanListRequest>(
        setValue,
        filteredOptions ? filteredOptions : getParsedSearchParams(FI_ANCHOR_FINANCING_LIST_QS_KEY).formSearchData,
      );
      fetchAcLoanList(pageable.currentPage, pageable.sizePerPage, filteredOptions ? filteredOptions : getValues());
    }

    return () => {};
  }, [mounted]);

  const onSearchSubmit = () => {
    const data = getValues();
    fetchAcLoanList(1, pageable.sizePerPage, data);
  };

  async function fetchAcLoanList(pageNumber: number = 1, rowCount: number = 10, data: FinancierAnchorLoanListRequest) {
    try {
      const financingPage = await requestFinancierAnchorLoanList(pageNumber, rowCount, data);
      updateSearchParams(
        {
          ...data,
          pageNumber,
          rowCount,
        },
        FI_ANCHOR_FINANCING_LIST_QS_KEY,
      );

      setFinancingPage(financingPage);
      setPageable(financingPage);
    } catch (error) {
      modal.show(error);
    }
  }

  const paginate = async (page: number, sizePerPage: number) => {
    await fetchAcLoanList(page, sizePerPage, getParsedSearchParams(FI_ANCHOR_FINANCING_LIST_QS_KEY).formSearchData);
  };

  const handleCheckAll = (e: any) => {
    if (e.target.checked) {
      setValue('loanStatusConditions', LoanStatusCheckBoxOptions);
    } else {
      setValue('loanStatusConditions', []);
    }
  };

  const handleCheckAllLoanApprovalType = (e: any) => {
    if (e.target.checked) setValue('loanApprovalConditions', LoanApprovalTypeCheckBoxOptions);
    else setValue('loanApprovalConditions', []);
  };

  const onRemoveFilter = (event: any) => {
    reset({});
    event.preventDefault();
  };

  const onClickExportButton = async (e: any) => {
    e.preventDefault();
    const excelColumns: ColumnOption<AnchorLoanVOModel>[] = [
      {
        header: t('text:Program_Type'),
        key: 'collateralType',
      },
      {
        header: t('text:Platform_Financing_ID'),
        key: 'anchorLoanId',
      },
      {
        header: t('text:Financier_Financing_ID'),
        key: 'financierLoanId',
      },
      {
        header: t('text:Anchor_Name'),
        key: 'anchorClientName',
      },
      {
        header: t('text:Invoice_Number'),
        key: 'invoiceNumber',
      },
      {
        header: t('text:AR_Number'),
        key: 'arNumber',
      },
      {
        header: t('text:Currency'),
        key: 'currencyType',
      },
      {
        header: t('text:Associated_Partner_Name'),
        key: 'anchorPartnerName',
      },
      {
        header: t('text:Disbursement_Amount'),
        key: 'disbursedAmount',
        style: {
          alignment: { horizontal: 'right' },
        },
      },
      {
        header: t('text:Advance_Disbursement'),
        key: 'netDisbursementAmount',
        style: {
          alignment: { horizontal: 'right' },
        },
      },
      {
        header: t('text:Applied_Date'),
        key: 'requestedDateTime',
      },
      {
        header: t('text:Disbursed_Date'),
        key: 'disbursedDate',
      },
      {
        header: t('text:Scheduled_Repayment_Date'),
        key: 'repaymentDate',
      },
      {
        header: t('text:Repaid_Date'),
        key: 'repaidDate',
      },
      {
        header: t('text:Outstanding_Balance'),
        key: 'outstandingBalance',
      },
      {
        header: t('text:Financing_Status'),
        key: 'loanStatus',
      },
      {
        header: t('text:Approval_Status'),
        key: 'loanApprovalType',
      },
    ];

    try {
      const searchData = getParsedSearchParams(FI_ANCHOR_FINANCING_LIST_QS_KEY).formSearchData;
      const acFinancingPage = await requestFinancierAnchorLoanList(0, EXCEL_EXPORT_MAX_ROW_COUNT, searchData);
      const acFinancingPDFPage = acFinancingPage.content.slice(0, PDF_EXPORT_MAX_ROW_COUNT);

      const jsonArrayData: any[] = acFinancingPage.content.map(loanData => ({
        collateralType: tableValueManage(t(`code:collateral-type.${loanData.collateralType}`)),
        anchorLoanId: tableValueManage(loanData.anchorLoanId),
        financierLoanId: tableValueManage(loanData.financierLoanId),
        anchorClientName: tableValueManage(loanData.anchorClientName),
        invoiceNumber: tableValueManage(loanData.invoiceNumber),
        arNumber: tableValueManage(loanData.arNumber),
        currencyType: tableValueManage(loanData.currencyType),
        dealerName: tableValueManage(loanData.anchorPartnerName),
        disbursedAmount: tableValueManage(getFilteredDisbursedAmount(loanData.loanStatus, loanData.disbursedAmount)),
        netDisbursementAmount: tableValueManage(loanData.netDisbursementAmount),
        requestedDateTime: t('format:original-date', {
          value: loanData.requestedDateTime,
          key: 'original-date',
        }),

        disbursedDate: t('format:original-date', {
          value: loanData.disbursedDate,
          key: 'original-date',
        }),
        repaymentDate: t('format:original-date', {
          value: loanData.repaymentDate,
          key: 'original-date',
        }),
        outstandingBalance: tableValueManage(parseFloat(loanData.outstandingBalance)),
        repaidDate: t('format:original-date', {
          value: loanData.repaidDate,
          key: 'original-date',
        }),
        loanStatus: tableValueManage(loanData.loanStatus, t(`code:financing-status.${loanData.loanStatus}`)),
        loanApprovalType: tableValueManage(
          loanData.loanApprovalType,
          t(`code:approval-status.${loanData.loanApprovalType}`),
        ),
      }));

      const excelExporterProps: ExportSpreadSheetProps<any> = {
        jsonArrayData: jsonArrayData,
        columns: excelColumns,
        options: {
          rowHeight: [{ position: 1, height: 30 }],
        },
      };

      const pdfExporterProps: PDFExporterProps = {
        mergedTableHeaders: [],
        tableHeaders: tableHeaders,
        tableBody: renderResultTable(acFinancingPDFPage, true),
      };

      modal.show(<Exporter spreadSheetExporterProps={excelExporterProps} pdfExporterProps={pdfExporterProps} />, {
        title: t('text:Export_File'),
        closeBtnText: t('text:Close'),
      });
    } catch (e) {
      modal.show(e);
    }
  };

  const onClickFinancingStatus = () => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={Object.values(LOAN_STATUS)}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="ANCHOR_LOAN_STATUS"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const tableHeaders: HeaderType[] = [
    {
      headerText: t('text:Program_Type'),
      colWidths: 100,
    },
    {
      headerText: t('text:Platform_Financing_ID'),
      colWidths: 100,
    },
    {
      headerText: t('text:Financier_Financing_ID'),
      colWidths: 100,
    },
    {
      headerText: t('text:Anchor_Name'),
      colWidths: 100,
    },
    {
      headerText: t('text:Invoice_Number'),
      colWidths: 100,
    },
    {
      headerText: t('text:AP_Number'),
      colWidths: 100,
    },
    {
      headerText: t('text:Currency'),
      colWidths: 100,
    },
    {
      headerText: t('text:Associated_Partner_Name'),
      colWidths: 140,
    },
    {
      headerText: t('text:Associated_Supplier_Name'),
      colWidths: 140,
    },
    {
      headerText: t('text:Disbursement_Amount'),
      colWidths: 120,
    },
    {
      headerText: t('text:Advance_Disbursement'),
      colWidths: 120,
    },
    {
      headerText: t('text:Applied_Date'),
      colWidths: 100,
    },
    {
      headerText: t('text:Disbursed_Date'),
      colWidths: 100,
    },
    {
      headerText: t('text:Scheduled_Repayment_Date'),
      colWidths: 100,
    },
    {
      headerText: t('text:Repaid_Date'),
      colWidths: 100,
    },
    {
      headerText: t('text:Outstanding_Balance'),
      colWidths: 120,
    },
    {
      headerText: t('text:Financing_Status'),
      colWidths: 100,
      hasStatusDescription: true,
      showStatusDescriptionFunc: onClickFinancingStatus,
    },
    {
      headerText: t('text:Approval_Status'),
      colWidths: 140,
    },
    {
      headerText: '',
      colWidths: 80,
    },
  ];

  const onClickFinancingApprovalStatus = () => {
    modal.show(
      <StatusDescriptionModal
        statusDescriptionEnum={[
          LOAN_APPROVAL_TYPE.OPERATOR_APPROVAL,
          LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_APPROVAL,
          LOAN_APPROVAL_TYPE.OPERATOR_REQUEST_REJECTED,
          LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_REJECTED,
          LOAN_APPROVAL_TYPE.OPERATOR_REPAID,
          LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_REPAID,
          LOAN_APPROVAL_TYPE.OPERATOR_OVERDUE,
          LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_OVERDUE,
          LOAN_APPROVAL_TYPE.OPERATOR_EXPIRATION,
          LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_EXPIRATION,
          LOAN_APPROVAL_TYPE.OPERATOR_CLEAR_OVERDUE,
          LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_CLEAR_OVERDUE,
          LOAN_APPROVAL_TYPE.OPERATOR_REQUEST_CANCELLATION_DISBURSEMENT,
          LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_CANCELLATION_DISBURSEMENT,
        ]}
        statusDescriptionModalType="TEXT"
        statusDescriptionEnumType="LOAN_APPROVAL_TYPE"
      />,
      {
        modalSize: ModalSize.XL,
        modalType: ModalType.ALERT,
        closeBtnText: t('text:Close'),
      },
    );
  };

  const renderResultTable = (data: AnchorLoanVOModel[] | undefined, isPdf = false) => {
    return data?.map((item, i) => {
      return (
        <Tr key={i} className={isPdf ? 'virtual-table-row' : ''}>
          <Td data={t(`code:collateral-type.${item.collateralType}`)} format="code" />
          <Td data={item.anchorLoanId} />
          <Td data={item.financierLoanId} />
          <Td data={item.anchorClientName} />
          <Td data={item.invoiceNumber} />
          <Td data={item.arNumber} />
          <Td data={item.currencyType} />
          <Td data={item.anchorPartnerName} />
          <Td data={item.dealerClientName} />
          <Td data={getFilteredDisbursedAmount(item.loanStatus, item.disbursedAmount)} format="number" />
          <Td data={item.netDisbursementAmount} format="number" />
          <Td data={item.requestedDateTime} format="date" />
          <Td data={item.disbursedDate} format="date" />
          <Td>
            {t('format:date', {
              value: item.repaymentDate,
              key: 'date',
            })}
          </Td>
          <Td data={item.repaidDate} format="date" />
          <Td data={item.outstandingBalance} format="number" />
          <Td
            className={getStatusTextClass('LOAN_STATUS', item.loanStatus)}
            data={tableValueManage(item.loanStatus, t(`code:financing-status.${item.loanStatus}`))}
            format="code"
          />
          <Td
            data={t(`code:approval-status.${item.loanApprovalType}`)}
            className={getStatusTextClass('LOAN_APPROVAL_TYPE', item.loanApprovalType)}
            format="code"
          />
          <TdLink path={ROUTES_FI.MANAGE_ANCHOR_FINANCING.DETAIL_BUILD_PATH(item.anchorLoanId)} />
        </Tr>
      );
    });
  };

  return (
    <>
      <HeaderTitle title={t('text:Financing')} />
      <div className="content-area">
        <form>
          <SectionTitle title={t('text:Search')}>
            <Button variant={ButtonVariantEnum.OUTLINED} color={ButtonColorEnum.SECONDARY} onClick={onRemoveFilter}>
              {t('text:Remove_Filter')}
            </Button>
          </SectionTitle>
          <SearchBorder>
            <div className="row">
              <SearchLabel label={t('text:Platform_Financing_ID')} />
              <SearchInput name="anchorLoanId" ref={register} />
              <SearchLabel label={t('text:Financier_Financing_ID')} />
              <SearchInput name="financierLoanId" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Invoice_Number')} />
              <SearchInput name="invoiceNumber" ref={register} />
              <SearchLabel label={t('text:AP_Number')} />
              <SearchInput name="arNumber" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Anchor_Name')} />
              <SearchInput name="anchorClientName" ref={register} />
              <SearchLabel label={t('text:Associated_Partner_Name')} />
              <SearchInput name="anchorPartnerName" ref={register} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Associated_Supplier_Name')} />
              <SearchInput name="dealerClientName" ref={register} />
              <SearchLabel label={t('text:Currency')} />
              <SearchSelect
                selectOptions={getSelectOptions<CURRENCY_TYPE>('CURRENCY_TYPE', 'ALL', true)}
                name="currencyType"
                ref={register}
              />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Applied_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="requestedDateFrom" control={control} />
              <SearchDatePicker placeholder={t('text:to')} name="requestedDateTo" control={control} />
              <SearchLabel label={t('text:Disbursed_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="disbursedDateFrom" control={control} />
              <SearchDatePicker placeholder={t('text:to')} name="disbursedDateTo" control={control} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Scheduled_Repayment_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="repaymentDateFrom" control={control} />
              <SearchDatePicker placeholder={t('text:to')} name="repaymentDateTo" control={control} />
              <SearchLabel label={t('text:Repaid_Date')} />
              <SearchDatePicker placeholder={t('text:from')} name="repaidDateFrom" control={control} />
              <SearchDatePicker placeholder={t('text:to')} name="repaidDateTo" control={control} />
            </div>
            <div className="row">
              <SearchLabel label={t('text:Program_Type')} />
              <SearchSelect
                name="collateralType"
                selectOptions={getSelectOptions<COLLATERAL_TYPE>('COLLATERAL_TYPE', 'ALL', true)}
                ref={register}
              />
              <SearchEmpty />
            </div>
            <div className="row">
              <SearchLabel
                className="search-form__label-wrap auto-height col-2"
                label={t('text:Financing_Status')}
                hasStatusDescription={true}
                showStatusDescriptionFunc={onClickFinancingStatus}
              />
              <div className="search-form__item__input auto-height col-10">
                <div className="checkbox-form">
                  <div className="form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="allCheck"
                      onChange={handleCheckAll}
                      checked={watchCheckbox?.length === NUM_OF_CHECKBOX}
                    />
                    <label className="form-check-label" htmlFor="allCheck">
                      {t('text:All')}
                    </label>
                  </div>
                  {LoanStatusCheckBoxOptions?.map((checkBoxValue, index) => {
                    return (
                      <div className="form-check-inline" key={index}>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={checkBoxValue}
                          id={`flexCheckDefault${index}`}
                          name="loanStatusConditions"
                          ref={register}
                        />
                        <label className="form-check-label" htmlFor={`flexCheckDefault${index}`}>
                          {t(`code:financing-status.${LoanStatusCheckBoxOptions[index]}`)}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="row">
              <SearchLabel
                className="search-form__label-wrap auto-height col-2"
                label={t('text:Approval_Status')}
                hasStatusDescription={true}
                showStatusDescriptionFunc={onClickFinancingApprovalStatus}
              />
              <div className="search-form__item__input col-10">
                <div className="checkbox-form">
                  <div className="form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="allCheck2"
                      onChange={handleCheckAllLoanApprovalType}
                      checked={LoanApprovalTypeWatchCheckbox?.length === NUM_OF_CHECKBOX_LOAN_APPROVAL_TYPE}
                    />
                    <label className="form-check-label" htmlFor="allCheck2">
                      {t('text:All')}
                    </label>
                  </div>
                  {LoanApprovalTypeCheckBoxOptions?.map((checkBoxValue, index) => {
                    return (
                      <div className="form-check-inline" key={index}>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={checkBoxValue}
                          id={`flexCheckDefault${index + NUM_OF_CHECKBOX_LOAN_APPROVAL_TYPE}`}
                          name="loanApprovalConditions"
                          ref={register}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`flexCheckDefault${index + NUM_OF_CHECKBOX_LOAN_APPROVAL_TYPE}`}
                        >
                          {t(`code:approval-status.${LoanApprovalTypeCheckBoxOptions[index]}`)}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </SearchBorder>
          <div className="flex-center mt-4">
            <Button size={ButtonSizeEnum.LG} onClick={onSearchSubmit}>
              {t('text:Search')}
            </Button>
          </div>
        </form>
      </div>
      <div className="division-border" />
      <div className="content-area">
        <SectionTitle title={t('text:Result')} />
        <div className="d-flex mb-2">
          <p className="total-data me-auto">
            {t('text:Total')} {financingPage?.totalElements}
          </p>
          <Button onClick={onClickExportButton} variant={ButtonVariantEnum.OUTLINED}>
            {t('text:Export')}
          </Button>
        </div>
        <TableBorder>
          <TableHeader header={tableHeaders} />
          <TableBody numOfCol={tableHeaders.length}>{renderResultTable(financingPage?.content)}</TableBody>
        </TableBorder>
        <Pagination pageable={pageable} paginate={paginate} />
      </div>
    </>
  );
}

export default FinancierAnchorFinancingList;
