import { useTranslation } from 'react-i18next';

import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import { BackGroundType } from 'components/stateless/CommonForm/FormSubtitle';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import { SectionTitle } from 'components/stateless/Title/SectionTitle';
import type { SuccessInvoiceDetailVOModel } from 'models/vo/SuccessInvoiceDetailVO';

interface DealerFinancingDetailInvoiceInformationProps {
  invoiceData: SuccessInvoiceDetailVOModel;
}

function FinancierAnchorFinancingDetailInvoiceInformation({
  invoiceData,
}: DealerFinancingDetailInvoiceInformationProps) {
  const { t } = useTranslation(['format']);

  return (
    <div className="content-area">
      <SectionTitle title={t('text:Invoice_Information')} />
      <FormBorder>
        <FormContents backGroundType={BackGroundType.WHITE}>
          <div className="row">
            <FormValue
              col={3}
              className="information-form__input border-none bold-font"
              label={t('text:Invoice_Number')}
              subLabel={`(${t('text:Invoice_Reference_Number')})`}
              value={invoiceData.invoiceNumber}
              subValue={invoiceData.referenceNumber}
            />
            <FormValue
              col={3}
              className="information-form__input border-none bold-font"
              label={t('text:Invoice_Amount')}
              value={invoiceData.invoiceAmount}
              format="number"
            />
            <FormValue
              col={3}
              className="information-form__input border-none bold-font"
              label={t('text:Invoice_Issued_Date')}
              value={invoiceData.invoiceIssuedDate}
              format="date"
            />
            <FormValue
              col={3}
              className="information-form__input border-none bold-font"
              label={t('text:Payment_Date')}
              value={invoiceData.settlementDate}
              format="date"
            />
          </div>
          <div className="row">
            <FormValue col={3} label={t('text:Anchor_Name')} value={invoiceData.anchorClientName} />
            <FormValue col={3} label={t('text:Partner_Name')} value={invoiceData.dealerClientName} />
            <FormValue col={3} label={t('text:Currency')} value={invoiceData.currencyType} />
          </div>
        </FormContents>
      </FormBorder>
    </div>
  );
}

export default FinancierAnchorFinancingDetailInvoiceInformation;
