import { useTranslation } from 'react-i18next';

import GuideMessage, { MessageType } from 'components/stateless/GuideMessage/GuideMessage';
import { LOAN_APPROVAL_TYPE } from 'enums';
import type { AnchorLoanDetailVOModel } from 'models/vo/AnchorLoanDetailVO';

interface AnchorLoanAppliedStatusGuideMessageProps {
  loanDetailData: AnchorLoanDetailVOModel;
}

function AnchorLoanAppliedStatusGuideMessage({ loanDetailData }: AnchorLoanAppliedStatusGuideMessageProps) {
  const { t } = useTranslation();

  const { loanApprovalType, returnReason } = loanDetailData;

  const renderApprovedLoanStatusMessage = (loanApprovalType: LOAN_APPROVAL_TYPE) => {
    if (loanApprovalType === LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_APPROVAL) {
      return (
        <GuideMessage
          messageType={MessageType.ALERT}
          message={[
            t('text:The_financing_approval_request_has_been_reverted'),
            t('text:Check_the_reason_for_the_revert_below'),
          ]}
          reasonTitle={t('text:REASON_FOR_REVERT')}
          reason={returnReason}
        />
      );
    } else {
      return (
        <GuideMessage
          message={[t('text:Check_the_financing_request_information_below_and_proceed_with_confirmation')]}
        />
      );
    }
  };

  return <>{renderApprovedLoanStatusMessage(loanApprovalType)}</>;
}

export default AnchorLoanAppliedStatusGuideMessage;
