import type { MutableRefObject } from 'react';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import dayjs from 'dayjs';

import ReasonModal from 'components/stateless/Modal/common/ReasonModal';
import ExpectedFinancingRepaymentModal from 'components/stateless/Modal/common/loan/ExpectedFinancingRepaymentModal';
import FinancierLoanTransactionRenewModal from 'components/stateless/Modal/financier/FinancierLoanTransactionRenewModal';
import { COLLATERAL_TYPE } from 'enums';
import usePageable from 'hooks/usePageable';
import type { AnchorLoanDetailVOModel } from 'models/vo/AnchorLoanDetailVO';
import type { LoanTransactionVOModel } from 'models/vo/LoanTransactionVO';
import type { SuccessArDetailVOModel } from 'models/vo/SuccessArDetailVO';
import type { SuccessInvoiceDetailVOModel } from 'models/vo/SuccessInvoiceDetailVO';
import { convertToServerDateFormat, getDayTerm } from 'utils/date/date';
import {
  requestFinancierAnchorLoanCalculateExpectedAmount,
  requestFinancierAnchorLoanDetail,
  requestFinancierAnchorLoanExpiration,
  requestFinancierAnchorLoanOverdue,
  requestFinancierAnchorLoanOverdueRelease,
  requestFinancierAnchorLoanRepayment,
  requestFinancierAnchorLoanTransaction,
  requestFinancierAnchorLoanTransactionDelete,
  requestFinancierAnchorLoanTransactionRenew,
  requestFinancierApproveAnchorLoanExpiration,
  requestFinancierApproveAnchorLoanOverdue,
  requestFinancierApproveAnchorLoanOverdueRelease,
  requestFinancierApproveAnchorLoanRepayment,
  requestFinancierApproveCancelAnchorLoanDisbursement,
  requestFinancierCancelAnchorLoan,
  requestFinancierExecuteAnchorLoanDisbursement,
  requestFinancierRequestCancelAnchorLoanDisbursement,
  requestFinancierReturnAnchorLoan,
} from 'utils/http/api/financier/anchor-loans';
import type {
  FinancierAnchorLoanExpirationRequest,
  FinancierAnchorLoanRepaymentRequest,
  FinancierAnchorLoanTransactionRequest,
  FinancierExecuteAnchorLoanDisbursementRequest,
} from 'utils/http/api/financier/anchor-loans/request';
import { requestFinancierCalendarList } from 'utils/http/api/financier/financier-calendar';
import { requestFinancierSuccessArsDetail } from 'utils/http/api/financier/success-ars';
import { requestFinancierSuccessInvoice } from 'utils/http/api/financier/success-invoices';
import type { ModalOptions } from 'utils/modal/ModalWrapper';
import { ModalSize, ModalType } from 'utils/modal/ModalWrapper';
import useModal from 'utils/modal/useModal';
import { scrollToTopOfElement } from 'utils/scroll';

import FinancierAnchorFinancingDetailExecuteLoanDisbursementModal from './modals/FinancierAnchorFinancingDetailExecuteLoanDisbursementModal';
import FinancierAnchorFinancingDetailRequestRepaymentModal from './modals/FinancierAnchorFinancingDetailRequestRepaymentModal';
import FinancierExpiredStatusRequestModal from '../../../../../components/stateless/Modal/financier/FinancierExpiredStatusRequestModal';
import FinancierViewRepaymentHistoryModal from '../../../../../components/stateless/Modal/financier/FinancierViewRepaymentHistoryModal';

type LoanRequestType = 'repayment' | 'expiredStatus' | 'executeDisbursement' | 'transaction';

type RequestRefTypes = {
  repayment: FinancierAnchorLoanRepaymentRequest;
  expiredStatus: FinancierAnchorLoanExpirationRequest;
  executeDisbursement: FinancierExecuteAnchorLoanDisbursementRequest;
  transaction: FinancierAnchorLoanTransactionRequest;
};

export function useFinancierAnchorLoanDetailState() {
  const modal = useModal();
  const { anchorLoanId } = useParams<any>();
  const { id: modalId, show: showModal, disableConfirmBtn } = useModal();
  const { t } = useTranslation(['format']);

  const [dataState, setDataState] = useState({
    loanDetail: {} as AnchorLoanDetailVOModel,
    invoiceDetail: {} as SuccessInvoiceDetailVOModel,
    arDetail: {} as SuccessArDetailVOModel,
    loanTransactionHistoryPage: [] as LoanTransactionVOModel[],
  });

  const repaymentRequestRef = useRef<FinancierAnchorLoanRepaymentRequest | null>(null);
  const expiredStatusRequestRef = useRef<FinancierAnchorLoanExpirationRequest | null>(null);
  const executeDisbursementRequestRef = useRef<FinancierExecuteAnchorLoanDisbursementRequest | null>(null);
  const transactionRequestRef = useRef<FinancierAnchorLoanTransactionRequest | null>(null);

  const { pageable: loanTransactionHistoryPageable, setPageable: setLoanTransactionHistoryPageable } = usePageable();

  const setLoanModalRequestData = useCallback(
    (type: LoanRequestType) => (data: RequestRefTypes[LoanRequestType]) => {
      const requestRefs: Record<LoanRequestType, MutableRefObject<RequestRefTypes[LoanRequestType] | null>> = {
        repayment: repaymentRequestRef,
        expiredStatus: expiredStatusRequestRef,
        executeDisbursement: executeDisbursementRequestRef,
        transaction: transactionRequestRef,
      };

      if (requestRefs[type]) {
        requestRefs[type].current = data;
      }
    },
    [],
  );

  const fetchAll = async () => {
    try {
      const [fetchedLoanDetail, fetchedLoanTransactionHistoryPage] = await Promise.all([
        requestFinancierAnchorLoanDetail(anchorLoanId),
        requestFinancierAnchorLoanTransaction(anchorLoanId, 0, 10),
      ]);

      setDataState(prevState => ({
        ...prevState,
        loanDetail: fetchedLoanDetail,
        loanTransactionHistoryPage: fetchedLoanTransactionHistoryPage.content,
      }));
      setLoanTransactionHistoryPageable(fetchedLoanTransactionHistoryPage);

      if (fetchedLoanDetail.collateralType === COLLATERAL_TYPE.INVOICE) {
        const successInvoiceInformationResponse = await requestFinancierSuccessInvoice(
          fetchedLoanDetail.successInvoiceId,
        );

        setDataState(prevState => ({
          ...prevState,
          invoiceDetail: successInvoiceInformationResponse,
        }));
      }

      if (fetchedLoanDetail.collateralType === COLLATERAL_TYPE.AR) {
        const successArInformationResponse = await requestFinancierSuccessArsDetail(fetchedLoanDetail.successArId);

        setDataState(prevState => ({
          ...prevState,
          arDetail: successArInformationResponse,
        }));
      }
    } catch (e: any) {
      modal.show(e);
    }
  };

  const fetchAnchorLoanDetail = async () => {
    try {
      const fetchedLoanDetail = await requestFinancierAnchorLoanDetail(anchorLoanId);

      setDataState(prevState => ({
        ...prevState,
        loanDetail: fetchedLoanDetail,
      }));
    } catch (e: any) {
      modal.show(e);
    }
  };

  const fetchAnchorLoanTransactionHistory = async () => {
    try {
      const response = await requestFinancierAnchorLoanTransaction(anchorLoanId, 0, 10);

      setDataState(prevState => ({
        ...prevState,
        loanTransactionHistoryPage: [...response.content],
      }));
      setLoanTransactionHistoryPageable(response);
    } catch (e: any) {
      modal.show(e);
    }
  };

  const onClickViewExpectedRepaymentAmount = async (e: any) => {
    e.preventDefault();
    const { repaymentDate, disbursedDate } = dataState.loanDetail;

    const expectedRepaymentDate = new Date(repaymentDate);
    const expectedRepaymentDateList: number[] = [
      expectedRepaymentDate.getFullYear(),
      expectedRepaymentDate.getMonth(),
      expectedRepaymentDate.getDate(),
    ];
    const toDate = new Date(
      expectedRepaymentDateList[0],
      expectedRepaymentDateList[1],
      expectedRepaymentDateList[2] + 180,
    );

    async function fetchAll() {
      const [expectedFinancingRepaymentDataResponse, financierHolidayResponse] = await Promise.all([
        requestFinancierAnchorLoanCalculateExpectedAmount(anchorLoanId, repaymentDate),
        requestFinancierCalendarList(
          0,
          Number(getDayTerm(convertToServerDateFormat(new Date(disbursedDate)), convertToServerDateFormat(toDate))) + 1,
          {
            fromDate: convertToServerDateFormat(new Date(disbursedDate)),
            toDate: convertToServerDateFormat(toDate),
            holiday: true,
          },
        ),
      ]);

      return { expectedFinancingRepaymentDataResponse, financierHolidayResponse };
    }

    showModal(
      <ExpectedFinancingRepaymentModal
        loanId={anchorLoanId}
        repaymentDate={repaymentDate}
        fetchAll={fetchAll}
        disbursedDate={disbursedDate}
        maxDate={toDate}
        requestSearch={requestFinancierAnchorLoanCalculateExpectedAmount}
        factoringEnable={false}
      />,
      {
        modalType: ModalType.ALERT,
        modalSize: ModalSize.L,
        title: t('text:View_Expected_Repayment_Amount'),
      },
    );
  };

  const onClickExecuteLoanDisbursement = (e: any) => {
    e.preventDefault();

    const isLoanExecutionImpossibleToday = new Date(dataState.loanDetail.desiredDisburseDate) > new Date();
    const submitChangeExecuteLoanDisbursement = async (data: FinancierExecuteAnchorLoanDisbursementRequest | null) => {
      if (!data) return;

      try {
        await requestFinancierExecuteAnchorLoanDisbursement(anchorLoanId, data);
        showModal(<h6>{t('text:The_information_has_been_successfully_updated')}</h6>, {
          closeBtnCb: async () => {
            await fetchAnchorLoanDetail();
            await fetchAnchorLoanTransactionHistory();
          },
        });
      } catch (e) {
        showModal(e);
      }
    };

    const executeLoanDisbursementModalOptions: ModalOptions = {
      modalType: ModalType.CONFIRM,
      title: t('text:Enter_Disbursement_Information'),
      closeBtnText: t('text:Cancel'),
      confirmBtnText: t('text:Confirm'),
      confirmBtnCb: () => submitChangeExecuteLoanDisbursement(executeDisbursementRequestRef.current),
      confirmBtnDisabled: isLoanExecutionImpossibleToday,
    };

    const repaymentDatePrev = dayjs(new Date(dataState.loanDetail.repaymentDate)).subtract(1, 'days').toDate();

    showModal(
      <FinancierAnchorFinancingDetailExecuteLoanDisbursementModal
        principalAmount={dataState.loanDetail.principalAmount}
        repaymentDatePrev={repaymentDatePrev}
        requestedDateTime={dataState.loanDetail.requestedDateTime}
        getSubmitData={setLoanModalRequestData('executeDisbursement')}
        modalInfo={{
          id: modalId,
          disableConfirmBtn,
        }}
        loanId={anchorLoanId}
        showNetDisbursementAmount={dataState.loanDetail.showNetDisbursementAmount}
      />,
      executeLoanDisbursementModalOptions,
    );
  };

  // 상환처리 요청 취소
  const onClickCancelRepayment = (e: any) => {
    e.preventDefault();
    const cancelRepaymentModalOptions: ModalOptions = {
      modalType: ModalType.CONFIRM,
      closeBtnText: t('text:Close'),
      confirmBtnText: t('text:Confirm'),
      confirmBtnCb: submitChangeLoanCancelRequest,
    };
    showModal(
      <h6>
        {t('text:Would_you_like_to_cancel_the_request_to_change_the_financing_status_to_Repaid?')}
        <br />
        {t('text:If_you_cancel,_the_entered_contents_will_not_be_saved_and_you_will_have_to_proceed_again')}
      </h6>,
      cancelRepaymentModalOptions,
    );
  };

  // 거래 갱신
  const onClickRenewLoanTransactions = (e: any) => {
    e.preventDefault();

    const renewLoanTransactionSuccessModal = () => {
      showModal(<h6>{t('text:The_information_has_been_successfully_updated')}</h6>, {
        modalType: ModalType.ALERT,
        closeBtnText: t('text:OK'),
        closeBtnCb: () => {
          fetchAnchorLoanDetail();
          fetchAnchorLoanTransactionHistory();
        },
      });
    };

    const submitChangeRenewLoanTransactions = async (data: FinancierAnchorLoanTransactionRequest | null) => {
      if (!data) return;

      try {
        await requestFinancierAnchorLoanTransactionRenew(anchorLoanId, data);
        renewLoanTransactionSuccessModal();
      } catch (e) {
        showModal(e);
      }
    };
    const renewLoanTransactionsModalOptions: ModalOptions = {
      modalType: ModalType.CONFIRM,
      title: t('text:Update_Repayment_Transaction'),
      closeBtnText: t('text:Cancel'),
      confirmBtnText: t('text:Confirm'),
      confirmBtnCb: () => submitChangeRenewLoanTransactions(transactionRequestRef.current),
    };

    showModal(
      <FinancierLoanTransactionRenewModal
        disbursedDate={dataState.loanDetail.disbursedDate}
        getSubmitData={setLoanModalRequestData('transaction')}
        modalInfo={{
          id: modalId,
          disableConfirmBtn,
        }}
        factoringEnable={false}
      />,
      renewLoanTransactionsModalOptions,
    );
  };

  // 상환 처리 내역 modal
  const onClickViewRepaymentHistory = (e: any) => {
    e.preventDefault();

    showModal(<FinancierViewRepaymentHistoryModal data={dataState.loanDetail} />, {
      modalType: ModalType.ALERT,
      title: t('text:View_Repayment_History'),
      closeBtnText: t('text:Close'),
    });
  };

  const onClickConfirmRepaidStatus = (e: any) => {
    e.preventDefault();
    showModal(<h6>{t('text:Would_you_like_to_approve_the_request_to_change_the_financing_status_to_Repaid?')}</h6>, {
      modalType: ModalType.CONFIRM,
      closeBtnText: t('text:Cancel'),
      confirmBtnCb: async () => {
        try {
          await requestFinancierApproveAnchorLoanRepayment(anchorLoanId);
          showModal(
            <h6>{t('text:The_approval_was_completed_successfully_and_the_financing_status_has_changed_to_Repaid')}</h6>,
            {
              modalType: ModalType.ALERT,
              closeBtnText: t('text:OK'),
              closeBtnCb: () => {
                fetchAnchorLoanDetail();
                fetchAnchorLoanTransactionHistory();
              },
            },
          );
        } catch (e) {
          showModal(e);
        }
      },
    });
  };

  const onClickRevertRepaidStatusRequest = (e: any) => {
    e.preventDefault();

    const submitRevertRepaidStatusRequest = async (reason: string) => {
      submitChangeReturn(reason, 'repayment').then(() => {
        fetchAnchorLoanDetail();
      });
    };

    showCommonReasonModal(
      [
        t('text:Would_you_like_to_revert_the_request_to_change_the_financing_status_to_Repaid?'),
        t('text:Please_enter_the_reason_for_the_revert_below'),
      ],
      submitRevertRepaidStatusRequest,
      true,
    );
  };

  // 연체 해제 요청 취소
  const onClickCancelOverdueRelease = (e: any) => {
    e.preventDefault();
    const cancelOverdueReleaseModalOptions: ModalOptions = {
      modalType: ModalType.CONFIRM,
      closeBtnText: t('text:Cancel'),
      confirmBtnText: t('text:Confirm'),
      confirmBtnCb: submitChangeLoanCancelRequest,
    };
    showModal(
      <h6>{t('text:Would_you_like_to_cancel_the_request_to_remove_the_Delinquent_financing_status?')}</h6>,
      cancelOverdueReleaseModalOptions,
    );
  };

  // 연체 처리 요청 취소
  const onClickCancelOverdue = (e: any) => {
    e.preventDefault();

    showModal(<h6>{t('text:Would_you_like_to_cancel_the_request_to_change_the_financing_status_to_Delinquent?')}</h6>, {
      modalType: ModalType.CONFIRM,
      title: t('text:Notice'),
      confirmBtnCb: async () => {
        try {
          await requestFinancierCancelAnchorLoan(anchorLoanId);
          showConfirmModal();
        } catch (e) {
          showModal(e);
        }
      },
      closeBtnText: t('text:Close'),
    });

    const showConfirmModal = () => {
      showModal(<h6>{t('text:The_delinquent_status_request_has_been_cancelled_successfully')}</h6>, {
        modalType: ModalType.ALERT,
        closeBtnText: t('text:OK'),
        closeBtnCb: fetchAnchorLoanDetail,
      });
    };
  };

  const onClickConfirmDelinquentStatus = (e: any) => {
    e.preventDefault();

    showModal(
      <h6>{t('text:Would_you_like_to_approve_the_request_to_change_the_financing_status_to_Delinquent?')}</h6>,
      {
        modalType: ModalType.CONFIRM,
        title: t('text:Notice'),
        confirmBtnCb: async () => {
          try {
            await requestFinancierApproveAnchorLoanOverdue(anchorLoanId);
            showConfirmModal();
          } catch (e) {
            showModal(e);
          }
        },
        closeBtnText: t('text:Cancel'),
      },
    );

    const showConfirmModal = () => {
      showModal(
        <h6>{t('text:The_approval_was_completed_successfully_and_the_financing_status_has_changed_to_Delinquent')}</h6>,
        {
          modalType: ModalType.ALERT,
          closeBtnText: t('text:OK'),
          closeBtnCb: fetchAnchorLoanDetail,
        },
      );
    };
  };

  // 현재 신청한 대출 처리에 대한 취소 요청
  const submitChangeLoanCancelRequest = async () => {
    try {
      await requestFinancierCancelAnchorLoan(anchorLoanId);
      fetchAnchorLoanDetail();
    } catch (e) {
      showModal(e);
    }
  };

  const onClickRevertDelinquentStatusRequest = (e: any) => {
    e.preventDefault();

    const submitRevertDelinquentStatusRequest = (reason: string) => {
      submitChangeReturn(reason, 'delinquent').then(() => {
        fetchAnchorLoanDetail();
      });
    };

    showCommonReasonModal(
      [
        t('text:Would_you_like_to_revert_the_request_to_change_the_financing_status_to_Delinquent?'),
        t('text:Please_enter_the_reason_below'),
      ],
      submitRevertDelinquentStatusRequest,
      true,
    );
  };

  const onClickCancelDisbursementCancellationRequest = (e: any) => {
    e.preventDefault();

    showModal(<h6>{t('text:Would_you_like_to_cancel_your_financing_disbursement_status_cancellation_request?')}</h6>, {
      modalType: ModalType.CONFIRM,
      closeBtnText: t('text:Cancel'),
      confirmBtnText: t('text:Confirm'),
      confirmBtnCb: submitChangeLoanCancelRequest,
    });
  };

  const onClickApproveDisbursementCancellation = (e: any) => {
    e.preventDefault();

    const submitApproveDisbursementCancellation = async () => {
      try {
        await requestFinancierApproveCancelAnchorLoanDisbursement(anchorLoanId);
        fetchAnchorLoanDetail();
      } catch (e) {
        showModal(e);
      }
    };

    showModal(
      <h6>
        {t('text:Would_you_like_to_confirm_the_cancellation_request?')}
        <br />
        {t('text:If_confirmed_the_cancellation_cannot_be_reverted')}
      </h6>,
      {
        modalType: ModalType.CONFIRM,
        closeBtnText: t('text:Cancel'),
        confirmBtnText: t('text:Confirm'),
        confirmBtnCb: submitApproveDisbursementCancellation,
      },
    );
  };

  const onClickRequestFinancierLoanRepayment = (e: any) => {
    e.preventDefault();

    const submitChangeRepayment = async (data: FinancierAnchorLoanRepaymentRequest | null) => {
      if (!data) return;

      try {
        await requestFinancierAnchorLoanRepayment(anchorLoanId, data);
        fetchAnchorLoanDetail();
      } catch (e) {
        showModal(e);
      }
    };

    const requestFinancierLoanRepaymentModalOptions: ModalOptions = {
      modalType: ModalType.CONFIRM,
      title: t('text:Repayment_Status_Request'),
      closeBtnText: t('text:Cancel'),
      confirmBtnText: t('text:Confirm'),
      confirmBtnCb: () => {
        submitChangeRepayment(repaymentRequestRef.current);
      },
    };

    showModal(
      <FinancierAnchorFinancingDetailRequestRepaymentModal
        repaidInterestAmount={dataState.loanDetail.repaidInterestAmount}
        repaidDelayAmount={dataState.loanDetail.repaidDelayAmount}
        disbursedDate={dataState.loanDetail.disbursedDate}
        getSubmitData={setLoanModalRequestData('repayment')}
        modalInfo={{ id: modalId, disableConfirmBtn }}
        loanId={anchorLoanId}
      />,

      requestFinancierLoanRepaymentModalOptions,
    );
  };

  const onClickRequestFinancierLoanOverdue = (e: any) => {
    e.preventDefault();
    const submitChangeOverdue = async () => {
      try {
        await requestFinancierAnchorLoanOverdue(anchorLoanId);
        fetchAnchorLoanDetail();
      } catch (e) {
        showModal(e);
      }
    };

    const requestFinancierLoanOverdueModalOptions: ModalOptions = {
      modalType: ModalType.CONFIRM,
      title: t('text:Delinquent_Status_Request'),
      closeBtnText: t('text:Cancel'),
      confirmBtnText: t('text:Confirm'),
      confirmBtnCb: submitChangeOverdue,
    };

    showModal(
      <h6>
        {t('text:Would_you_like_to_request_the_Authorizer_s_approval_to_change_the_financing_status_to_Delinquent?')}
      </h6>,
      requestFinancierLoanOverdueModalOptions,
    );
  };

  // 지급 취소 요청 (OP)
  const onClickRequestCancelDisbursement = (e: any) => {
    e.preventDefault();

    const showConfirmModal = () => {
      modal.show(<h6>{t('text:The_disbursement_cancellation_request_has_been_requested')}</h6>, {
        closeBtnCb: async () => await fetchAnchorLoanDetail(),
      });
    };

    const submitRequestCancelDisbursementRequest = async (reason: string) => {
      try {
        await requestFinancierRequestCancelAnchorLoanDisbursement(anchorLoanId, reason);
        showConfirmModal();
      } catch (e) {
        modal.show(e);
      }
    };

    showCommonReasonModal(
      [
        t('text:Would_you_like_to_request_the_Authorizers_confirmation_to_cancel_the_financing_disbursement?'),
        t('text:By_cancelling_the_disbursement_financing_status_will_change_to_Approved'),
        t('text:Please_enter_the_reason_below'),
      ],
      submitRequestCancelDisbursementRequest,
      true,
      t('text:Disbursement_Cancellation_Request'),
    );
  };

  // reason 모달 - 공통
  const showCommonReasonModal = (
    message: string[],
    submitConfirmFn: (reason: string) => void,
    reasonRequired?: boolean,
    title: string = 'text:Notice',
  ) => {
    let reason = '';

    const reasonModalOptions: ModalOptions = {
      modalType: ModalType.CONFIRM,
      title: t(title),
      closeBtnText: t('text:Cancel'),
      confirmBtnText: t('text:Confirm'),
      confirmBtnCb: () => {
        submitConfirmFn(reason);
      },
    };

    showModal(
      <>
        <h6>
          {message.map((item, i) => (
            <React.StrictMode key={i}>
              {item}
              {i !== item.length - 1 && <br />}
            </React.StrictMode>
          ))}
        </h6>
        <ReasonModal
          modalId={modalId}
          getReturnReason={(returnReason: string) => {
            reason = returnReason;
          }}
          required={reasonRequired}
        />
      </>,
      reasonModalOptions,
    );
  };

  // return 반려 - 반려 관련된 기능은 동일한 api 사용,
  // return Type 을 지정해, 성공적으로 반려 시 그에 해당하는 모달 내용 보여주기
  const submitChangeReturn = async (
    reason: string,
    returnType: 'delinquent' | 'expired' | 'repayment' | 'financing' | 'delinquentRelease',
  ) => {
    try {
      await requestFinancierReturnAnchorLoan(anchorLoanId, reason);
      const modalContent = () => {
        switch (returnType) {
          case 'delinquent':
            return t('text:The_delinquent_status_request_has_been_reverted');
          case 'expired':
            return t('text:The_expired_status_request_has_been_reverted');
          case 'repayment':
            return t('text:The_repaid_status_request_has_been_reverted');
          case 'financing':
            return t('text:The_financing_approval_request_has_been_reverted');
          case 'delinquentRelease':
            return t('text:The_delinquent_status_removal_request_has_been_reverted');
          default:
            return '';
        }
      };

      showModal(<h6>{modalContent()}</h6>, {
        closeBtnText: t('text:OK'),
      });
    } catch (e) {
      showModal(e);
    }
  };

  const onClickDeleteLoanTransactions = async (e: any) => {
    e.preventDefault();

    try {
      await requestFinancierAnchorLoanTransactionDelete(anchorLoanId);
      fetchAnchorLoanDetail();
      fetchAnchorLoanTransactionHistory();
    } catch (e: any) {
      showModal(e);
    }
  };

  const onClickRevertDisbursementCancellation = () => {
    const showConfirmModal = () => {
      modal.show(<h6>{t('text:The_disbursement_cancellation_request_has_been_reverted')}</h6>, {
        closeBtnCb: async () => {
          await fetchAnchorLoanDetail();
          scrollToTopOfElement();
        },
      });
    };
    const submitRevert = async (reason: string) => {
      try {
        await requestFinancierReturnAnchorLoan(anchorLoanId, reason);
        showConfirmModal();
      } catch (e) {
        showModal(e);
      }
    };

    showCommonReasonModal(
      [
        t('text:Would_you_like_to_revert_the_disbursement_cancellation_request?'),
        t('text:Please_enter_the_reason_for_the_revert_below'),
      ],
      submitRevert,
      true,
      t('text:Revert_Cancellation_Request'),
    );
  };

  // 연체 해제 요청 승인
  const onClickConfirmDelinquentStatusRemovalRequest = (e: any) => {
    e.preventDefault();
    const submitApproveOverdueRelease = async () => {
      try {
        await requestFinancierApproveAnchorLoanOverdueRelease(anchorLoanId);
        showApproveOverdueReleaseOKModal();
      } catch (e) {
        showModal(e);
      }
    };

    const approveOverdueReleaseModalOptions: ModalOptions = {
      modalType: ModalType.CONFIRM,
      title: t('text:Notice'),
      closeBtnText: t('text:Cancel'),
      confirmBtnCb: () => {
        submitApproveOverdueRelease();
      },
    };
    const showApproveOverdueReleaseOKModal = () => {
      const approveOverdueReleaseOKModalOptions: ModalOptions = {
        modalType: ModalType.ALERT,
        title: t('text:Notice'),
        closeBtnText: t('text:OK'),
        closeBtnCb: fetchAnchorLoanDetail,
      };
      showModal(
        <h6>{t('text:The_Delinquent_financing_status_has_been_removed_successfully')}</h6>,
        approveOverdueReleaseOKModalOptions,
      );
    };
    showModal(
      <h6>
        {t('text:Would_you_like_to_approve_the_request_to_remove_the_Delinquent_financing_status?')}
        <br />
        {t('text:If_approved_the_financing_status_will_change_to_the_Disbursed_status')}
      </h6>,
      approveOverdueReleaseModalOptions,
    );
  };

  //  연체 해제 요청 반려
  const onClickRevertDelinquentStatusRemovalRequest = (e: any) => {
    e.preventDefault();

    const submitRevertDelinquentStatusRemovalRequest = (reason: string) => {
      submitChangeReturn(reason, 'delinquentRelease').then(() => {
        fetchAnchorLoanDetail();
      });
    };

    showCommonReasonModal(
      [
        t('text:Would_you_like_to_revert_the_request_to_remove_the_Delinquent_financing_status?'),
        t('text:Please_enter_the_reason_for_the_revert_below'),
      ],
      submitRevertDelinquentStatusRemovalRequest,
      true,
    );
  };

  // 기타 만료 요청
  const onClickExpiredStatusRequest = (e: any) => {
    e.preventDefault();

    const showConfirmModal = () => {
      showModal(
        <h6>{t('text:The_request_to_change_the_financing_status_to_Expired_has_been_submitted_to_the_Authorizer')}</h6>,
        {
          modalType: ModalType.ALERT,
          closeBtnText: t('text:OK'),
          closeBtnCb: fetchAnchorLoanDetail,
        },
      );
    };

    const submitChangeRequestExpiration = async (data: FinancierAnchorLoanExpirationRequest | null) => {
      if (!data) return;

      try {
        await requestFinancierAnchorLoanExpiration(anchorLoanId, data);
        showConfirmModal();
      } catch (e) {
        showModal(e);
      }
    };

    showModal(
      <FinancierExpiredStatusRequestModal
        getSubmitData={setLoanModalRequestData('expiredStatus')}
        modalInfo={{ id: modalId, disableConfirmBtn }}
      />,
      {
        modalType: ModalType.CONFIRM,
        confirmBtnCb: () => submitChangeRequestExpiration(expiredStatusRequestRef.current),
      },
    );
  };

  // 기타만료 취소
  const onClickCancelExpiredStatusRequest = (e: any) => {
    e.preventDefault();
    const returnModalOptions: ModalOptions = {
      modalType: ModalType.CONFIRM,
      title: t('text:Notice'),
      closeBtnText: t('text:Cancel'),
      confirmBtnText: t('text:Confirm'),
      confirmBtnCb: submitChangeLoanCancelRequest,
    };
    showModal(
      <h6>{t('text:Would_you_like_to_cancel_the_request_to_change_the_financing_status_to_Expired?')}</h6>,
      returnModalOptions,
    );
  };

  // 기타만료 승인
  const onClickConfirmExpiredStatusRequest = (e: any) => {
    e.preventDefault();

    showModal(<h6>{t('text:Would_you_like_to_approve_the_request_to_change_the_financing_status_to_Expired?')}</h6>, {
      modalType: ModalType.CONFIRM,
      title: t('text:Notice'),
      closeBtnText: t('text:Cancel'),
      confirmBtnCb: async () => {
        try {
          await requestFinancierApproveAnchorLoanExpiration(anchorLoanId);
          showConfirmModal();
        } catch (e) {
          showModal(e);
        }
      },
    });
    const showConfirmModal = () => {
      showModal(<h6>{t('text:The_financing_expiration_has_been_completed_successfully')}</h6>, {
        modalType: ModalType.ALERT,
        title: t('text:Notice'),
        closeBtnText: t('text:OK'),
        closeBtnCb: fetchAnchorLoanDetail,
      });
    };
  };

  // 기타만료 반려
  const onClickRevertExpiredStatusRequest = (e: any) => {
    e.preventDefault();

    const submitRevertExpiredStatusRequest = (reason: string) => {
      submitChangeReturn(reason, 'expired').then(() => {
        fetchAnchorLoanDetail();
      });
    };

    showCommonReasonModal(
      [
        t('text:Would_you_like_to_revert_the_request_to_change_the_financing_status_to_Expired?'),
        t('text:Please_enter_the_reason_below'),
      ],
      submitRevertExpiredStatusRequest,
      true,
    );
  };

  const onClickRemoveDelinquentStatus = (e: any) => {
    e.preventDefault();
    const submitChangeOverdueRelease = async () => {
      try {
        await requestFinancierAnchorLoanOverdueRelease(anchorLoanId);
        showOverdueReleaseOKModal();
      } catch (e) {
        showModal(e);
      }
    };

    const requestOverdueReleaseModalOptions: ModalOptions = {
      modalType: ModalType.CONFIRM,
      closeBtnText: t('text:Cancel'),
      confirmBtnText: t('text:Confirm'),
      confirmBtnCb: () => {
        submitChangeOverdueRelease();
      },
    };

    const showOverdueReleaseOKModal = () => {
      const requestOverdueReleaseOKModalOptions: ModalOptions = {
        modalType: ModalType.ALERT,
        closeBtnText: t('text:OK'),
        closeBtnCb: fetchAnchorLoanDetail,
      };
      showModal(
        <h6>{t('text:The_request_to_remove_the_Delinquent_status_has_been_submitted_to_the_Authorizer')}</h6>,
        requestOverdueReleaseOKModalOptions,
      );
    };
    showModal(
      <h6>
        {t('text:Would_you_like_to_request_the_Authorizer_s_approval_to_remove_the_Delinquent_financing_status?')}
        <br />
        {t('text:If_approved_by_the_Authorizer_the_financing_status_will_change_to_the_Disbursed_status')}
      </h6>,
      requestOverdueReleaseModalOptions,
    );
  };

  return {
    state: dataState,
    fetchAll,
    fetchAnchorLoanTransactionHistory,
    loanTransactionHistoryPageable,
    onClickViewExpectedRepaymentAmount,
    updateFinancingStatusActions: {
      onClickRequestFinancierLoanRepayment,
      onClickRequestFinancierLoanOverdue,
      onClickViewRepaymentHistory,
      onClickCancelRepayment,
      onClickConfirmRepaidStatus,
      onClickRevertRepaidStatusRequest,
      onClickCancelOverdue,
      onClickCancelOverdueRelease,
      onClickConfirmDelinquentStatus,
      onClickRevertDelinquentStatusRequest,
      onClickExecuteLoanDisbursement,
      onClickRenewLoanTransactions,
      onClickViewExpectedRepaymentAmount,
      onClickRequestCancelDisbursement,
      onClickCancelDisbursementCancellationRequest,
      onClickApproveDisbursementCancellation,
      onClickDeleteLoanTransactions,
      onClickRevertDisbursementCancellation,
      onClickConfirmDelinquentStatusRemovalRequest,
      onClickRevertDelinquentStatusRemovalRequest,
      onClickExpiredStatusRequest,
      onClickCancelExpiredStatusRequest,
      onClickConfirmExpiredStatusRequest,
      onClickRevertExpiredStatusRequest,
      onClickRemoveDelinquentStatus,
    },
  };
}
