import type React from 'react';
import { useEffect, useRef } from 'react';

import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useToggle from 'hooks/useToggle';

import './CustomSelect.scss';

export interface CustomSelectProps<T> {
  value: T;
  options: T[];
  className?: string;
  style?: React.CSSProperties;
  getCurrentViewFunc: (v: T) => JSX.Element;
  getOptionViewFunc: (v: T) => JSX.Element;
}

export function isObjectEqual<T>(first: T, second: T, cnt?: number): boolean {
  if (cnt && cnt > 2) return true;
  if (typeof first !== typeof second) return false;
  if (typeof first === 'object') {
    for (const key in first) {
      if (!isObjectEqual(first[key], second[key], (cnt || 0) + 1)) return false;
    }
  } else if (typeof first !== 'function') {
    if (first !== second) return false;
  }

  return true;
}

function CustomSelect<T>({
  value,
  options,
  className = '',
  style,
  getOptionViewFunc,
  getCurrentViewFunc,
}: CustomSelectProps<T>): React.ReactElement<CustomSelectProps<T>> {
  const ref = useRef<HTMLDivElement>(null);
  const optionRef = useRef<HTMLDivElement[]>([]);
  const [isOptionOpen, setOptionOpen] = useToggle(ref);

  useEffect(() => {
    if (isOptionOpen) {
      const selectedDiv = optionRef.current.find(el => el?.className.includes('selected'));
      const optionsContainer = ref.current?.querySelector('.options');

      if (selectedDiv && optionsContainer) {
        const containerTop = optionsContainer.scrollTop;
        const containerHeight = optionsContainer.clientHeight;

        const selectedTop = selectedDiv.offsetTop;
        const selectedHeight = selectedDiv.offsetHeight;

        // 선택된 옵션을 컨테이너 중앙으로 스크롤
        optionsContainer.scrollTop = selectedTop - containerTop - containerHeight / 2 + selectedHeight / 2;
      }
    }
  }, [isOptionOpen]);

  const optionsView = options.map((o, i) => (
    <div
      className={`option ${isObjectEqual(o, value) ? 'selected' : ''}`}
      onClick={e => {
        setOptionOpen(false);
        e.stopPropagation();
      }}
      key={i}
      ref={el => (optionRef.current[i] = el!)}
    >
      {getOptionViewFunc(o)}
    </div>
  ));

  return (
    <div className={`custom-select ${className}`} style={style} ref={ref}>
      <div
        className="current"
        onClick={e => {
          setOptionOpen();
          e.stopPropagation();
        }}
      >
        {getCurrentViewFunc(value)}
        <FontAwesomeIcon icon={faCaretDown} />
      </div>
      {isOptionOpen && <div className="options">{optionsView}</div>}
    </div>
  );
}

export default CustomSelect;
