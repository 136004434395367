import { useTranslation } from 'react-i18next';

import Button, { ButtonColorEnum, ButtonVariantEnum } from 'components/stateless/Button/Button';
import GuideMessage, { MessageType } from 'components/stateless/GuideMessage/GuideMessage';
import { AUTHORITY_TYPE, LOAN_APPROVAL_TYPE } from 'enums';
import type { SignInModel } from 'models/SignInModel';
import type { AnchorLoanDetailVOModel } from 'models/vo/AnchorLoanDetailVO';
import { getSignIn } from 'utils/storage/LocalStorage';

interface AnchorLoanOverdueStatusGuideMessageProps {
  loanDetailData: AnchorLoanDetailVOModel;
  onClickCancelRepayment(e: any): void;
  onClickRenewLoanTransactions(e: any): void;
  onClickViewRepaymentHistory(e: any): void;
  onClickCancelOverdueRelease(e: any): void;
  onClickRevertRepaidStatusRequest(e: any): void;
  onClickConfirmDelinquentStatusRemovalRequest(e: any): void;
  onClickRevertDelinquentStatusRemovalRequest(e: any): void;
  onClickExpiredStatusRequest(e: any): void;
  onClickCancelExpiredStatusRequest(e: any): void;
  onClickConfirmExpiredStatusRequest(e: any): void;
  onClickRevertExpiredStatusRequest(e: any): void;
  onClickRemoveDelinquentStatus(e: any): void;
}

function AnchorLoanOverdueStatusGuideMessage({
  loanDetailData,
  onClickCancelOverdueRelease,
  onClickViewRepaymentHistory,
  onClickCancelRepayment,
  onClickRevertRepaidStatusRequest,
  onClickConfirmDelinquentStatusRemovalRequest,
  onClickRevertDelinquentStatusRemovalRequest,
  onClickExpiredStatusRequest,
  onClickCancelExpiredStatusRequest,
  onClickConfirmExpiredStatusRequest,
  onClickRevertExpiredStatusRequest,
  onClickRenewLoanTransactions,
  onClickRemoveDelinquentStatus,
}: AnchorLoanOverdueStatusGuideMessageProps) {
  const { t } = useTranslation();
  const signInModel: SignInModel | null = getSignIn();
  const { AUTHORIZER, OPERATOR } = AUTHORITY_TYPE;
  const { authorityType } = signInModel ?? {};
  const isOperator = authorityType === OPERATOR;
  const isAuthorizer = authorityType === AUTHORIZER;

  const { loanApprovalType, returnReason, expirationReason } = loanDetailData;

  const renderOverdueStatusMessage = (loanApprovalType: LOAN_APPROVAL_TYPE) => {
    switch (loanApprovalType) {
      case LOAN_APPROVAL_TYPE.OPERATOR_REPAID:
        if (isOperator) {
          return (
            <GuideMessage
              messageType={MessageType.ALERT}
              message={[
                t('text:The_repayment_status_has_been_requested'),
                t('text:Depending_on_the_confirmation_by_Authorizer_the_financing_status_will_change'),
                t(
                  'text:Cancelling_the_repayment_status_request_is_only_possible_before_confirmation,_and_can_be_done_by_clicking_on_the_button_to_the_left',
                ),
              ]}
              reasonTitle={t('text:REASON_FOR_REVERT')}
              reason={returnReason}
            >
              {{
                button: (
                  <Button
                    variant={ButtonVariantEnum.OUTLINED}
                    color={ButtonColorEnum.SECONDARY}
                    onClick={onClickCancelRepayment}
                  >
                    {t('text:Cancel_Repayment_Status_Request')}
                  </Button>
                ),
                link: (
                  <button className="guide-message-link-btn" onClick={onClickViewRepaymentHistory}>
                    {t('text:View_Repayment_History')}
                  </button>
                ),
              }}
            </GuideMessage>
          );
        } else if (isAuthorizer) {
          return (
            <GuideMessage
              message={[
                t('text:The_repayment_status_has_been_requested'),
                t('text:Please_proceed_with_confirmation_after_checking_the_repayment_information'),
              ]}
            >
              {{
                button: (
                  <Button
                    variant={ButtonVariantEnum.OUTLINED}
                    color={ButtonColorEnum.SECONDARY}
                    onClick={onClickRevertRepaidStatusRequest}
                  >
                    {t('text:Revert_Repayment_Status_Request')}
                  </Button>
                ),
                link: (
                  <button className="guide-message-link-btn" onClick={onClickViewRepaymentHistory}>
                    {t('text:View_Repayment_History')}
                  </button>
                ),
              }}
            </GuideMessage>
          );
        }
        break;
      case LOAN_APPROVAL_TYPE.OPERATOR_CLEAR_OVERDUE:
        if (isOperator) {
          return (
            <GuideMessage
              messageType={MessageType.ALERT}
              message={[t('text:The_delinquent_status_cancellation_has_been_requested')]}
            >
              {{
                button: (
                  <Button
                    variant={ButtonVariantEnum.OUTLINED}
                    color={ButtonColorEnum.SECONDARY}
                    onClick={onClickCancelOverdueRelease}
                  >
                    {t('text:Cancel_Delinquent_Status_Removal_Request')}
                  </Button>
                ),
              }}
            </GuideMessage>
          );
        } else if (isAuthorizer) {
          return (
            <GuideMessage
              messageType={MessageType.ALERT}
              message={[t('text:The_delinquent_status_cancellation_has_been_requested')]}
            >
              {{
                button: (
                  <>
                    <Button onClick={onClickConfirmDelinquentStatusRemovalRequest}>
                      {t('text:Confirm_Delinquent_Status_Removal_Request')}
                    </Button>
                    <Button
                      variant={ButtonVariantEnum.OUTLINED}
                      color={ButtonColorEnum.SECONDARY}
                      onClick={onClickRevertDelinquentStatusRemovalRequest}
                    >
                      {t('text:Revert_Delinquent_Status_Removal_Request')}
                    </Button>
                  </>
                ),
              }}
            </GuideMessage>
          );
        }
        break;
      case LOAN_APPROVAL_TYPE.OPERATOR_EXPIRATION:
        if (isOperator) {
          return (
            <GuideMessage
              messageType={MessageType.ALERT}
              message={[
                t('text:The_expired_status_has_been_requested'),
                t('text:The_financing_status_will_change_when_the_Authorizer_approves_the_request'),
                t(
                  'text:Cancel_the_expired_status_request_before_the_Authorizers_approval_by_clicking_on_the_Cancel_Expired_Status_Request_button_on_the_right',
                ),
              ]}
              reasonTitle={t('text:REASON_FOR_EXPIRATION')}
              reason={expirationReason}
            >
              {{
                button: (
                  <Button
                    variant={ButtonVariantEnum.OUTLINED}
                    color={ButtonColorEnum.SECONDARY}
                    onClick={onClickCancelExpiredStatusRequest}
                  >
                    {t('text:Cancel_Expired_Status_Request')}
                  </Button>
                ),
              }}
            </GuideMessage>
          );
        } else if (isAuthorizer) {
          return (
            <GuideMessage
              messageType={MessageType.ALERT}
              message={[
                t('text:The_expired_status_has_been_requested'),
                t(
                  'text:Check_the_reason_for_the_expiration_below_and_click_on_the_Confirm_Expired_Status_Request_button_to_approve_the_request',
                ),
                t(
                  'text:If_the_financing_has_not_expired_click_on_the_Revert_Expired_Status_Request_button_and_revert_the_request',
                ),
              ]}
              reasonTitle={t('text:REASON_FOR_EXPIRATION')}
              reason={expirationReason}
            >
              {{
                button: (
                  <>
                    <Button onClick={onClickConfirmExpiredStatusRequest}>
                      {t('text:Confirm_Expired_Status_Request')}
                    </Button>
                    <Button
                      variant={ButtonVariantEnum.OUTLINED}
                      color={ButtonColorEnum.SECONDARY}
                      onClick={onClickRevertExpiredStatusRequest}
                    >
                      {t('text:Revert_Expired_Status_Request')}
                    </Button>
                  </>
                ),
              }}
            </GuideMessage>
          );
        }
        break;
      case LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_EXPIRATION:
        if (isOperator) {
          const authorizerReturnedExpirationStatusOperatorGuideMessage = [
            t('text:The_expired_status_request_has_been_reverted'),
            t('text:Check_the_reason_for_the_revert_below'),
            t(
              'text:Cancel_the_expired_status_request_before_the_Authorizers_approval_by_clicking_on_the_Cancel_Expired_Status_Request_button_on_the_right',
            ),

            t(
              'text:If_there_are_any_repayment_transactions_before_the_financing_is_completely_repaid_click_on_the_Update_Repayment_Transaction_button_to_reflect_the_changes',
            ),
          ];

          return (
            <GuideMessage
              messageType={MessageType.ALERT}
              message={authorizerReturnedExpirationStatusOperatorGuideMessage}
              reasonTitle={t('text:REASON_FOR_REVERT')}
              reason={returnReason}
            >
              {{
                button: (
                  <>
                    <Button
                      variant={ButtonVariantEnum.OUTLINED}
                      color={ButtonColorEnum.SECONDARY}
                      onClick={onClickCancelExpiredStatusRequest}
                    >
                      {t('text:Cancel_Expired_Status_Request')}
                    </Button>

                    <Button onClick={onClickRenewLoanTransactions}>{t('text:Update_Repayment_Transaction')}</Button>
                  </>
                ),
              }}
            </GuideMessage>
          );
        } else if (isAuthorizer) {
          const authorizerReturnedExpirationStatusAuthorizerGuideMessage = [
            t('text:The_expired_status_request_has_been_reverted'),
            t('text:Check_the_reason_for_the_revert_below'),

            t(
              'text:If_there_are_any_repayment_transactions_before_the_financing_is_completely_repaid_click_on_the_Update_Repayment_Transaction_button_to_reflect_the_changes',
            ),
          ];

          return (
            <GuideMessage
              messageType={MessageType.ALERT}
              message={authorizerReturnedExpirationStatusAuthorizerGuideMessage}
              reasonTitle={t('text:REASON_FOR_REVERT')}
              reason={returnReason}
            >
              {{
                button: (
                  <Button onClick={onClickRenewLoanTransactions}>{t('text:Update_Repayment_Transaction')}</Button>
                ),
              }}
            </GuideMessage>
          );
        }
        break;
      case LOAN_APPROVAL_TYPE.AUTHORIZER_RETURNED_CLEAR_OVERDUE:
        return (
          <GuideMessage
            messageType={MessageType.ALERT}
            message={[
              t('text:The_delinquent_status_cancellation_request_has_been_reverted'),
              t('text:Check_the_reason_for_the_revert_below'),
            ]}
            reasonTitle={t('text:REASON_FOR_REVERT')}
            reason={returnReason}
          >
            {{
              button: (
                <Button
                  variant={ButtonVariantEnum.OUTLINED}
                  color={ButtonColorEnum.SECONDARY}
                  style={{ display: isOperator ? 'block' : 'none' }}
                  onClick={onClickCancelOverdueRelease}
                >
                  {t('text:Cancel_Delinquent_Status_Request')}
                </Button>
              ),
            }}
          </GuideMessage>
        );

      default:
        if (isOperator) {
          return (
            <GuideMessage messageType={MessageType.ALERT} message={[t('text:The_financing_repayment_is_delinquent')]}>
              {{
                button: (
                  <>
                    <Button
                      variant={ButtonVariantEnum.OUTLINED}
                      color={ButtonColorEnum.SECONDARY}
                      onClick={onClickExpiredStatusRequest}
                    >
                      {t('text:Expired_Status_Request')}
                    </Button>
                    <Button
                      variant={ButtonVariantEnum.OUTLINED}
                      color={ButtonColorEnum.SECONDARY}
                      onClick={onClickRemoveDelinquentStatus}
                    >
                      {t('text:Remove_Delinquent_Status')}
                    </Button>
                  </>
                ),
              }}
            </GuideMessage>
          );
        } else if (isAuthorizer) {
          return (
            <GuideMessage messageType={MessageType.ALERT} message={[t('text:The_financing_repayment_is_delinquent')]} />
          );
        }
    }
  };

  return <>{renderOverdueStatusMessage(loanApprovalType)}</>;
}

export default AnchorLoanOverdueStatusGuideMessage;
