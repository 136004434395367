import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';
import { isEmpty } from 'lodash-es';

import Button from 'components/stateless/Button/Button';
import { NumericFormatInput } from 'components/stateless/CommonForm';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import FormDatePickerInput from 'components/stateless/CommonForm/FormDatePickerInput';
import FormInput from 'components/stateless/CommonForm/FormInput';
import useFinancierHolidays from 'hooks/useFinancierHolidays';
import { convertToServerDateFormat, getDayTerm } from 'utils/date/date';
import { requestFinancierAnchorLoanCalculateNetDisbursement } from 'utils/http/api/financier/anchor-loans';
import type { FinancierExecuteAnchorLoanDisbursementRequest } from 'utils/http/api/financier/anchor-loans/request';

interface FinancierAnchorFinancingDetailExecuteLoanDisbursementModalProps {
  principalAmount: number;
  requestedDateTime: string;
  getSubmitData(data: FinancierExecuteAnchorLoanDisbursementRequest): void;
  modalInfo: {
    id: number;
    disableConfirmBtn: (id: number, disabled: boolean) => void;
  };
  loanId: number;
  showNetDisbursementAmount: boolean;
  repaymentDatePrev: Date;
}

export type FinancierFinancingDetailExecuteLoanDisbursementFormType = FinancierExecuteAnchorLoanDisbursementRequest & {
  netDisbursementAmount: number; // 보여주기용
};

function FinancierAnchorFinancingDetailExecuteLoanDisbursementModal({
  principalAmount,
  requestedDateTime,
  modalInfo,
  loanId,
  getSubmitData,
  showNetDisbursementAmount,
  repaymentDatePrev,
}: FinancierAnchorFinancingDetailExecuteLoanDisbursementModalProps) {
  const { register, watch, getValues, setValue, errors, control } =
    useForm<FinancierFinancingDetailExecuteLoanDisbursementFormType>();

  const { t } = useTranslation(['format']);

  const { disbursedDate, disbursedTotalInterestRate, financierLoanId, disbursedAmount, prepaidInterestAmount } =
    watch();
  const { id: modalId, disableConfirmBtn } = modalInfo;

  const [clickedEstimateButton, setClickedEstimateButton] = useState<boolean>(false);
  const requiredFieldCheck =
    !isEmpty(disbursedDate) && !isEmpty(disbursedTotalInterestRate) && !isEmpty(String(disbursedAmount));

  const canSubmit = showNetDisbursementAmount ? requiredFieldCheck && clickedEstimateButton : requiredFieldCheck;

  const maxDate = repaymentDatePrev < new Date() ? repaymentDatePrev : new Date();

  useEffect(() => {
    const { netDisbursementAmount, ...formData } = getValues();

    getSubmitData(formData);
  }, [
    getSubmitData,
    getValues,
    disbursedDate,
    disbursedTotalInterestRate,
    financierLoanId,
    disbursedAmount,
    prepaidInterestAmount,
  ]);

  useEffect(() => {
    disableConfirmBtn(modalId, !canSubmit);
  }, [canSubmit, disableConfirmBtn, modalId]);

  useEffect(() => {
    setValue('disbursedAmount', principalAmount ?? '');
  }, [principalAmount, setValue]);

  const { financierHolidays } = useFinancierHolidays(
    Number(getDayTerm(convertToServerDateFormat(new Date(requestedDateTime)), convertToServerDateFormat(maxDate))) + 1,
    dayjs(requestedDateTime).format('YYYY-MM-DD'),
    dayjs(maxDate).format('YYYY-MM-DD'),
  );

  const fetchCalculateNetDisbursement = async () => {
    const { disbursedDate, disbursedTotalInterestRate, disbursedAmount } = getValues();
    const response = await requestFinancierAnchorLoanCalculateNetDisbursement(loanId, {
      disbursedDate,
      disbursedTotalInterestRate,
      disbursedAmount,
    });

    setValue('netDisbursementAmount', response.netDisbursementAmount);
    setValue('prepaidInterestAmount', response.expectedInterestAmount);
  };

  const handleClickEstimateButton = async () => {
    await fetchCalculateNetDisbursement();
    setClickedEstimateButton(true);
  };

  return (
    <FormBorder editable={true}>
      <FormContents>
        <div className="row">
          <FormInput
            col={12}
            placeholder=""
            label={t('text:Financier_Financing_ID')}
            name="financierLoanId"
            ref={register}
            error={errors.financierLoanId}
          />
        </div>
        <div className="row">
          <FormDatePickerInput
            col={12}
            label={t('text:Disbursed_Date')}
            name="disbursedDate"
            control={control}
            minDate={new Date(requestedDateTime)}
            maxDate={maxDate}
            excludeDates={financierHolidays}
            error={errors.disbursedDate}
          />
        </div>
        <div className="row">
          <div className="col-12">
            <label className="information-form__label">{t('text:Total_Interest_Rate_(APR)')}</label>
            <div className="d-flex">
              <NumericFormatInput
                control={control}
                numberType="float"
                name="disbursedTotalInterestRate"
                className="information-form__input text-end"
              />
              <div className="ms-2 mt-1">%</div>
            </div>
          </div>
        </div>
        <div className="row">
          <NumericFormatInput
            control={control}
            name="disbursedAmount"
            numberType="bigNumber"
            label={t('text:Disbursed_Amount')}
            col={12}
            placeholder=""
            textAlign="text-end"
            error={errors.disbursedAmount}
          />
        </div>
        {showNetDisbursementAmount && (
          <>
            <div className="row">
              <div className="col-12">
                <Button onClick={handleClickEstimateButton} bold disabled={!requiredFieldCheck}>
                  {t('text:Estimate')}
                </Button>
              </div>
            </div>
            <div className="row">
              <NumericFormatInput
                control={control}
                name="prepaidInterestAmount"
                numberType="bigNumber"
                label={t('text:Interest_Amount')}
                col={12}
                placeholder=""
                textAlign="text-end"
                disabled={!canSubmit}
              />
            </div>
            <div className="row">
              <NumericFormatInput
                control={control}
                name="netDisbursementAmount"
                numberType="bigNumber"
                label={t('text:Advance_Disbursement')}
                col={12}
                placeholder=""
                textAlign="text-end"
                disabled={!canSubmit}
              />
            </div>
          </>
        )}
      </FormContents>
    </FormBorder>
  );
}

export default FinancierAnchorFinancingDetailExecuteLoanDisbursementModal;
