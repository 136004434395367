import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';
import { isEmpty, isNumber } from 'lodash-es';

import Button from 'components/stateless/Button/Button';
import { NumericFormatInput } from 'components/stateless/CommonForm';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import FormDatePickerInput from 'components/stateless/CommonForm/FormDatePickerInput';
import { FormValue } from 'components/stateless/CommonForm/FormValue';
import useFinancierHolidays from 'hooks/useFinancierHolidays';
import useProperty from 'hooks/useProperty';
import { CalculatorBigNumber } from 'utils/bigNumber';
import { convertToServerDateFormat, getDayTerm } from 'utils/date/date';
import { requestFinancierAnchorLoanCalculateExpectedAmount } from 'utils/http/api/financier/anchor-loans';
import type { FinancierAnchorLoanRepaymentRequest } from 'utils/http/api/financier/anchor-loans/request';
import useModal from 'utils/modal/useModal';

interface FinancierAnchorFinancingDetailRequestRepaymentModalProps {
  repaidInterestAmount: number;
  repaidDelayAmount: number;
  disbursedDate: string;
  getSubmitData(data: FinancierAnchorLoanRepaymentRequest): void;
  modalInfo: {
    id: number;
    disableConfirmBtn: (id: number, disabled: boolean) => void;
  };
  loanId: number;
}

function FinancierAnchorFinancingDetailRequestRepaymentModal({
  repaidInterestAmount,
  repaidDelayAmount,
  disbursedDate,
  getSubmitData,
  modalInfo,
  loanId,
}: FinancierAnchorFinancingDetailRequestRepaymentModalProps) {
  const { t } = useTranslation(['format']);
  const modal = useModal();

  const getProperty = useProperty<FinancierAnchorLoanRepaymentRequest>();

  const { watch, getValues, errors, control, setValue } = useForm<FinancierAnchorLoanRepaymentRequest>({
    mode: 'onSubmit',
    shouldFocusError: true,
  });

  const { totalInterestAmount, totalDelayAmount, transactionDate } = watch();

  const totalAmounts = watch(['totalInterestAmount', 'totalDelayAmount']);
  const { id: modalId, disableConfirmBtn } = modalInfo;

  const calculatorBigNumber = new CalculatorBigNumber();

  const canSubmit = useCallback(() => {
    return !isEmpty(totalDelayAmount) && !isEmpty(totalInterestAmount) && !isEmpty(transactionDate);
  }, [totalDelayAmount, totalInterestAmount, transactionDate]);

  useEffect(() => {
    if (canSubmit()) getSubmitData(getValues());
  }, [canSubmit, getSubmitData, getValues, totalDelayAmount, totalInterestAmount]);

  useEffect(() => {
    disableConfirmBtn(modalId, !canSubmit());
  }, [canSubmit, disableConfirmBtn, modalId]);

  const { financierHolidays } = useFinancierHolidays(
    Number(getDayTerm(convertToServerDateFormat(new Date(disbursedDate)), convertToServerDateFormat(new Date()))) + 1,
    disbursedDate,
    dayjs().format('YYYY-MM-DD'),
  );

  const handleClickEstimateButton = async (e: any): Promise<void> => {
    e.preventDefault();

    try {
      const response = await requestFinancierAnchorLoanCalculateExpectedAmount(loanId, transactionDate);
      setValue('totalInterestAmount', String(response.scheduledInterestAmount)); // 임시 string 형변환
      setValue('totalDelayAmount', String(response.scheduledDelayAmount));
    } catch (error) {
      modal.show(error);
    }
  };

  return (
    <>
      <div className="mb-1">* {t('text:Enter_the_information_of_the_completed_repayment_below')}</div>
      <FormBorder editable>
        <FormContents>
          <div className="row">
            <FormDatePickerInput
              col={12}
              label={t('text:Repayment_Date')}
              name={getProperty('transactionDate')}
              control={control}
              error={errors.transactionDate}
              minDate={new Date(disbursedDate)}
              maxDate={new Date()}
              excludeDates={financierHolidays}
            >
              <div className="align-self-center ps-3">
                <Button
                  onClick={handleClickEstimateButton}
                  className="flex-column-center text-bold"
                  disabled={!transactionDate}
                >
                  {t('text:Estimate')}
                </Button>
              </div>
            </FormDatePickerInput>
          </div>
          <div className="row">
            <NumericFormatInput
              col={12}
              control={control}
              label={t('text:Interest_Amount_Repaid')}
              name={getProperty('totalInterestAmount')}
              numberType="bigNumber"
              error={errors.totalInterestAmount}
            />
          </div>
          <div className="row">
            <FormValue
              className="information-form__input text-end"
              col={12}
              label={t('text:Interest_Amount_Repaid_at_this_time')}
              value={
                totalAmounts.totalInterestAmount &&
                isNumber(repaidInterestAmount) &&
                calculatorBigNumber.add(totalAmounts.totalInterestAmount).minus(repaidInterestAmount).get()
              }
              format="number"
            />
          </div>
          <div className="row">
            <NumericFormatInput
              control={control}
              col={12}
              name={getProperty('totalDelayAmount')}
              label={t('text:Delinquent_Interest_Amount_Repaid')}
              numberType="bigNumber"
              error={errors.totalDelayAmount}
            />
          </div>
          <div className="row">
            <FormValue
              className="information-form__input text-end"
              col={12}
              label={t('text:Delinquent_Interest_Amount_Repaid_at_this_time')}
              value={
                totalAmounts.totalDelayAmount &&
                isNumber(repaidDelayAmount) &&
                calculatorBigNumber.add(totalAmounts.totalDelayAmount).minus(repaidDelayAmount).get()
              }
              format="number"
            />
          </div>
        </FormContents>
      </FormBorder>
    </>
  );
}

export default FinancierAnchorFinancingDetailRequestRepaymentModal;
