import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';
import { isEmpty } from 'lodash-es';

import { NumericFormatInput } from 'components/stateless/CommonForm';
import { FormBorder } from 'components/stateless/CommonForm/FormBorder';
import { FormContents } from 'components/stateless/CommonForm/FormContents';
import FormDatePickerInput from 'components/stateless/CommonForm/FormDatePickerInput';
import useFinancierHolidays from 'hooks/useFinancierHolidays';
import useProperty from 'hooks/useProperty';
import { convertToServerDateFormat, getDayTerm } from 'utils/date/date';
import type { FinancierLoanTransactionRequest } from 'utils/http/api/financier/loans/request';

interface FinancierLoanTransactionRenewModalProps {
  disbursedDate: string;
  getSubmitData(data: FinancierLoanTransactionRequest): void;
  factoringEnable: boolean;
  modalInfo: {
    id: number;
    disableConfirmBtn: (id: number, disabled: boolean) => void;
  };
}

function FinancierLoanTransactionRenewModal({
  disbursedDate,
  getSubmitData,
  factoringEnable,
  modalInfo,
}: FinancierLoanTransactionRenewModalProps) {
  const { t } = useTranslation(['format']);

  const getProperty = useProperty<FinancierLoanTransactionRequest>();

  const { watch, getValues, errors, control } = useForm<FinancierLoanTransactionRequest>({
    mode: 'onSubmit',
  });

  const { transactionDate, repaidPrincipalAmount, repaidInterestAmount, repaidDelayAmount } = watch();
  const { id: modalId, disableConfirmBtn } = modalInfo;

  const canSubmit = useCallback(() => {
    return (
      !isEmpty(transactionDate) &&
      !isEmpty(repaidPrincipalAmount) &&
      (factoringEnable || !isEmpty(repaidInterestAmount)) &&
      !isEmpty(repaidDelayAmount)
    );
  }, [transactionDate, repaidPrincipalAmount, repaidInterestAmount, repaidDelayAmount]);

  useEffect(() => {
    if (canSubmit()) getSubmitData(getValues());
  }, [canSubmit, getSubmitData, getValues]);

  useEffect(() => {
    disableConfirmBtn(modalId, !canSubmit());
  }, [canSubmit, disableConfirmBtn, modalId]);

  const { financierHolidays } = useFinancierHolidays(
    Number(getDayTerm(convertToServerDateFormat(new Date(disbursedDate)), convertToServerDateFormat(new Date()))) + 1,
    disbursedDate,
    dayjs().format('YYYY-MM-DD'),
  );

  return (
    <>
      <div className="mb-1">* {t('text:Enter_the_transaction_information_for_the_selected_date')}</div>
      <FormBorder editable>
        <FormContents>
          <div className="row">
            <FormDatePickerInput
              col={12}
              label={t('text:Repayment_Date')}
              name={getProperty('transactionDate')}
              control={control}
              error={errors.transactionDate}
              minDate={new Date(disbursedDate)}
              maxDate={new Date()}
              excludeDates={financierHolidays}
            />
          </div>
          <div className="row">
            <NumericFormatInput
              control={control}
              col={12}
              name={getProperty('repaidPrincipalAmount')}
              numberType="bigNumber"
              label={t('text:Principle_Amount_Repaid')}
              textAlign="text-end"
              error={errors.repaidPrincipalAmount}
            />
          </div>
          {!factoringEnable && (
            <div className="row">
              <NumericFormatInput
                control={control}
                name={getProperty('repaidInterestAmount')}
                numberType="bigNumber"
                label={t('text:Interest_Amount_Repaid')}
                col={12}
                textAlign="text-end"
                error={errors.repaidInterestAmount}
              />
            </div>
          )}
          <div className="row">
            <NumericFormatInput
              control={control}
              col={12}
              numberType="bigNumber"
              label={t('text:Delinquent_Interest_Amount_Repaid')}
              textAlign="text-end"
              name={getProperty('repaidDelayAmount')}
              error={errors.repaidDelayAmount}
            />
          </div>
        </FormContents>
      </FormBorder>
    </>
  );
}

export default FinancierLoanTransactionRenewModal;
