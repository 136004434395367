import type Pageable from 'models/Pageable';
import type { AnchorLoanDetailVO, AnchorLoanDetailVOModel } from 'models/vo/AnchorLoanDetailVO';
import { formattingToAnchorLoanDetailVOModel } from 'models/vo/AnchorLoanDetailVO';
import { formattingToAnchorLoanVOModel } from 'models/vo/AnchorLoanVO';
import type { AnchorLoanVO, AnchorLoanVOModel } from 'models/vo/AnchorLoanVO';
import type { CalculateNetDisbursementVO, CalculateNetDisbursementVOModel } from 'models/vo/CalculateNetDisbursementVO';
import { formattingToCalculateNetDisbursementVOModel } from 'models/vo/CalculateNetDisbursementVO';
import type { ExpectedLoanRepaymentVO, ExpectedLoanRepaymentVOModel } from 'models/vo/ExpectedLoanRepaymentVO';
import { formattingToExpectedLoanRepaymentVOModel } from 'models/vo/ExpectedLoanRepaymentVO';
import type { LoanTransactionVO, LoanTransactionVOModel } from 'models/vo/LoanTransactionVO';
import { formattingToLoanTransactionVOModel } from 'models/vo/LoanTransactionVO';
import http from 'utils/http';
import API_FI from 'utils/http/api/financier';

import type {
  FinancierAnchorLoanCalculateNetDisbursementRequest,
  FinancierAnchorLoanExpirationRequest,
  FinancierAnchorLoanListRequest,
  FinancierAnchorLoanRepaymentRequest,
  FinancierAnchorLoanTransactionRequest,
  FinancierExecuteAnchorLoanDisbursementRequest,
} from './request';

export async function requestFinancierAnchorLoanList(
  pageNumber: number,
  rowCount: number,
  searchValue?: FinancierAnchorLoanListRequest,
): Promise<Pageable<AnchorLoanVOModel[]>> {
  const response = await http.get<Pageable<AnchorLoanVO[]>>({
    url: API_FI.ANCHOR_LOANS.LOANS,
    data: {
      pageNumber,
      rowCount,
      ...searchValue,
    },
  });

  return {
    ...response,
    content: response.content.map(data => formattingToAnchorLoanVOModel(data)),
  };
}

export async function requestFinancierAnchorLoanDetail(anchorLoanId: number): Promise<AnchorLoanDetailVOModel> {
  const response = await http.get<AnchorLoanDetailVO>({
    url: API_FI.ANCHOR_LOANS.LOAN(anchorLoanId),
  });

  return formattingToAnchorLoanDetailVOModel(response);
}

// 대출 지급 확인
export async function requestFinancierExecuteAnchorLoanDisbursement(
  loanId: number,
  data: FinancierExecuteAnchorLoanDisbursementRequest,
): Promise<void> {
  return await http.put({
    url: API_FI.ANCHOR_LOANS.EXECUTE_LOAN_DISBURSEMENT(loanId),
    data,
  });
}

// 대출 지급 취소 요청
export async function requestFinancierRequestCancelAnchorLoanDisbursement(
  loanId: number,
  reason: string,
): Promise<void> {
  return await http.put({
    url: API_FI.ANCHOR_LOANS.REQUEST_CANCEL_LOAN_DISBURSEMENT(loanId),
    data: { reason },
  });
}

// 대출 지급 취소 승인
export async function requestFinancierApproveCancelAnchorLoanDisbursement(loanId: number): Promise<void> {
  return await http.put({
    url: API_FI.ANCHOR_LOANS.APPROVE_CANCEL_LOAN_DISBURSEMENT(loanId),
  });
}

// 거래 내역 조회
export async function requestFinancierAnchorLoanTransaction(
  loanId: number,
  pageNumber: number,
  rowCount: number,
): Promise<Pageable<LoanTransactionVOModel[]>> {
  const response = await http.get<Pageable<LoanTransactionVO[]>>({
    url: API_FI.ANCHOR_LOANS.LOAN_TRANSACTIONS(loanId),
    data: {
      pageNumber,
      rowCount,
    },
  });
  const LoanTransactionList: LoanTransactionVOModel[] = response.content.map((data: LoanTransactionVO) =>
    formattingToLoanTransactionVOModel(data),
  );
  const LoanTransactionPage: Pageable<LoanTransactionVOModel[]> = {
    ...response,
    content: LoanTransactionList,
  };

  return LoanTransactionPage;
}

// 대출 갱신 요청
export async function requestFinancierAnchorLoanTransactionRenew(
  loanId: number,
  data: FinancierAnchorLoanTransactionRequest,
): Promise<LoanTransactionVOModel> {
  const response = await http.post<LoanTransactionVO>({
    url: API_FI.ANCHOR_LOANS.LOAN_TRANSACTIONS(loanId),
    data,
  });

  return formattingToLoanTransactionVOModel(response);
}

// 마지막 거래 내역 삭제
export async function requestFinancierAnchorLoanTransactionDelete(loanId: number) {
  const response = await http.delete({
    url: API_FI.ANCHOR_LOANS.LOAN_TRANSACTIONS(loanId),
  });

  return response;
}

// 결재 요청 반려
export async function requestFinancierReturnAnchorLoan(loanId: number, reason: string): Promise<void> {
  return await http.put<void>({
    url: API_FI.ANCHOR_LOANS.RETURN_LOAN(loanId),
    data: { reason },
  });
}

// 결재 요청 취소
export async function requestFinancierCancelAnchorLoan(loanId: number): Promise<void> {
  return await http.put({
    url: API_FI.ANCHOR_LOANS.CANCEL_LOAN(loanId),
  });
}

// 상환 완료 결재 요청
export async function requestFinancierAnchorLoanRepayment(
  loanId: number,
  data: FinancierAnchorLoanRepaymentRequest,
): Promise<void> {
  return await http.put({
    url: API_FI.ANCHOR_LOANS.REQUEST_LOAN_REPAYMENT(loanId),
    data,
  });
}

// 상환 완료 승인
export async function requestFinancierApproveAnchorLoanRepayment(loanId: number): Promise<void> {
  return await http.put({
    url: API_FI.ANCHOR_LOANS.APPROVE_LOAN_REPAYMENT(loanId),
  });
}

// 대출 연체 처리 승인 요청
export async function requestFinancierAnchorLoanOverdue(loanId: number): Promise<void> {
  return await http.put<void>({
    url: API_FI.ANCHOR_LOANS.REQUEST_LOAN_OVERDUE(loanId),
  });
}

// 연체 처리 요청 승인
export async function requestFinancierApproveAnchorLoanOverdue(loanId: number): Promise<void> {
  return await http.put<void>({
    url: API_FI.ANCHOR_LOANS.APPROVE_LOAN_OVERDUE(loanId),
  });
}

// 기타 만료 처리 승인 요청
export async function requestFinancierAnchorLoanExpiration(
  loanId: number,
  data: FinancierAnchorLoanExpirationRequest,
): Promise<void> {
  return await http.put<void>({
    url: API_FI.ANCHOR_LOANS.REQUEST_LOAN_EXPIRATION(loanId),
    data,
  });
}

// 기타 만료 처리 승인
export async function requestFinancierApproveAnchorLoanExpiration(loanId: number): Promise<void> {
  return await http.put<void>({
    url: API_FI.ANCHOR_LOANS.APPROVE_LOAN_EXPIRATION(loanId),
  });
}

// 연체 해제 요청
export async function requestFinancierAnchorLoanOverdueRelease(loanId: number): Promise<void> {
  return await http.put<void>({
    url: API_FI.ANCHOR_LOANS.REQUEST_LOAN_OVERDUE_RELEASE(loanId),
  });
}

// 연체 해제 요청 승인
export async function requestFinancierApproveAnchorLoanOverdueRelease(loanId: number): Promise<void> {
  return await http.put<void>({
    url: API_FI.ANCHOR_LOANS.APPROVE_LOAN_OVERDUE_RELEASE(loanId),
  });
}

// 조기상환가능 예상금액 조회
export async function requestFinancierAnchorLoanCalculateExpectedAmount(
  loanId: number,
  expectedRepaymentDate: string,
): Promise<ExpectedLoanRepaymentVOModel> {
  const response = await http.post<ExpectedLoanRepaymentVO>({
    url: API_FI.ANCHOR_LOANS.CALCULATE_EXPECTED_AMOUNT(loanId),
    data: { expectedRepaymentDate },
  });

  return formattingToExpectedLoanRepaymentVOModel(response);
}

// 대출 실지급 금액 조회
export async function requestFinancierAnchorLoanCalculateNetDisbursement(
  loanId: number,
  data: FinancierAnchorLoanCalculateNetDisbursementRequest,
): Promise<CalculateNetDisbursementVOModel> {
  const response = await http.get<CalculateNetDisbursementVO>({
    url: API_FI.ANCHOR_LOANS.CALCULATE_NET_DISBURSEMENT(loanId),
    data,
  });

  return formattingToCalculateNetDisbursementVOModel(response);
}
